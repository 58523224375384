<template>
  <div class="graphic-container">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb-student">
      <el-breadcrumb-item>创作中心</el-breadcrumb-item>
      <el-breadcrumb-item>图文管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="graphic-wrapper">
      <div class="tab-wrapper">
        <router-link to="/student/graphic/index" class="tab-item current">图文管理</router-link>
        <router-link to="/student/graphic/creation" class="tab-item">新增图文</router-link>
      </div>

      <el-table
        :data="graphicList"
        style="width: 100%; margin-top: 10px; min-height: calc(100vh - 557px)"
        :header-cell-style="{ fontWeight: 'normal', color: '#333', background: '#EFEEFF', height: '50px' }"
        :cell-style="{ fontSize: '12px', color: '#333' }"
      >
        <el-table-column label="序号" type="index" align="center" width="100"></el-table-column>
        <el-table-column label="文章名称" align="center">
          <template slot-scope="scope">{{ scope.row.title }}</template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="250">
          <template slot-scope="scope">
            <el-button type="text" @click="handlePreview(scope.row)">预览</el-button>
            <el-button type="text" @click="handleEdit(scope.row)" v-if="scope.row.status === 1">编辑</el-button>
            <el-button type="text" style="color: #f56c6c" @click="handleDelete(scope.row)">删除</el-button>
            <span class="operate-span" style="color: #e6a23c" v-if="scope.row.status === 1">审核中</span>
            <span class="operate-span" style="color: #67c23a" v-if="scope.row.status === 2">审核通过</span>
            <span class="operate-span" style="color: #f56c6c" v-else-if="scope.row.status === 3">审核失败</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pages-center"
        :current-page.sync="listPages.currentPageNum"
        :page-size="listPages.eachPageNum"
        :total="listPages.total"
        layout="prev, pager, next, jumper"
        @current-change="pagesCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let that = this;
    return {
      graphicList: [],
      listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      graphicInfo: {
        // platformList: [
        //     {
        //         image: require('../../../assets/img/image/douyin.png')
        //     },
        // ],
        // accountList: [],
        // timeList: [
        //     {
        //         id: 1,
        //         text: '6小时',
        //     },
        //     {
        //         id: 2,
        //         text: '12小时',
        //     },
        //     {
        //         id: 3,
        //         text: '3天',
        //     },
        // ],
        // articleTypeList: [
        //     {
        //         tag: 'all',
        //         text: '全部',
        //     },
        //     {
        //         tag: 'news',
        //         text: '图文',
        //     },
        //     {
        //         tag: 'gallery',
        //         text: '图集',
        //     },
        //     {
        //         tag: 'video',
        //         text: '视频',
        //     },
        // ],
        // statusList: [
        //     {
        //         tag: 'all',
        //         text: '全部',
        //     },
        //     {
        //         tag: 'draft',
        //         text: '草稿',
        //     },
        //     {
        //         tag: 'publish',
        //         text: '已发布',
        //     },
        //     {
        //         tag: 'pre_publish',
        //         text: '待发布',
        //     },
        //     {
        //         tag: 'withdraw',
        //         text: '已撤回',
        //     },
        //     {
        //         tag: 'rejected',
        //         text: '未通过',
        //     },
        // ],
      },
      // platformCurrent: 0,
      // accountCurrent: 0,
      // timeCurrent: 0,
      // statusCurrent: 'all',
      // articleTypeCurrent: 'all',
      // currentAccount: {
      //     app_id: '',
      //     app_token: '',
      // },
      // startTime: '',
      // endTime: '',
      // pickerOptionsStart: {
      //     disabledDate(time) {
      //         return time.getTime() > Date.now();
      //     }
      // },
      // pickerOptionsEnd: {
      //     disabledDate: time => {
      //         if (that.startTime) {
      //             //如果开始时间不为空，则结束时间大于开始时间
      //             return new Date(that.startTime).getTime() > time.getTime()
      //         } else {
      //             //开始时间不选时，结束时间最大值小于等于当天
      //             return time.getTime() > Date.now()
      //         }
      //     }

      // },
      // hasMore: false,
      // page_no: 1
    };
  },
  mounted() {
    // this.getUserInfo()
    this.getGraphicList();
  },
  methods: {
    // 获取文创平台百家号信息
    // getUserInfo() {
    //     this.$httpStudent.axiosGet(this.$api.api_getUserList, (res) => {
    //         if (res.code === 200) {
    //             if (res.data.data.length === 0) {
    //                 this.$message.warning('请先到账号管理中绑定百家号')
    //             } else {
    //                 this.graphicInfo.accountList = res.data.data
    //                 this.currentAccount = {
    //                     app_id: res.data.data[0].app_id,
    //                     app_token: res.data.data[0].app_token,
    //                 }
    //                 this.graphicList = []
    //                 this.page_no = 1
    //                 this.hasMore = false
    //                 this.getGraphicList()
    //             }
    //         } else {
    //             this.$message.warning(res.message)
    //         }
    //     }, (err) => {
    //         console.log(err)
    //     })
    // },
    // 获取图文创作列表
    getGraphicList() {
      let params = {
        page: this.listPages.currentPageNum,
        limit: this.listPages.eachPageNum,
        status: -1,
      };
      this.$httpStudent.axiosGetBy(this.$api.api_articleList, params, (res) => {
        if (res.code === 200) {
          this.graphicList = res.data.list || [];
          this.listPages.total = res.data.total;
        } else {
          this.$message.warning(res.message);
        }
      });
      // let params = {
      //     app_id: this.currentAccount.app_id,
      //     app_token: this.currentAccount.app_token,
      //     page_no: this.page_no,
      //     page_size: 10,
      // }
      // if (this.statusCurrent === 'all') {
      //     delete params.collection
      // } else {
      //     params.collection = this.statusCurrent
      // }
      // if (this.articleTypeCurrent === 'all') {
      //     delete params.article_type
      // } else {
      //     params.article_type = this.articleTypeCurrent
      // }
      // if (this.startTime) {
      //     params.start_time = this.startTime
      // } else {
      //     delete params.start_time
      // }
      // if (this.endTime) {
      //     params.end_time = this.endTime
      // } else {
      //     delete params.end_time
      // }
      // this.$httpStudent.axiosGetBy(this.$api.api_articleListAll, params, (res) => {
      //     if (res.code === 200) {
      //         // this.graphicList = res.data.items
      //         this.graphicList = [...this.graphicList, ...res.data.items]
      //         // this.listPages.total = res.data.page.items_count
      //         this.hasMore = res.data.page.has_next
      //         this.page_no++
      //     } else {
      //         this.$message.warning(res.message)
      //     }
      // }, (err) => {
      //     console.log(err)
      // })
    },

    // 预览文章
    handlePreview(row) {
      sessionStorage.setItem("articleInfo", JSON.stringify(row));
      this.$router.push({
        path: "/student/graphic/preview",
      });
    },

    // 编辑文章
    handleEdit(row) {
      this.$router.push({
        path: "/student/graphic/creation",
        query: { id: row.id },
      });
    },

    // 删除文章
    handleDelete(row) {
      this.$confirm("是否删除该文章?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: row.id,
        };
        this.$httpStudent.axiosPost(this.$api.api_articleDelete, params, (res) => {
          if (res.code === 200) {
            this.$message.success(res.message);

            let articleCount = this.graphicList.length - 1;
            if (articleCount <= 0 && this.listPages.currentPageNum > 1) {
              this.listPages.currentPageNum -= 1;
            }

            this.getGraphicList();
          }
        });
      });
    },

    // 查看更多
    // moreBtn() {
    //     this.getGraphicList()
    // },
    // 切换分页
    pagesCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.getGraphicList();
    },
    // 切换平台
    // toggleBtn(index, type, item) {
    //     if (type === 'platform') {
    //         this.platformCurrent = index
    //     }
    //     if (type === 'account') {
    //         this.accountCurrent = index
    //         this.currentAccount = {
    //             app_id: item.app_id,
    //             app_token: item.app_token,
    //         }
    //     }
    //     // if (type === 'time') {
    //     //     this.timeCurrent = index
    //     // }
    //     if (type === 'status') {
    //         this.statusCurrent = item.tag
    //     }
    //     if (type === 'articleType') {
    //         this.articleTypeCurrent = item.tag
    //     }
    //     this.graphicList = []
    //     this.page_no = 1
    //     this.hasMore = false
    //     this.getGraphicList()
    // },
    // 切换日期
    // toggleTime() {
    //     if(this.startTime && this.endTime) {
    //         this.graphicList = []
    //         this.page_no = 1
    //         this.hasMore = false
    //         this.getGraphicList()
    //     }
    //     if (this.startTime === '') {
    //         this.$message.warning('请选择开始时间')
    //     }
    // },
    // seeBtn(item) {
    //     let params = {
    //         app_id: this.currentAccount.app_id,
    //         app_token: this.currentAccount.app_token,
    //         // article_id: item.article_id,
    //         article_id: '1685561226465769624',
    //     }
    //     this.$httpStudent.axiosGetBy(this.$api.api_getBjhStatus, params, (res) => {
    //         console.log('see', res)
    //     })
    // },
    // 撤回图文创作
    // withdrawBtn(id) {
    //     this.$confirm('是否撤回该图文创作，撤回后将无法恢复', '提示消息', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         confirmButtonClass: 'blue-btn',
    //         type: 'warning',
    //     }).then(() => {
    //         let params = {
    //             app_id: this.currentAccount.app_id,
    //             app_token: this.currentAccount.app_token,
    //             article_id: id,
    //         }
    //         this.$httpStudent.axiosGetBy(this.$api.api_withdraw, params, (res) => {
    //             if (res.code === 200) {
    //                 this.$message.success(res.message)
    //                 this.graphicList = []
    //                 this.page_no = 1
    //                 this.hasMore = false
    //                 this.getGraphicList()
    //             } else {
    //                 this.$message.warning(res.message)
    //             }
    //         }, (err) => {
    //             console.log(err)
    //         })
    //     }).catch(() => {
    //         this.$message.info('已取消删除')
    //     });
    // },
    // 表格多选
    // handleSelectionChange(selection) {
    //     console.log('selection', selection)
    // }
  },
};
</script>

<style scoped lang="scss">
::v-deep p {
  margin: 0;
}
.graphic-container {
  position: relative;
  /*height: calc(100vh - 100px);*/
  display: flex;
  flex-direction: column;
  .graphic-wrapper {
    display: flex;
    /*height: 1%;*/
    /*flex: 1;*/
    flex-direction: column;
    background: #fff;
    margin-top: 20px;
    border-radius: 10px;
    padding: 30px 40px;
    box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
    .tab-wrapper {
      .tab-item {
        display: inline-block;
        height: 32px;
        padding: 0 22px;
        line-height: 32px;
        background: #f1f0fe;
        color: #584eee;
        font-size: 16px;
        margin-right: 20px;
        border-radius: 4px;
        transition: all 0.3s;
        &:last-child {
          margin-right: 0;
        }
        &.current,
        &:hover {
          color: #fff;
          background: #574ded;
        }
      }
    }
    .graphic-info {
      .info-item {
        color: #16113f;
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        .left-box {
          width: 40px;
          margin-top: 20px;
          line-height: 30px;
        }
        .right-box {
          width: 1%;
          flex: 1;
          margin-left: 20px;
          display: flex;
          flex-wrap: wrap;
          .round {
            width: 38px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f1f0fe;
            border-radius: 50%;
            position: relative;
            margin-right: 30px;
            margin-top: 20px;
            cursor: pointer;
            .image {
              max-width: 38px;
              max-height: 38px;
              border-radius: 50%;
            }
            .icon-box {
              position: absolute;
              bottom: 0;
              right: -4px;
              align-items: center;
              justify-content: center;
              width: 16px;
              height: 16px;
              background: #fff;
              border-radius: 50%;
              display: none;
              .icon {
                font-size: 16px;
                color: #9acc5b;
                line-height: 1;
              }
            }
            &.current {
              .icon-box {
                display: flex;
              }
            }
          }
          .square {
            line-height: 30px;
            margin-top: 20px;
            margin-right: 30px;
            padding: 0 10px;
            border-radius: 5px;
            cursor: pointer;
            &.current {
              color: #fff;
              background: #9acc5b;
            }
          }
        }
      }
    }
    .operate-wrapper {
      margin: 30px 0 10px;
    }

    .operate-span {
      margin-left: 6px;
      font-size: 14px;
    }
  }
}
.blue-text {
  font-weight: 500;
  color: #53c1f2;
}
.green-text {
  font-weight: 500;
  color: #67c23a;
}
.purple-text {
  font-weight: 500;
  color: #685ef3;
}
.red-text {
  font-weight: 500;
  color: #fb0338;
}
</style>
