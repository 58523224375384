<template>
  <div class="video-management">
    <div class="header-box">
      <data-center-header @selectAccountData="getAccountInfo">
        <!-- <el-button slot="header-right" size="mini" @click="handlePublish">发布作品</el-button> -->
      </data-center-header>
    </div>

    <div class="content-box">
      <div class="content-title">
        <div class="title-text">作品管理</div>

        <div class="title-filter-box">
          <div class="data-filter filter-item">
            <span>发布时间</span>
            <el-date-picker
              v-model="filterParams.filterTime"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="getVideoList(false)"
            >
            </el-date-picker>
          </div>

          <el-input placeholder="搜索作品" prefix-icon="el-icon-search" v-model="filterParams.filterName" @change="videoSearch"> </el-input>
        </div>
      </div>

      <div class="content-main">
        <div class="main-header">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item v-for="item in menuConfig" :key="item.index" :index="item.index">{{ item.name }}</el-menu-item>
          </el-menu>

          <span class="video-count">共{{ videoTotal }}个作品</span>
        </div>

        <div class="main-no-data" v-if="videoList.length == 0">
          <img src="@/assets/img/video-managerment/no-data.png" alt="" />
          <span>暂无作品</span>
        </div>

        <div class="main-list" v-else>
          <div class="video-card" v-for="item in videoList" :key="item.id">
            <div class="card-cover" @click="handlePreview(item.video_url)">
              <img :src="item.cover_url" alt="" />
              <i class="sticky-top" v-if="item.yike_top == 1">置顶</i>
              <i class="preview-btn" v-if="item.media_type == 2"></i>
            </div>

            <div class="card-info">
              <div :class="['card-info_desc', { 'no-data': !item.desc }]" @click="handleVideoCard(item)">
                <template v-if="item.desc">{{ item.desc }}</template>
                <template v-else>无作品描述</template>
              </div>
              <div class="card-info_date">{{ item.video_create_time }}</div>
              <div
                :class="[
                  'card-info_status',
                  {
                    success: item.status_value == '1',
                    info: item.status_value == '4',
                    error: item.status_value == '2',
                  },
                ]"
              >
                {{ item.status_value_name }}
              </div>

              <div class="empty"></div>

              <div class="card-info_counts">
                <div class="count-play count-item">
                  <span class="item-text">播放</span>
                  <span class="item-count">{{ item.play_count }}</span>
                </div>

                <div class="count-play count-item">
                  <span class="item-text">点赞</span>
                  <span class="item-count">{{ item.digg_count }}</span>
                </div>

                <div class="count-play count-item">
                  <span class="item-text">评论</span>
                  <span class="item-count">{{ item.comment_count }}</span>
                </div>

                <!-- <div class="count-play count-item">
                    <span class="item-text">收藏</span>
                    <span class="item-count">{{ item.share_count }}</span>
                  </div> -->
              </div>
            </div>

            <div class="empty"></div>

            <!-- 隐藏，待定 -->
            <div class="card-operation">
              <template v-if="false">
                <el-popover
                  popper-class="black-popover"
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  content="超过30天作品不允许修改描述与封面"
                  :disabled="!disabledChangeCover(item.video_create_time)"
                >
                  <el-button slot="reference" type="text" :disabled="disabledChangeCover(item.video_create_time)" key="change">
                    <svg
                      t="1725854275249"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="10243"
                      width="32"
                      height="32"
                    >
                      <path
                        d="M84.1728 716.0832L0 1024l306.9952-85.4016 484.864-484.1472L566.272 234.496 84.1728 716.0832zM755.0976 46.08l-120.832 120.6272 226.304 219.136 117.248-117.1456c61.5424-61.44 61.5424-161.1776 0-222.6176-61.44-61.44-161.28-61.44-222.72 0z"
                        fill="#8a8a8a"
                        p-id="10244"
                      ></path>
                    </svg>
                    修改描述和封面
                  </el-button>
                </el-popover>
                <el-button type="text">
                  <svg
                    t="1725854331761"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="12159"
                    width="32"
                    height="32"
                  >
                    <path
                      d="M842.666667 384h-74.666667V277.333333a234.666667 234.666667 0 1 0-469.333333 0v106.666667H224a53.393333 53.393333 0 0 0-53.333333 53.333333v490.666667a53.393333 53.393333 0 0 0 53.333333 53.333333h618.666667a53.393333 53.393333 0 0 0 53.333333-53.333333V437.333333a53.393333 53.393333 0 0 0-53.333333-53.333333z m-288 295.006667V789.333333a21.333333 21.333333 0 0 1-42.666667 0v-110.326666a64 64 0 1 1 42.666667 0zM725.333333 384H341.333333V277.333333c0-105.866667 86.133333-192 192-192s192 86.133333 192 192z"
                      fill="#8a8a8a"
                      p-id="12160"
                    ></path>
                  </svg>
                  设置权限
                </el-button>
                <el-button type="text" class="delete-button">
                  <svg
                    t="1725854420238"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="17829"
                    width="32"
                    height="32"
                  >
                    <path
                      d="M789.01248 270.46912l-2.79552 598.30272C774.08256 927.21152 742.98368 972.8 690.0224 972.8H334.46912c-52.96128 0-84.06016-45.54752-96.1536-104.0384L235.52 270.47936h553.49248zM654.97088 51.2l57.05728 51.28192H911.36v104.40704H112.64V102.48192h200.02816L369.7152 51.2h285.25568z"
                      fill="#8a8a8a"
                      p-id="17830"
                    ></path>
                  </svg>
                  删除作品
                </el-button>

                <el-button type="text" v-if="item.yike_top == 1" @click="handleIsSticky(item)">
                  <svg
                    t="1725871048603"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="18860"
                    width="32"
                    height="32"
                  >
                    <path
                      d="M512 12.624658C235.660274 12.624658 11.221918 238.465753 11.221918 513.40274c0 276.339726 224.438356 500.778082 500.778082 500.778082 276.339726 0 500.778082-224.438356 500.778082-500.778082C1012.778082 238.465753 788.339726 12.624658 512 12.624658z m0 70.136986c106.608219 0 203.39726 39.276712 277.742466 102.4L183.758904 792.547945c-64.526027-75.747945-102.4-172.536986-102.4-277.742466 0-238.465753 193.578082-432.043836 430.641096-432.043835z m0 861.282192c-106.608219 0-203.39726-39.276712-277.742466-102.4l605.983562-605.983562c64.526027 75.747945 102.4 172.536986 102.4 277.742466 0 238.465753-193.578082 430.641096-430.641096 430.641096z"
                      fill="#8a8a8a"
                      p-id="18861"
                    ></path>
                  </svg>
                  取消置顶
                </el-button>
                <el-button type="text" v-if="item.yike_top == 0" @click="handleIsSticky(item)">
                  <svg
                    t="1725854371081"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="13196"
                    width="32"
                    height="32"
                  >
                    <path
                      d="M128 128l768 0 0 89.6-768 0 0-89.6ZM732.288 496 555.7888 309.8624c-10.9504-11.2064-26.4128-18.4896-43.7888-18.4896s-32.832 7.2832-43.6032 18.6048L291.5264 495.8656c-9.2288 10.0224-15.1104 22.9696-15.1104 37.3248 0 30.816 26.3808 55.8016 58.8992 55.8016 0.4544 0 0.896-0.0576 1.344-0.0704l0 0.352L416 589.2736 416 896l192 0L608 589.2736l77.1904 0 0-0.448c1.1648 0.064 2.3104 0.1792 3.5008 0.1792 32.5248 0 58.8992-24.992 58.8992-55.8144C747.5904 518.8352 741.7024 505.8944 732.288 496z"
                      fill="#8a8a8a"
                      p-id="13197"
                    ></path>
                  </svg>
                  置顶
                </el-button>
              </template>
            </div>
          </div>

          <div class="medio-load">
            <span v-if="!loadMore" style="cursor: auto">没有更多作品</span>
            <span v-else @click="loadMoreData">点击加载更多</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 预览窗口 -->
    <!-- <el-dialog :visible.sync="previewVisiable">
    </el-dialog> -->
  </div>
</template>

<script>
import _ from "underscore";
import DataCenterHeader from "@/components/dataCenter/DataCenterHeader.vue";
export default {
  name: "VideoManagement",
  data() {
    return {
      filterParams: {
        filterName: "",
        filterTime: [],
      },

      activeIndex: "1",
      menuConfig: [
        {
          index: "1",
          name: "全部作品",
        },
        {
          index: "2",
          name: "已发布",
          status: 1,
        },
        {
          index: "3",
          name: "审核中",
          status: 4,
        },
        {
          index: "4",
          name: "未通过",
          status: 2,
        },
      ],
      videoTotal: 0,
      videoList: [],
      videoParams: {
        paging: "1",
        page: 1,
        pageSize: 10,
        account_id: "",
      },
      loadMore: false,
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          date.setDate(date.getDate());
          return time.getTime() > date.getTime();
          // return time.getTime() > Date.now()
        },
      },
      // scrollbarEventListener: false,
      // previewVisiable: false,
      // previewItem: {},
    };
  },
  computed: {},
  watch: {
    // videoList: {
    //   handler(newVal) {
    //     if (newVal.length > 0) {
    //       if (this.scrollbarEventListener) return;
    //       this.$nextTick(() => {
    //         this.scrollbarEventListener = true;
    //         this.handleScrollEventlistener("add");
    //       });
    //     } else {
    //       this.handleScrollEventlistener("remove");
    //       this.scrollbarEventListener = false;
    //     }
    //   },
    //   immediate: false,
    // },
  },
  mounted() {},
  methods: {
    handleSelect(key, keyPath) {
      const menuObj = this.menuConfig.find((item) => item.index == key);

      if (menuObj.status) this.videoParams.status = menuObj.status;
      else delete this.videoParams.status;

      this.videoParams.page = 1;
      this.getVideoList();
    },

    // 判断是否可以编辑封面，超过30天不可编辑
    disabledChangeCover(date) {
      const currentDate = new Date();
      const formatDate = new Date(date);

      currentDate.setDate(currentDate.getDate() - 30);

      return formatDate.getTime() - currentDate.getTime() > 0 ? false : true;
    },

    getAccountInfo(account) {
      if (account) {
        // console.log("account: ", account);
        this.videoParams.account_id = account.id;
        this.videoParams.page = 1;

        this.getVideoList();
      }
    },

    // 加载更多数据
    loadMoreData() {
      this.getVideoList(true);
    },

    getVideoList(append = false) {
    console.log(this.filterParams.filterTime);

      if (!this.videoParams.account_id) return;

      // 页码修改
      if (append) this.videoParams.page = this.videoParams.page + 1;

      // 关键词过滤
      if (this.filterParams.filterName) this.videoParams.name = this.filterParams.filterName;
      else delete this.videoParams.name;

      if (this.filterParams.filterTime) {
        this.videoParams.video_date = this.filterParams.filterTime.join(",");
      } else {
        this.videoParams.video_date = "";
      }

      // 重置scrollbar, 当数据重新查询，且不是添加数据时，将scroll移回顶部
      // if (this.$refs.scrollbar && !append) this.$refs.scrollbar.wrap.scrollTop = 0;

      this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_video, this.videoParams, (res) => {
        if (res.code == 200) {
          this.videoTotal = res.data.total;
          this.videoList = append ? this.videoList.concat(res.data.list) : res.data.list || [];

          this.loadMore = this.videoParams.page * this.videoParams.pageSize < this.videoTotal ? true : false;

          // console.log("videoList: ", this.videoList);
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 格式化日期为 yyyy-MM-dd
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    handleVideoCard(video) {
      // console.log("video: ", video);
      const date = new Date();
      const prevDate = new Date();
      prevDate.setFullYear(prevDate.getFullYear() - 1);
      const dates = [this.formatDate(prevDate), this.formatDate(date)];

      this.$router.push({
        path: "/student/dataCenter/ContentDetail",
        query: { id: video.id, accountIds: this.videoParams.account_id, date: dates.toString() },
      });
    },

    // 关键字搜索
    videoSearch() {
      this.videoParams.page = 1;
      this.getVideoList();
    },

    // handleScrollEventlistener(type) {
    //   if (!this.$refs.scrollbar) return;

    //   const scrollWrap = this.$refs.scrollbar.wrap;
    //   scrollWrap[`${type}EventListener`]("scroll", this.handleScroll);
    // },

    // 监听滚动条
    // handleScroll(event) {
    //   const scrollElement = event.target;
    //   // console.log(scrollElement.scrollHeight, scrollElement.scrollTop, scrollElement.clientHeight);

    //   // 误差，精度问题
    //   const tolerance = 1.5;

    //   // 检查是否到底部
    //   if (scrollElement.scrollHeight - scrollElement.scrollTop <= scrollElement.clientHeight + tolerance) {
    //     if (this.videoParams.page * this.videoParams.pageSize < this.videoTotal) this.getVideoList(true);
    //   }
    // },

    // 置顶
    handleIsSticky(video) {
      let apiName = video.yike_top == 0 ? this.$api.tikhoknew_data_top_video : this.$api.tikhoknew_data_top_video;
      let params = {
        id: video.id,
        need_top: video.yike_top == 0 ? 1 : 0,
      };
      this.$httpStudent.axiosGetBy(apiName, params, (res) => {
        if (res.code == 200) {
          this.$message.success(`${params.need_top == 0 ? "取消" : ""}置顶成功！`);
          this.getVideoList();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 预览视频或图片 - 跳转视频地址
    handlePreview(url) {
      window.open(url, "_blank");
    },

    handlePublish() {
      this.$router.push({
        path: "/student/video/publishWorks",
        query: {
          id: this.videoParams.account_id,
          tabIndex: 1,
        },
      });
    },
  },

  components: {
    DataCenterHeader,
  },

  beforeDestroy() {
    // this.handleScrollEventlistener("remove");
  },
};
</script>

<style scoped lang="scss">
.video-management {
  .header-box {
    ::v-deep .el-button {
      border: none;
      background-color: #594fee;
      color: #fff;
    }
  }

  // 弹框
  ::v-deep(.el-dialog) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 960px;
    height: 540px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 100%;
      height: 100%;
      padding: 0;
      box-sizing: border-box;
    }
  }

  .content-box {
    margin-top: 20px;
    min-height: calc(100vh - 235px);
    background-color: #ffffff;
    border-radius: 4px;
    padding: 24px;
    box-sizing: border-box;

    .content-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title-text {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #252632;
        text-align: left;
        font-style: normal;
      }

      .title-filter-box {
        display: flex;
        gap: 6px;

        ::v-deep .el-input__inner {
          background-color: #f4f5f5;
          height: 36px;
          line-height: 36px;

          &:focus,
          &:hover {
            outline: none;
            border-color: #dcdfe6;
          }
        }

        .filter-item {
          background-color: #f2f2f4;
          border-radius: 4px;
          padding: 2px 12px;
          display: flex;
          gap: 2px;
          align-items: center;
          height: 36px;
          box-sizing: border-box;

          span {
            text-wrap: nowrap;
            color: rgba(37, 38, 50, 0.6);
          }

          ::v-deep .el-select__tags-text {
            max-width: 180px !important;
            flex-wrap: nowrap;
            display: inline-block;
            max-width: 100px !important; // 根据实际情况调整
            overflow: hidden; // 溢出隐藏
            text-overflow: ellipsis; // 超出文本以省略号显示
            white-space: nowrap; // 文本不换行
          }

          ::v-deep .el-input--suffix {
            width: 212px;
          }

          ::v-deep .el-input__inner {
            padding-left: 10px;
            background: transparent;
            border: none;
          }

          ::v-deep .el-date-editor .el-range-input {
            background-color: transparent;
          }

          ::v-deep .el-date-editor--daterange.el-input,
          .el-date-editor--daterange.el-input__inner,
          .el-date-editor--timerange.el-input,
          .el-date-editor--timerange.el-input__inner {
            width: 250px;
          }
        }
      }
    }

    .content-main {
      .main-header {
        position: relative;

        .video-count {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);

          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #888888;
          line-height: 14px;
          font-style: normal;
        }

        ::v-deep .el-menu-item {
          padding-inline: 3px;
          margin-right: 20px;
        }
      }

      .main-no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 40px;

        img {
          width: 200px;
          height: 200px;
          object-fit: cover;
        }

        span {
          text-align: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: rgba(28, 31, 35, 0.55);
          line-height: 16px;
          font-style: normal;
        }
      }

      .main-list {
        min-height: calc(100vh - 385px);
        box-sizing: border-box;
        padding: 10px 0;

        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }

        .video-card {
          display: flex;
          justify-content: space-between;
          border-radius: 4px;
          gap: 10px;
          padding: 12px;

          &:hover {
            background-color: #f8f8f8;
          }

          & > div {
            flex-grow: 0;
          }

          .empty {
            flex-grow: 1 !important;
          }

          .card-cover {
            position: relative;
            img {
              width: 150px;
              height: 200px;
              object-fit: cover;
              border-radius: 5px;
              cursor: pointer;
            }

            .sticky-top {
              position: absolute;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 12px;
              color: #161823;
              line-height: 14px;
              font-style: normal;
              background-color: #ffd107;
              border-radius: 2px;
              padding: 4px;
              top: 5px;
              left: 5px;
            }

            .preview-btn {
              display: inline-block;
              position: absolute;
              width: 13px;
              height: 13px;
              box-sizing: border-box;
              top: 10px;
              right: 5px;
              background-color: #ffffff;
              border-radius: 3px;
              z-index: 2;
              // cursor: pointer;

              &::before {
                content: "";
                position: absolute;
                background-color: #ffffff;
                width: 13px;
                height: 13px;
                bottom: 15%;
                right: 15%;
                border-radius: 3px;
                border-right: 1px solid #c7b9b9;
                border-bottom: 1px solid #d9d9d9;
                box-sizing: border-box;
              }
            }
          }

          .card-info {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            & > div {
              flex-grow: 0;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              text-align: left;
              font-style: normal;
            }

            &_desc {
              font-size: 14px;
              color: #252632;
              line-height: 16px;

              display: -webkit-box;
              line-clamp: 2;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
              margin-bottom: 5px;

              &.no-data {
                color: rgba(28, 31, 35, 0.55);
              }
            }

            &_date {
              font-size: 12px;
              color: rgba(28, 31, 35, 0.6);
              line-height: 14px;
              margin-bottom: 10px;
            }

            &_status {
              &.success {
                font-size: 12px;
                color: #39b54a;
                line-height: 14px;
              }

              &.info {
                font-size: 12px;
                color: rgba(28, 31, 35, 0.3);
                line-height: 14px;
              }

              &.error {
                font-size: 12px;
                color: #fe2c55;
                line-height: 14px;
              }
            }

            &_counts {
              display: flex;
              align-items: center;

              .count-item {
                width: 80px;
                position: relative;
                margin-right: 12px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                gap: 6px;

                & > span {
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 500;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                }

                .item-text {
                  font-size: 12px;
                  color: rgba(22, 24, 35, 0.34);
                  line-height: 14px;
                }

                .item-count {
                  font-weight: bold;
                  font-size: 14px;
                  color: #161823;
                  line-height: 16px;
                }

                &:not(.count-item:last-of-type)::after {
                  content: "";
                  position: absolute;
                  width: 1px;
                  height: 24px;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  background-color: #e3e3e4;
                }
              }
            }
          }

          .card-operation {
            ::v-deep .el-button {
              margin-left: 20px !important;
            }

            ::v-deep .el-button--text {
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: rgba(28, 31, 35, 0.6);
              line-height: 16px;
            }

            .delete-button {
              color: #f56c6c;

              svg path {
                fill: #f56c6c !important;
              }
            }
          }
        }
      }
    }
    .medio-load {
      display: flex;
      justify-content: center;
      padding: 25px 0;

      span {
        font-weight: 400;
        font-size: 16px;
        color: #888888;
        line-height: 19px;
        cursor: pointer;
      }
    }
  }
}
</style>
