<template>
    <div class="data_center_box">
        <div class="title">
            <div class="title_left">
                <span class="data_title">数据中心</span>
                <el-tooltip placement="top">
                    <div slot="content">{{ update_time_text }}</div>
                    <i class="el-icon-question" style="color: #BBBBBD;margin: 0 5px;cursor: pointer;"></i>
                </el-tooltip>

                <span class="time">{{ timeDate }}</span>
            </div>
            <div class="title_right" @click="goMore()">查看更多<i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="show_data">
            <div class="data_left">
                <div class="new_work">最新作品</div>
                <div class="video" v-if="latestWork.id" @click="getContentAnalysis">
                    <img :src="latestWork.cover_url" alt="">
                    <p class="desc desc_type" v-if="latestWork.media_type == 2"><span><i
                                class="el-icon-copy-document"></i></span></p>
                    <p class="desc">{{ latestWork.title }}</p>
                    <div class="video_info">
                        <div class="no_content"></div>
                        <div class="content">
                            <p><span>播放量</span><span>{{ latestWork.play_count }}</span></p>
                            <p><span>点赞量</span><span>{{ latestWork.digg_count }}</span></p>
                            <p><span>评论量</span><span>{{ latestWork.comment_count }}</span></p>
                            <p><span>分享量</span><span>{{ latestWork.share_count }}</span></p>
                            <p><span>查看分析<i class="el-icon-arrow-right"></i></span></p>
                        </div>
                    </div>

                </div>
                <div class="no_video" v-else>
                    <img src="../../assets/img/work/noWork.png" alt="">
                    <span class="text" style="font-size: 12px;">
                        近30天未发布新作品，快去发布作品吧
                    </span>
                </div>
            </div>
            <div class="data_right">
                <div class="data_header">
                    <div class="header_left">
                        <div class="type_item" v-for="(item, index) in dataType"
                            :class="activeDataType == index ? 'active_type_item' : ''" @click="activeTypeItem(index)">{{
                                item.name
                            }}</div>
                    </div>
                    <div class="header_right" v-if="activeDataType != 1">
                        <span>时间</span>
                        <el-select v-model="selectDateType" placeholder="请选择" popper-class="eloption"
                            @change="changeDateType" :popper-append-to-body="false">
                            <el-option v-for="item in dateTypeOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="data_box" v-if="activeDataType != 1">
                    <div v-if="echartsData.length == 0" class="no_echarts">
                        <img src="../../assets/img/work/noData.png" alt="">
                        <span class="text">
                            <span v-if="activeDataType == 0">暂无数据</span>
                            <span v-if="activeDataType == 2"> 近{{ selectDateType }}日没有开播,暂无数据,快去开播吧</span>

                        </span>
                    </div>
                    <div v-else class="echarts">
                        <div class="data_echarts" id="data_echarts" ref="data_echarts" v-if="echartsData.length > 0">
                        </div>

                        <!-- <div v-if="echartsData.length == 1" class="data_echarts"></div> -->
                        <div class="select_box" v-if="activeDataType == 0">
                            <div class="select_item" v-for="(item, index) in dataOverview"
                                @mouseover="activeDataOverviewType(item.data_type)">
                                <p class="selectType"
                                    :class="dataOverviewType == item.data_type ? 'activeSelectType' : ''">
                                    {{
                                        item.name }}</p>
                                <p class="number">
                                    <span class="num">{{ item.num }}</span>
                                    <span class="date" v-if="selectDateType >= 1 && selectDateType != 30">较前{{
                                        selectDateType }}日</span>
                                    <span class="changenum" v-if="selectDateType >= 1 && selectDateType != 30"
                                        :style="{ color: item.changeNum < 0 ? '#14C15A' : item.changeNum != 0 ? '#FF2C55' : '#999999', fontWeight: '600' }">{{
                                            item.changeNum
                                        }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="select_box" v-if="activeDataType == 2">
                            <div class="select_item" v-for="(item, index) in liveData"
                                @mouseover="activeDataOverviewType(item.data_type)">
                                <p class="selectType"
                                    :class="dataOverviewType == item.data_type ? 'activeSelectType' : ''">
                                    {{
                                        item.name }}</p>
                                <p class="number">
                                    <span class="num">{{ item.num }}</span>
                                    <span class="date" v-if="selectDateType > 1">较前{{ selectDateType }}日</span>
                                    <span class="changenum" v-if="selectDateType > 1"
                                        :style="getStyle">{{
                                            item.changeNum
                                        }}</span>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="data_box" v-else>
                    <template v-if="recentWorks.length > 0">
                        <div v-for="(item, index) in recentWorks" class="recentWorks_item" @click="viewAnalysis(item)">
                            <div class="image">
                                <!-- <img src="../../assets/img/work/tttt.jpeg" alt=""> -->
                                <img :src="item.cover_url" alt="">
                            </div>
                            <div class="recent_data">
                                <P class="description">
                                <div class="status_name">{{ item.title }}</div>
                                <div class="time">
                                    <span>{{ item.status_name }}</span>
                                    <span>{{ item.video_create_time }}</span>
                                </div>
                                </P>
                                <P class="Works_data">
                                <div class="work_item">
                                    <span>播放</span>
                                    <span>{{ item.play_count }}</span>
                                </div>
                                <div class="work_item">
                                    <span>点赞</span>
                                    <span>{{ item.digg_count }}</span>
                                </div>
                                <div class="work_item">
                                    <span>评论</span>
                                    <span>{{ item.comment_count }}</span>
                                </div>
                                <div class="work_item">
                                    <span>分享</span>
                                    <span>{{ item.share_count }}</span>
                                </div>
                                <div class="viewAnalysis">
                                    查看分析
                                </div>

                                </P>
                            </div>
                        </div>
                    </template>
                    <div class="no_video" v-else>
                        <img src="../../assets/img/work/noData.png" alt="">
                        <span class="text" style="color: rgba(28, 31, 35, 0.55);">
                            近30天未发布新作品，暂无近期作品数据
                        </span>
                    </div>


                </div>

            </div>


        </div>

    </div>
</template>
<script>
import * as echarts from "echarts";
export default {
    props: ["accountId"],
    data() {
        return {
            timeDate: "",
            update_time_text: "",
            dataType: [{
                name: "数据总览"
            },
            {
                name: "近期作品"
            },
            {
                name: "直播数据"
            }],
            activeDataType: 0,//0 数据总览, 1近期作品,2,直播数据
            latestWork: {

            },
            dataOverview: [
                {
                    name: "播放量",
                    data_type: 1
                },
                {
                    name: "主页访问量",
                    data_type: 2
                },
                {
                    name: "作品点赞",
                    data_type: 3
                },
                {
                    name: "作品分享",
                    data_type: 4
                },
                {
                    name: "作品评论",
                    data_type: 5
                },
                {
                    name: "净增粉丝",
                    data_type: 6
                },
            ],
            liveData: [
                {
                    name: "开播时长",
                    data_type: 14
                },
                {
                    name: "观看人数",
                    data_type: 2
                },
                {
                    name: "在线人数峰值",
                    data_type: 3
                },
                {
                    name: "人均观看时长(分) ",
                    data_type: 5
                },
                {
                    name: "评论人数",
                    data_type: 8
                },
                {
                    name: "新增粉丝数",
                    data_type: 11
                },
            ],
            dateTypeOptions: [
                {
                    value: 1,
                    label: "昨日"
                },
                {
                    value: 7,
                    label: "近7天"
                }, {
                    value: 30,
                    label: "近30天"
                },
            ],
            selectDateType: 7,//选择查看日期
            dataOverviewType: 1,//数据总览查看类型
            echartsData: [],
            oldEchartData: null,
            recentWorks: [],//近期数据,
            dataEcharts: null
        }
    },
    watch: {
        'accountId': function (newValue, oldValue) {
            // console.log('本地存储值发生变化：', newValue)
            this.activeDataType = 0
            this.selectDateType = 7
            this.latestWork = {}
            this.recentWorks = []
            this.dataOverviewType = 1
            this.echartsData = []
            this.getDataOverview()
            this.getEachersData()
            this.getLatestWork()


        }
    },
    mounted() {

        // this.getDataOverview()
        // this.getEachersData()
    },
    methods: {
        getStyle() {
            return { color: parseFloat(item.changeNum?.replace('%', '')) < 0 ? '#14C15A' : (parseFloat(item.changeNum?.replace('%', '')) != 0 ? '#FF2C55' : 'rgb(37,38,50)') }
        },
        getAccount() {
            return JSON.parse(localStorage.getItem("account"));
        },
        getDate() {
            const now = new Date();
            const yestoday = new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000);
            const year = yestoday.getFullYear();
            const month = yestoday.getMonth() + 1;
            const day = yestoday.getDate();
            const formattedDate = `${year}.${month.toString().padStart(2, '0')}.${day.toString().padStart(2, '0')}`;
            if (this.selectDateType == 1) {
                this.timeDate = `${formattedDate}`
            } else {
                const sevenDaysAgo = new Date(now.getTime() - this.selectDateType * 24 * 60 * 60 * 1000);
                const sevenformattedDate = `${sevenDaysAgo.getFullYear()}.${sevenDaysAgo.getMonth() + 1}.${sevenDaysAgo.getDate().toString().padStart(2, '0')}`;
                this.timeDate = `${sevenformattedDate}-${formattedDate}`
            }
        },
        goMore() {
            // 查看更多
            this.$router.push({
                path: '/student/dataCenter/BusinessAnalysis',
                query: {
                    accountId: this.accountId
                }
            })
        },
        activeTypeItem(index) {
            this.activeDataType = index
            if (index == 0) {
                this.dataOverviewType = 1
                this.getDataOverview()
                this.getEachersData()
            }
            if (index == 1) {
                if (this.dataEcharts) {
                    this.dataEcharts.dispose();
                }
                this.getnewestItemList()
            }
            if (index == 2) {
                this.dataOverviewType = 14
                this.getLiveData()
                this.getEachersData()
            }

        },
        changeDateType(val) {
            if (this.activeDataType == 0) {
                this.dataOverviewType = 1
                this.getDataOverview()
                this.getEachersData()
            }
            if (this.activeDataType == 2) {
                this.dataOverviewType = 14
                this.getLiveData()
                this.getEachersData()
            }

        },
        getDataOverview() {
            // 获取数据总览数据
            // dataOverview
            let params = {
                date_type: this.selectDateType,
                id: this.accountId
            }
            this.$httpStudent.axiosGetBy(this.$api.dataCenterVideoOverview, params, res => {

                if (res.code == 200) {
                    let obj = res.data;
                    this.dataOverview.forEach((item, index) => {
                        let data = {
                            data_type: item.data_type,
                            name: item.name
                        }
                        switch (item.data_type) {
                            case 1:
                                data.num = obj.play
                                data.changeNum = obj.play_change
                                break;
                            case 2:
                                data.num = obj.profile_uv
                                data.changeNum = obj.profile_uv_change
                                break;
                            case 3:
                                data.num = obj.like
                                data.changeNum = obj.like_change
                                break;
                            case 4:
                                data.num = obj.share
                                data.changeNum = obj.share_change
                                break;
                            case 5:
                                data.num = obj.comment
                                data.changeNum = obj.comment_change
                                break;
                            case 6:
                                data.num = obj.new_fans
                                data.changeNum = obj.new_fans_change
                                break;
                        }
                        this.dataOverview.splice(index, 1, data)

                    })
                    this.timeDate = res.data.statistics_time_text
                    this.update_time_text = res.data.update_time_text
                    // console.log(this.dataOverview);


                }


            }, (err) => {
            })

        },
        getLiveData() {
            let params = {
                date_type: this.selectDateType,
                id: this.accountId
            }
            this.$httpStudent.axiosGetBy(this.$api.dataCenterLiveOverview, params, res => {

                if (res.code == 200) {
                    let obj = res.data;
                    this.liveData.forEach((item, index) => {
                        let data = {
                            data_type: item.data_type,
                            name: item.name
                        }
                        switch (item.data_type) {
                            case 14:
                                data.num = obj.live_duration
                                data.changeNum = obj.live_duration_change
                                break;
                            case 2:
                                data.num = obj.watch_people
                                data.changeNum = obj.watch_people_change
                                break;
                            case 3:
                                data.num = obj.watch_max_people
                                data.changeNum = obj.watch_max_people_change
                                break;
                            case 5:
                                data.num = obj.avg_watch_duration
                                data.changeNum = obj.avg_watch_duration_change
                                break;
                            case 8:
                                data.num = obj.comment_people
                                data.changeNum = obj.comment_people_change
                                break;
                            case 11:
                                data.num = obj.follow_people
                                data.changeNum = obj.follow_people_change
                                break;
                        }
                        this.liveData.splice(index, 1, data)

                    })
                    this.timeDate = res.data.statistics_time_text
                    this.update_time_text = res.data.update_time_text
                    // console.log(this.liveData, "直播数据");


                }


            }, (err) => {
            })
        },
        getnewestItemList() {
            let params = {
                page: 1,
                limit: 4,
                id: this.accountId
            }
            this.$httpStudent.axiosGetBy(this.$api.dataCenterNewestItemList, params, res => {
                if (res.code == 200) {
                    this.recentWorks = res.data.list
                    // this.recentWorks = [{
                    //     id: 91252, //作品id
                    //     item_i: "@9VwM0fHBUtE6MnqmN4s2Qs790WXqP/6BOpJzqAKlJ1kUb/D660zdRmYqig357zEBM/zSmyfFf+VWjvZyJX/FeQ==",
                    //     title: "客订分享。#美出高级感 #轻奢款珠宝首饰分享 #夏天怎能少闪闪的饰品", //视频标题
                    //     comment_count: 3, //评论数
                    //     digg_count: 0, //点赞数
                    //     play_count: 0, //播放量
                    //     share_count: 0, //分享量
                    //     video_url: "https://www.iesdouyin.com/share/video/7403263172183887116/?region=CN&mid=7403263221831863067&u_code=2caaabkii731&did=MS4wLjABAAAANwkJuWIRFOzg5uCpDRpMj4OX-QryoDgn-yYlXQnRwQQ&iid=MS4wLjABAAAANwkJuWIRFOzg5uCpDRpMj4OX-QryoDgn-yYlXQnRwQQ&with_sec_did=1&titleType=&share_sign=B.scF3PMOm3dKR9PTamtaeg7evF_rLDOL8lxG.UlW5Q-&share_version=270400&ts=1724056426&from_aid=1128&from_ssr=1", //播放地址
                    //     cover_url: "https://p3-sign.douyinpic.com/tos-cn-p-0015/oAP7NzgvfAIxgwtbBD9mkWB2WEACFAXi9a9Qfv~c5_300x400.jpeg?x-expires=1725264000&x-signature=nKp1tZnrm7u0sJq5V1mzs7JVxTI%3D&from=327834062_large&s=PackSourceEnum_PUBLISH&se=false&sc=cover&biz_tag=aweme_video&l=20240819163346AA1C6043E459A8066A99", //封面
                    //     video_create_time: "2024-08-15 15:22:33", //视频发布时间
                    //     avg_play_duration: 0, //平均播放时长
                    //     media_type: 4, //媒体类型: 2=图集 4=视频
                    //     status_name: "已通过" //状态
                    // }]
                }
            }, (err) => {
            })
        },
        activeDataOverviewType(type) {
            if (this.dataOverviewType == type) return
            this.dataOverviewType = type

            this.getEachersData()


        },
        getEachersData() {
            // 获取到图表的数据
            if (this.activeDataType == 0) {
                // 数据总览
                // console.log("数据总览");

                let params = {
                    date_type: this.selectDateType,
                    id: this.accountId,
                    data_type: this.dataOverviewType
                }
                this.$httpStuNoLoading.axiosGetBy(this.$api.dataCenterVideoChart, params, res => {
                    if (res.code == 200) {

                        this.echartsData = []
                        this.oldEchartData = null
                        this.echartsData = res.data
                        if (this.echartsData.length == 0) return
                        this.oldEchartData = res.data[0].value
                        this.echartsData.shift()



                        let x = []
                        let y = []
                        this.echartsData.forEach(item => {
                            x.push(item.date)
                            y.push(Number(item.value))

                        })
                        this.$nextTick(() => {
                            this.getEachers(x, y)
                        })



                    }
                }, (err) => {
                })

            }
            if (this.activeDataType == 2) {

                // 直播数据
                let params = {
                    date_type: this.selectDateType,
                    id: this.accountId,
                    data_type: this.dataOverviewType
                }
                this.$httpStuNoLoading.axiosGetBy(this.$api.dataCenterLiveTrend, params, res => {
                    if (res.code == 200) {

                        this.echartsData = []
                        this.oldEchartData = null
                        this.echartsData = res.data.list
                        // console.log(this.echartsData, "直播的数据");
                        if (this.echartsData.length == 0) return
                        this.oldEchartData = res.data.list[0].value
                        this.echartsData.shift()
                        // console.log(this.echartsData, "直播111");

                        let x = []
                        let y = []
                        this.echartsData.forEach(item => {
                            x.push(item.date)
                            y.push(Number(item.value))

                        })
                        this.$nextTick(() => {
                            this.getEachers(x, y)
                        })



                    }
                }, (err) => {
                })

            }
        },
        viewAnalysis(item) {
            // 查看数据分析
            // this.$router.push({
            //     path: '/student/dataCenter/ContentDetailCopy',
            //     query: {
            //         accountIds: this.accountId,
            //         id: item.id
            //     }
            // })
            const url = `#/student/dataCenter/ContentDetail?accountIds=${this.accountId}&id=${item.id}`;
            window.open(url, '_blank');

        },
        getEachers(x, y) {
            // console.log(this.dataEcharts, "this.dataEcharts");
            const richCss = {
                start: {
                    width: 66,
                    align: "right"
                },
                end: {
                    width: 66,
                    align: "left"
                }
            }
            if (this.dataEcharts) {
                this.dataEcharts.dispose();
            }
            this.dataEcharts = echarts.init(this.$refs.data_echarts)
            var myChart = this.dataEcharts
            let title = null
            if (this.activeDataType == 0) {
                title = this.dataOverview?.find(item => item.data_type == this.dataOverviewType).name
            }
            if (this.activeDataType == 2) {
                title = this.liveData?.find(item => item.data_type == this.dataOverviewType)?.name
            }
            x = x.map(date => date.slice(5));
            let option = {
                title: {
                    text: title,
                    textStyle: {
                        fontSize: 14,
                    },
                    right: 10,
                    top: 6
                },

                grid: {
                    x: 12,
                    y: 35,
                    x2: 12,
                    y2: 22,
                    borderWidth: 1
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: [...x],
                    splitLine: {
                        show: false // 隐藏 x 轴的分隔线
                    },
                    axisTick: {
                        show: false,// 隐藏 x 轴的刻度线（即坐标文字旁的小竖线）

                    },
                    axisLabel: {
                        show: true, // 保留 x 轴的标签,
                        color: '#999999',
                        formatter(val, i) {
                            if (i == 0) {
                                return `{start| ${val}}`
                            } else if (i == x.length - 1) {
                                return `{end| ${val}}`
                            } else {
                                return val
                            }
                        },
                        rich: richCss
                    },

                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#F2F2F4',  // 设置轴线颜色为红色
                            type: 'dashed' // 设置轴线为虚线
                        }
                    }


                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        show: false
                    },
                    splitNumber: 0.5,
                    splitLine: {
                        show: false,// 隐藏 y 轴的分隔线
                        lineStyle: {
                            color: '#F2F2F4',  // 设置轴线颜色为红色
                            type: 'dashed' // 设置轴线为虚线
                        }
                    },
                    axisLabel: {
                        show: false // 隐藏 y 轴的数字标签
                    },



                },
                tooltip: {
                    trigger: "axis",
                    formatter: (params) => {
                        let str = ''
                        params.forEach((item) => {
                            const value = item.value
                            // console.log(item, "pppp");

                            str = this.toolTipFormatter(item.axisValue, item.marker, value, y, item.dataIndex, title)
                        })
                        return str
                    },
                },
                series: [
                    {
                        data: [...y],
                        type: "line",
                        showSymbol: false,
                        smooth: true,
                        areaStyle: {
                            // 设置渐变色
                            color: {
                                type: 'linear',
                                x: 0, // 渐变色的起始点 x 轴位置
                                y: 0, // 渐变色的起始点 y 轴位置
                                x2: 0, // 渐变色的结束点 x 轴位置
                                y2: 1, // 渐变色的结束点 y 轴位置
                                colorStops: [
                                    { offset: 0, color: 'rgba(0, 0, 255, 0.2)' }, // 起始颜色
                                    { offset: 1, color: 'rgba(0, 0, 255, 0)' }  // 结束颜色
                                ],
                                global: false // 使用局部渐变色
                            },
                            origin: 'start'

                        }
                    }
                ]
            };
            myChart.getZr().on('draw', function () {
                var yAxis = myChart.getOption().yAxis[0];
                var minY = yAxis.min;
                var minYIndex = myChart.getOption().grid.splitNumber - 1;
                var minLine = myChart.getZr().painter.getLayer(1).children()[minYIndex];

                minLine.setStyle({
                    lineWidth: 2,
                    stroke: '#000'
                });
            });
            option && myChart.setOption(option);
            window.onresize = () => {
                // 基础数据
                myChart.resize()

            }


        },
        toolTipFormatter(axisValue, marker, value, dataList = [], dataIndex, desc = "") {
            let percent_value
            let percent = null
            // console.log(this.oldEchartData, dataList[0], "this.oldEchartData");

            if (dataIndex == 0) {
                if (this.oldEchartData == 0) {
                    percent = 0
                } else {
                    percent = parseFloat((dataList[0] - this.oldEchartData) / this.oldEchartData);
                }

            } else {

                if (Number(dataList[dataIndex - 1]) == 0) {

                    if (Number(dataList[dataIndex]) > 0) {
                        percent = 1
                    } else if (Number(dataList[dataIndex]) == 0) {
                        percent = 0
                    } else {
                        percent = -1
                    }
                } else {
                    percent = parseFloat((dataList[dataIndex] - dataList[dataIndex - 1]) / dataList[dataIndex - 1]);
                }


            }
            if (percent > 0) {
                percent_value = (percent * 100).toFixed(2)
                percent_value = `<div style="color: #F93353;font-size:13px;font-weight:600">${percent_value}%<div>`
            } else if (percent < 0) {
                percent_value = (percent * 100).toFixed(2)

                percent_value = `<div style="color: #00B47F;font-size:13px;font-weight:600">${percent_value}%<div>`
            } else {
                percent_value = `<div style="color: #000;font-size:13px;font-weight:600">0.00%<div>`
            }
            return `
              <div style="width: 150px;">
                <div style="color: #51515C;">${axisValue}</div>
                <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
                    <div>${marker} <span style="color: #292A37;font-weight: bold;">${desc}</span></div>
                    <div style="font-weight: bold; color: #292A37;">${value}</div>
                </div>
                <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
                    <div style="color: #94949B; font-size:13px; font-weight:600">比前1日</div>
                    ${percent_value}
                </div>
              </div>
          `;
        },
        // 获取最新作品
        getLatestWork() {
            let params = {
                id: this.accountId
            }
            this.$httpStudent.axiosGetBy(this.$api.dataCenterNewestVideo, params, res => {
                if (res.code == 200) {
                    this.latestWork = res.data ? res.data : {}
                }

            }, error => {

            })

        },
        getContentAnalysis() {
            // this.$router.push({
            //     path: '/student/dataCenter/ContentDetail',
            //     query: {
            //         accountIds: this.accountId,
            //         id: this.latestWork.id
            //     }
            // })
            const url = `#/student/dataCenter/ContentDetail?accountIds=${this.accountId}&id=${this.latestWork.id}`;
            window.open(url, '_blank');
        }

    }
}
</script>
<style lang="scss" scoped>
P {
    margin: 0;
    padding: 0;
}

.data_center_box {
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;

    .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        .title_left {
            color: #BBBBBD;

            .data_title {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 18px;
                color: #252632;
            }

            .title_icon {
                margin: 0 5px;
            }

            .time {
                font-weight: 400;
                font-size: 12px;
                color: #888888;
            }
        }

        .title_right {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #888888;
            cursor: pointer;
        }
    }

    .show_data {
        width: 100%;
        display: flex;
        margin-top: 10px;

        .data_left {
            width: 210px;
            box-sizing: border-box;
            border-right: 1px solid #F2F2F4;
            padding-right: 16px;
            box-sizing: border-box;
            flex-shrink: 0;

            .new_work {
                padding: 10px 0;
                margin-bottom: 2px;

            }

            .video {
                margin-top: 10px;
                width: 100%;
                height: 272px;
                //  background-color: skyblue;
                border-radius: 8px;
                font-size: 0;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                }

                .desc {
                    width: 92%;
                    position: absolute;
                    left: 10px;
                    top: 20px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 500;
                    z-index: 100;

                }

                .desc_type {
                    top: 10px;
                    display: flex;
                    justify-content: flex-end;
                }

                .video_info {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    //  background: linear-gradient(180deg, rgba(30, 30, 30, 0), rgba(30, 30, 30, .5));
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    bottom: 0;
                    box-sizing: border-box;
                    cursor: pointer;
                    height: 100%;


                    overflow: hidden;
                    padding-bottom: 16px;
                    transition: all .25s ease-in-out;

                    .no_content {
                        width: 100%;
                        height: 81%;
                        background-color: transparent;
                        transition: all .25s ease-in-out;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;

                    }

                    .content {
                        width: 100%;
                        padding: 5px 20px;
                        box-sizing: border-box;
                        backdrop-filter: blur(10px);
                        // background:  linear-gradient(180deg, rgba(30, 30, 30, 0), rgba(30, 30, 30, .5));

                        p {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            font-size: 12px;
                            color: #FFFFFF;
                            padding: 5px 0;

                            span {
                                display: inline-block;
                            }
                        }
                    }




                    &:hover {
                        backdrop-filter: blur(10px);
                        // background:  linear-gradient(180deg, rgba(30, 30, 30, 0), rgba(30, 30, 30, .5));
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;

                        .no_content {
                            height: 46%;

                        }

                        .content {
                            background-color: transparent;
                        }



                    }


                }

            }

            .no_video {
                width: 100%;
                height: 272px;
                background: #F9F9FA;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    width: 120px;
                    height: 120px;
                }

                .text {
                    width: 130px;
                    white-space: wrap;
                    text-align: center;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(28, 31, 35, 0.55);

                }
            }
        }

        .data_right {
            width: 84%;
            box-sizing: border-box;
            margin-left: 1%;

            .data_header {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #F2F2F4;
                align-items: center;

                .header_left {
                    display: flex;

                    .type_item {
                        padding: 10px 0;
                        margin-right: 20px;
                        cursor: pointer;
                    }

                    .active_type_item {
                        border-bottom: 2px solid #685EF5;
                    }


                }

                .header_right {
                    background: #F2F2F4;
                    //  border-radius: 4px 4px 4px 4px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 12px;
                    color: #888888;
                    padding-left: 10px;
                    padding-right: 2px;
                    border-radius: 6px;
                    border: 1px solid transparent;

                    &:hover {
                        // border: 1px solid #FF0000;
                    }

                    .el-select {
                        width: 90px;
                        //  border-top-right-radius: 4px !important;
                        //  border-bottom-right-radius: 4px !important;

                        ::v-deep .el-input__inner {
                            height: 24px;
                            line-height: 24px;
                            border-color: transparent;
                            background: #F2F2F4;
                            border-radius: 0px;
                            padding: 0 10px;
                            //  border-top-right-radius: 4px !important;
                            //  border-bottom-right-radius: 4px !important;

                        }

                        ::v-deep .el-input__icon {
                            line-height: 24px;
                        }




                    }
                }
            }

            .data_box {

                width: 100%;

                .no_echarts {
                    width: 100%;
                    height: 262px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: #F9F9FA;
                    border-radius: 4px 4px 4px 4px;
                    margin-top: 10px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(28, 31, 35, 0.55);

                }

                .echarts {
                    width: 100%;

                    .data_echarts {
                        width: 100%;
                        height: 160px;
                        //  background-color: skyblue;
                    }

                    .select_box {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;

                        .select_item {
                            width: 33.33%;
                            padding: 10px 10px;
                            box-sizing: border-box;
                            cursor: pointer;

                            &:hover {
                                background-color: #f9f9fa;
                            }

                            .selectType {
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 500;
                                font-size: 12px;
                                color: #252632;

                            }

                            .activeSelectType {
                                position: relative;
                                padding-left: 10px;
                                transition: padding-left 0.3s ease;
                                /* 文字位置的过渡动画 */
                                color: #685EF5;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 4px;
                                    /* 竖条的宽度 */
                                    height: 100%;
                                    /* 竖条的高度，与内容区域高度一致 */
                                    background-color: #685EF5;
                                    /* 竖条的颜色 */
                                    border-radius: 4px;
                                }
                            }

                            .number {
                                display: flex;
                                align-items: center;

                                span {
                                    display: inline-block;
                                }

                                .num {
                                    font-family: Douyin Sans, Douyin Sans;
                                    font-weight: bold;
                                    font-size: 20px;
                                    color: #000000;
                                }

                                .date {
                                    font-family: PingFang SC, PingFang SC;
                                    font-weight: 400;
                                    font-size: 12px;
                                    color: #999999;
                                    margin-left: 5px;
                                }

                                .changenum {
                                    font-family: PingFang SC, PingFang SC;
                                    font-weight: 400;
                                    font-size: 12px;
                                    margin-left: 5px;
                                }
                            }

                        }
                    }


                }

                .recentWorks_item {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 58px;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    cursor: pointer;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 12px;
                    color: #242632;

                    &:hover {
                        background-color: #F9F9FA;

                        .recent_data {
                            .Works_data {
                                .viewAnalysis {
                                    width: 20%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                }
                            }
                        }
                    }


                    .image {
                        width: 32px;
                        height: 42px;
                        border-radius: 4px 4px 4px 4px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px 4px 4px 4px;
                            object-fit: cover;
                        }
                    }

                    .recent_data {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-left: 10px;

                        .description {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 5px;

                            .status_name {
                                flex: 1;
                                width: 0;
                                flex-shrink: 0;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                line-height: 1.5;
                                max-height: 3em;
                                padding-right: 10px;
                                box-sizing: border-box;

                            }

                            .time {
                                width: 165px;
                                display: block;
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 400;
                                font-size: 12px;
                                color: #888888;


                                span:nth-of-type(1) {
                                    color: #61C26E;
                                    margin-right: 10px;
                                }

                            }
                        }

                        .Works_data {
                            width: 100%;
                            display: flex;
                            align-items: center;

                            .work_item {
                                width: 20%;

                                span:nth-of-type(1) {
                                    color: #888888;
                                }

                                span:nth-of-type(2) {
                                    font-family: Douyin Sans, Douyin Sans;
                                    font-weight: bold;
                                    font-size: 14px;
                                    color: #242632;
                                    margin-left: 10px;
                                }
                            }

                            .viewAnalysis {
                                width: 20%;
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 400;
                                font-size: 12px;
                                color: #685EF5;
                                cursor: pointer;
                                display: none;
                                //  display: flex;
                                //  align-items: center;
                                //  justify-content: flex-end;
                            }

                        }
                    }
                }

                .no_video {
                    width: 100%;
                    height: 262px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: #F9F9FA;
                    margin-top: 10px;
                    border-radius: 4px 4px 4px 4px;

                }



            }

        }


    }

}
</style>
<style>
.eloption {
    /* min-width: 120px !important; */
    left: 100% !important;
    /* right: 38px !important; */
    transform: translateX(-100%);
    /* right: 38px !important; */

    .el-select-dropdown__item.selected {
        color: #252632;
        font-weight: 600;
        padding: 0 40px;
    }

    .el-select-dropdown__item {
        padding: 0 40px;
    }

    .el-select-dropdown__item.selected::before {
        content: '✓';
        /* 对号的 Unicode 字符 */
        color: #666;
        /* 对号的颜色，可以根据需要修改 */
        font-size: 16px;
        /* 对号的大小，根据需要调整 */
        position: absolute;
        /* 绝对定位 */
        left: 15px;
        /* 根据需要调整对号的位置 */
        top: 50%;
        /* 垂直居中对号 */
        transform: translateY(-50%);
        /* 垂直居中对号 */
    }

    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
        background-color: rgb(242, 242, 244);
    }
}
</style>
