import { render, staticRenderFns } from "./ArticleAudit.vue?vue&type=template&id=e95f8bf0&scoped=true"
import script from "./ArticleAudit.vue?vue&type=script&lang=js"
export * from "./ArticleAudit.vue?vue&type=script&lang=js"
import style0 from "./ArticleAudit.vue?vue&type=style&index=0&id=e95f8bf0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e95f8bf0",
  null
  
)

export default component.exports