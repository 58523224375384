<template>
    <div class="work-bench">
        <div class="header">
            <WorkHeader @selectAccountData="selectAccountData" />
        </div>
        <div class="newCreate_box">
            <div class="newCreate">
                <p>新的创作</p>
                <div class="content_box">
                    <div class="item_box" v-for="item in createList">
                        <div class="icon"></div>
                        <div class="icon_image">
                            <img :src="item.icon" alt="">
                        </div>
                        <div class="text_box">
                            <p class="name">{{ item.name }}</p>
                            <p class="tip">{{ item.tip }}</p>
                        </div>
                        <div class="disable_btn"></div>
                    </div>

                </div>

            </div>
            <div class="quick_navigation">
                <p>快捷导航</p>
                <div class="content_box">
                    <div class="item_box" @click="goDetail(1)">
                        <img src="../../../assets/img/work/sucai.png" alt="">
                        <span>素材查找</span>

                    </div>
                    <div class="item_box" @click="goDetail(2)">
                        <img src="../../../assets/img/work/jiaoben.png" alt="">
                        <span>脚本创作</span>

                    </div>
                    <div class="item_box" @click="goDetail(3)">
                        <img src="../../../assets/img/work/shipin.png" alt="">
                        <span>视频创作</span>

                    </div>
                    <div class="item_box" @click="goDetail(4)">
                        <img src="../../../assets/img/work/shuju.png" alt="">
                        <span>数据分析</span>

                    </div>

                </div>

            </div>

        </div>
        <div class="data_center">
            <DataCenter :accountId="accountId" />
        </div>
        <div class="work-task">
            <div class="task">
                <div class="view-more" @click="viewMore(1)">查看更多 ></div>
                <el-tabs v-model="taskTab" @tab-click="changeTab" style="width: 100%;">
                    <el-tab-pane label="教师任务" name="teacher">
                        <div class="task-list" v-if="teacherTaskList.length != 0">
                            <div class="list-item" v-for="item in teacherTaskList" @click="viewTaskDetail(item)">
                                <div class="container">
                                    <div class="item-cover">
                                        <img :src="item.task_cover" alt="">
                                        <!-- <img src="../../../assets/img/work/image1.png" alt=""> -->
                                        <span class="sign" v-if="item.task_type === 1">普通任务</span>
                                        <span class="sign" v-if="item.task_type === 3">实训任务</span>
                                        <span class="sign" v-if="item.task_type === 4">脚本任务</span>
                                    </div>
                                    <div class="item-content">
                                        <span class="item-title">{{ item.task_title }}</span>
                                        <p class="task_money"><span>佣金:￥</span><span>{{ item.task_money }}1200</span>
                                        </p>
                                        <p class="join_num">参与人数: {{ item.join_num }}人</p>
                                    </div>
                                    <div class="item-status">
                                        <span>发布时间: {{ item.create_time }}</span>
                                        <span class="task_btn" style="color:#594FEE; background-color: #EBEAFF;"
                                            v-if="item.state === 2">查看任务</span>
                                        <span class="task_btn" style="color:#20C997 ;background-color: #d9f3ec;"
                                            v-if="item.state === 1">已完成</span>
                                        <span class="task_btn" style="color:#666666 ;background-color: #e3e3e3;"
                                            v-if="item.state === 0">已截止</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="task-list no-task-list">
                            <img src="../../../assets/img/work/no_data.png" alt="">
                            <span>暂无数据</span>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="企业任务" name="company">
                        <div class="task-list" v-if="companyTaskList.length != 0">
                            <div class="list-item" v-for="item in companyTaskList" @click="viewTaskDetail(item)">
                                <div class="container">
                                    <div class="item-cover">
                                        <img :src="item.task_cover" alt="">
                                        <span class="sign" v-if="item.enroll === '已报名'">已报名</span>
                                        <span class="sign" v-else-if="item.enroll === '需报名'">需要报名</span>
                                        <span class="sign" v-else>合作资讯</span>
                                    </div>
                                    <div class="item-content">
                                        <span class="item-title">{{ item.task_title }}</span>
                                        <p class="task_money"><span>佣金:￥</span><span>{{ item.task_money }}1200</span>
                                        </p>
                                        <p class="join_num">参与人数: {{ item.join_num }}人</p>
                                    </div>
                                    <div class="item-status">
                                        <span>发布时间: {{ item.create_time }}</span>
                                        <span class="task_btn" style="color:#594FEE; background-color: #EBEAFF;"
                                            v-if="item.state === 2">查看任务</span>
                                        <span class="task_btn" style="color:#20C997 ;background-color: #d9f3ec;"
                                            v-if="item.state === 1">已完成</span>
                                        <span class="task_btn" style="color:#666666 ;background-color: #e3e3e3;"
                                            v-if="item.state === 0">已截止</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="task-list no-task-list">
                            <img src="../../../assets/img/work/no_data.png" alt="">
                            <span>暂无数据</span>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="task-right">
                <div class="show-right">
                    <div class="show-name">
                        <span v-if="activeTable">推荐榜</span>
                        <span v-else>高佣榜</span>
                    </div>
                    <div class="show-switch" @click="switchTaskTable(activeTable)">
                        <div class="switch-icon">
                            <i class="iconfont">&#xe65f;</i>
                        </div>
                        <div class="switch-text">切换</div>
                    </div>
                </div>
                <div class="task-right-box box1">
                    <el-table style="width: 100%; margin-top: 10px;" @row-click="viewTaskDetail" class="rank-table"
                        :data="taskRightList" size="medium"
                        :header-cell-style="{ fontWeight: 'normal', color: '#333', background: '#EFEEFF' }"
                        :cell-style="{ fontSize: '14px', color: '#333', height: '50px', cursor: 'pointer' }">
                        <el-table-column align="left" prop="rank" label="排行" width="100">
                            <template slot-scope="scope">
                                <div class="icon-background">
                                    <img v-if="scope.row.rank === 1" src="../../../assets/img/work/one.png" alt="">
                                    <img v-if="scope.row.rank === 2" src="../../../assets/img/work/two.png" alt="">
                                    <img v-if="scope.row.rank === 3" src="../../../assets/img/work/three.png" alt="">
                                </div>
                                <div class="rank-title">{{ scope.row.rank }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column align="left" prop="task_title" label="任务名称" width='260'>
                            <template slot-scope="scope">
                                <div class="task-title">{{ scope.row.task_title }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="activeTable" align="center" prop="enroll_num" label="报名数" width='100'>
                            <template slot-scope="scope">
                                <span>{{ scope.row.enroll_num ? scope.row.enroll_num : '暂无' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column v-else align="center" prop="task_money" label="佣金" width='100'>
                            <template slot-scope="scope">
                                <span>{{ scope.row.task_money ? scope.row.task_money : '暂无' }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="all_data_center">
            <div class="data_left">
                <p class="title">创作中心</p>
                <div class="data_content">
                    <div class="rank">
                        <div class="item">
                            <div class="header">
                                <span class="item_title">作品排行榜</span>
                            </div>
                            <div class="has_list" v-if="videoRankList.length > 0">
                                <template v-for="(item, index) in videoRankList">
                                    <div class="all_data" v-if="index <= 5" @click="viewVideo(item.video_url)">
                                        <div class="image">
                                            <img :src="item.cover_url" alt="">
                                            <div class="icon_circle"
                                                :style="{ 'background-color': index == 0 ? '#FF2C55' : index == 1 ? '#FF851D ' : index == 2 ? '#FACE15' : '#929399' }">
                                                {{ index + 1 }}
                                            </div>
                                        </div>
                                        <div class="video_info_box">
                                            <p class="video_title">{{ item.desc }}</p>
                                            <p class="video_info">
                                                <span class="infor_item">播放量 <span class="number">{{
                                                    getNumber(item.play_count)
                                                        }}</span></span>
                                                <span class="infor_item">评论 <span class="number">{{
                                                    getNumber(item.comment_count) }}</span></span>
                                                <span class="infor_item">点赞 <span class="number">{{
                                                    getNumber(item.digg_count)
                                                        }}</span></span>
                                            </p>

                                        </div>

                                    </div>
                                </template>
                                <div class="bottom" @click="viewAllVideo(1)">
                                    查看全部 >
                                </div>
                            </div>
                            <div class="no_list" v-else>
                                <img src="../../../assets/img/work/no_data.png" alt="">
                                <span>暂无数据</span>
                            </div>

                        </div>
                    </div>
                    <div class="rank">
                        <div class="item">
                            <div class="header">
                                <span class="item_title">热门视频</span>
                            </div>
                            <div class="has_list" v-if="videoHotList.length > 0">
                                <template v-for="(item, index) in videoHotList">
                                    <div class="all_data" v-if="index <= 5" @click="viewVideo(item.share_url)">
                                        <div class="image">
                                            <img :src="item.item_cover" alt="">
                                            <div class="icon_circle"
                                                :style="{ 'background-color': index == 0 ? '#FF2C55' : index == 1 ? '#FF851D ' : index == 2 ? '#FACE15' : '#929399' }">
                                                {{ index + 1 }}
                                            </div>
                                        </div>
                                        <div class="video_info_box">
                                            <p class="video_title">{{ item.title }}</p>
                                            <p class="video_info">
                                                <span class="infor_item" style="width: 100%;">热度 <span class="number">{{
                                                    getNumber(item.hot_value)
                                                        }}</span></span>

                                            </p>

                                        </div>

                                    </div>
                                </template>
                                <div class="bottom" @click="viewAllVideo(2)">
                                    查看全部 >
                                </div>
                            </div>
                            <div class="no_list" v-else>
                                <img src="../../../assets/img/work/no_data.png" alt="">
                                <span>暂无数据</span>
                            </div>


                        </div>
                    </div>
                    <div class="rank">
                        <div class="item">
                            <div class="header">
                                <span class="item_title">热点榜单</span>
                            </div>
                            <div class="has_list" v-if="videoHotRankList.length > 0">
                                <template v-for="(item, index) in videoHotRankList">
                                    <div class="all_data" v-if="index <= 5" @click="viewVideo(item.share_url)">
                                        <div class="image">
                                            <img :src="item.cover" alt="">
                                            <div class="icon_circle"
                                                :style="{ 'background-color': index == 0 ? '#FF2C55' : index == 1 ? '#FF851D ' : index == 2 ? '#FACE15' : '#929399' }">
                                                {{ index + 1 }}
                                            </div>
                                        </div>
                                        <div class="video_info_box">
                                            <p class="video_title">{{ item.sentence }}</p>
                                            <p class="video_info">
                                                <span class="infor_item" style="width: 100%;">热度 <span class="number">{{
                                                    getNumber(item.hot_level)
                                                        }}</span></span>

                                            </p>

                                        </div>

                                    </div>
                                </template>
                                <div class="bottom" @click="viewAllVideo(3)">
                                    查看全部 >
                                </div>
                            </div>
                            <div class="no_list" v-else>
                                <img src="../../../assets/img/work/no_data.png" alt="">
                                <span>暂无数据</span>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <div class="data_right">
                <div class="fans_header">
                    <span class="fans_title">粉丝排行榜</span>
                    <span class="view_more" @click="viewFanChart()">查看更多 ></span>
                </div>
                <template v-for="(item, index) in fansRankList">
                    <div class="fans_list" v-if="index <= 7 && fansRankList.length > 0">
                        <div class="image_box">
                            <img :src="item.avatar" alt="">

                        </div>
                        <div class="fans_info">
                            <p class="fans_name">{{ item.nickname }}</p>
                            <div class="fans_number">
                                <div class="fans">
                                    <span>粉丝 <span style="color: #252632 ;">{{ getNumber(item.fans_num) }}</span></span>
                                    <span>昨日 <span
                                            :style="{ color: item.fans_num_yesterday <= 0 ? '#252632 ' : '#FF2C55' }">{{
                                                    item.fans_num_yesterday <= 0 ? 0 : `+${item.fans_num_yesterday}` }}</span>
                                        </span>
                                </div>
                                <div class="fans">
                                    <span>作品 <span style="color: #252632 ;">{{ getNumber(item.aweme_count)
                                            }}</span></span>
                                    <span>昨日
                                        <span
                                            :style="{ color: item.aweme_count_yesterday <= 0 ? '#252632 ' : '#FF2C55' }">{{
                                                    item.aweme_count_yesterday <= 0 ? 0 : `+${item.aweme_count_yesterday}`
                                            }}</span>
                                        </span>

                                </div>
                            </div>

                        </div>
                    </div>

                </template>
                <div class="no_list" v-if="fansRankList.length == 0">
                    <img src="../../../assets/img/work/no_data.png" alt="">
                    <span>暂无数据</span>
                </div>




            </div>

        </div>




    </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import WorkHeader from "@/components/workBech/WorkHeader.vue"
import DataCenter from "@/components/workBech/DataCenter.vue"
import { query } from 'quill';

export default {
    components: {
        WorkHeader,
        DataCenter
    },
    directives: {
        swiper: directive
    },
    name: "WorkBench",
    data() {
        return {
            createList: [
                {
                    name: "发布视频",
                    tip: "支持常用格式，推荐mp4、webm",
                    icon: require("../../../assets/img/work/videoIcon.png"),
                    backgroundUrl: require("../../../assets/img/work/video.png")
                },
                {
                    name: "发布图文",
                    tip: "支持常用图片格式，png、jpg",
                    icon: require("../../../assets/img/work/tuwenIcon.png"),
                    backgroundUrl: require("../../../assets/img/work/tuwen.png")
                },
                {
                    name: "发布全景视频",
                    tip: "推荐分辨率为4K (3840x1920) 及以上",
                    icon: require("../../../assets/img/work/quanjingIcon.png"),
                    backgroundUrl: require("../../../assets/img/work/quanjing.png")
                },
            ],
            accountId: 0,
            teacherTaskList: [],//教师任务列表
            activeTable: true,//true为推荐榜
            companyTaskList: [],//企业任务列表
            taskRightList: [],//推荐榜 or 高佣榜
            taskTab: "teacher",
            videoRankList: [],//作品排行榜
            videoHotList: [],//热门视频
            videoHotRankList: [],//热点榜单
            fansRankList: [],//粉丝排行榜,
            workTotal: 0,
            videoHotListTotal: 0,
            videoHotRankListTotal: 0
        }
    },
    mounted() {
        this.getTeacherTask();//教师任务
        this.getCompanyTask();//企业任务
        this.getTaskRightList(1);//推荐榜 or 高佣榜
        this.getHotVideoData()//热门视频
        this.getHotSortVideoData()//热点榜单


    },
    methods: {
        selectAccountData(val) {
            // console.log(val.id, "账户信息");
            this.accountId = val.id
            this.getVideoData()//作品排行榜
            this.getFanRankData()//粉丝排行榜

        },
        goDetail(type) {
            if (type == 1) {
                this.$router.push('/student/hot/scripts')
            } else if (type == 2) {
                this.$router.push('/student/script/creation')

            } else if (type == 3) {
                this.$router.push('/student/video/clip')

            } else {
                this.$router.push('/student/dataAnalysis/index')

            }

        },
        viewMore(val) {
            if (val == 3) {
                this.$router.push('/student/dy/products/rank');
                this.$emit('changeMenu', 'studentDyProductsRank');
            } else {
                this.$emit('changeMenu', 'studentTaskIndex');
                this.$router.push({
                    path: '/student/task/index',
                    query: {
                        taskTab: this.taskTab
                    }
                });
            }
        },
        switchTaskTable(val) {
            this.activeTable = !val;
            let param = val ? 0 : 1;
            this.getTaskRightList(param)
        },
        getTaskRightList(val) {
            let param = {
                type: 2,
                page: 1,
                limit: 6,
                task_money: 'desc',
                enroll: 'desc',
            }
            if (val == 1) {
                delete param.task_money
            } else {
                delete param.enroll
            }
            this.$httpStudent.axiosGetBy(this.$api.studentTaskList, param, res => {
                if (res.code == 200) {
                    this.taskRightList = res.list;
                    let len = this.taskRightList.length;
                    this.taskRightList.forEach(item => {
                        if (item.task_is_apply === 3) {
                            item.enroll_num = item.task_number_num
                        }
                    })
                    if (val == 1) {
                        this.taskRightList.sort(function (a, b) {
                            return b.enroll_num - a.enroll_num
                        })
                    }
                    this.taskRightList.forEach((item, index) => {
                        item.rank = index + 1;
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            }, (err) => {
            })
        },
        getTeacherTask() {
            Promise.all(([
                this.getTeacherTaskList(2),
                this.getTeacherTaskList(0),
                this.getTeacherTaskList(1),
            ])).then(([res1, res2, res3]) => {
                if (res1.length >= 3) {
                    this.teacherTaskList = res1;
                } else {
                    res1.forEach(item => {
                        if (item) {
                            this.teacherTaskList.push(item)
                        }
                    })
                    let len = 3 - res1.length;
                    if (res2.length > len) {
                        for (let i = 0; i < len; i++) {
                            this.teacherTaskList.push(res2[i])
                        }
                    } else {
                        res2.forEach(item => {
                            if (item) {
                                this.teacherTaskList.push(item)
                            }
                        })

                        let newLen = 3 - this.teacherTaskList.length;
                        if (res3.length > newLen) {
                            for (let i = 0; i < newLen; i++) {
                                this.teacherTaskList.push(res3[i])
                            }
                        } else {
                            res3.forEach(item => {
                                if (item) {
                                    this.teacherTaskList.push(item)
                                }
                            })
                        }
                    }
                }
                let len = this.teacherTaskList.length;
                for (let i = 0; i < len; i++) {
                    for (let j = i + 1; j < len; j++) {
                        if (this.teacherTaskList[i].task_id == this.teacherTaskList[j].task_id) { //第一个等同于第二个，splice方法删除第二个
                            this.teacherTaskList.splice(j, 1)
                            j--;
                        }
                    }
                }
            }).catch(err => {
            });
        },
        getCompanyTask() {
            Promise.all(([
                this.getCompanyTaskList(2),
                this.getIntelligentList(),
                this.getCompanyTaskList(0),
                this.getCompanyTaskList(1),
            ])).then(([res1, res2, res3, res4]) => {
                if (res1.length >= 3) {
                    this.companyTaskList = res1;
                } else {
                    res1.forEach(item => {
                        if (item) {
                            this.companyTaskList.push(item)
                        }
                    })
                    let len = 3 - res1.length;
                    if (res2.length > len) {
                        for (let i = 0; i < len; i++) {
                            this.companyTaskList.push(res2[i])
                        }
                    } else {
                        res2.forEach(item => {
                            if (item) {
                                this.companyTaskList.push(item)
                            }
                        })

                        let newLen = 3 - this.companyTaskList.length;
                        if (res3.length > newLen) {
                            for (let i = 0; i < newLen; i++) {
                                this.companyTaskList.push(res3[i])
                            }
                        } else {
                            res3.forEach(item => {
                                if (item) {
                                    this.companyTaskList.push(item)
                                }
                            })
                            let newLen1 = 3 - this.companyTaskList.length;
                            if (res4.length > newLen1) {
                                for (let i = 0; i < newLen1; i++) {
                                    this.companyTaskList.push(res4[i])
                                }
                            } else {
                                res4.forEach(item => {
                                    if (item) {
                                        this.companyTaskList.push(item)
                                    }
                                })
                            }
                        }
                    }
                }
                let len = this.companyTaskList.length;
                for (let i = 0; i < len; i++) {
                    for (let j = i + 1; j < len; j++) {
                        if (this.companyTaskList[i].task_id == this.companyTaskList[j].task_id) { //第一个等同于第二个，splice方法删除第二个
                            this.companyTaskList.splice(j, 1)
                            j--;
                        }
                    }
                }
            }).catch(err => {
            });
        },
        // 获取任务列表
        getTeacherTaskList(val) {
            return new Promise((resolve, reject) => {
                let param = {
                    type: 1,
                    page: 1,
                    limit: 3,
                    task_state: val
                }
                this.$httpStudent.axiosGetBy(this.$api.studentTaskList, param, res => {
                    if (res.code == 200) {
                        resolve(res.list);
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                }, (err) => {
                })
            });
        },
        getIntelligentList() {
            return new Promise((resolve, reject) => {
                let param = {
                    page: 1,
                    limit: 3
                }
                this.$httpStudent.axiosGetBy(this.$api.intelligent_getTaskCooperationList, param, res => {
                    if (res.code == 200) {
                        resolve(res.data.data);
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                })
            });
        },
        getCompanyTaskList(val) {
            return new Promise((resolve, reject) => {
                let param = {
                    type: 2,
                    page: 1,
                    limit: 3,
                    task_state: val
                }
                this.$httpStudent.axiosGetBy(this.$api.studentTaskList, param, res => {
                    if (res.code == 200) {
                        resolve(res.list);
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                }, (err) => {
                })
            });
        },
        getTaskRightList(val) {
            let param = {
                type: 2,
                page: 1,
                limit: 6,
                task_money: 'desc',
                enroll: 'desc',
            }
            if (val == 1) {
                delete param.task_money
            } else {
                delete param.enroll
            }
            this.$httpStudent.axiosGetBy(this.$api.studentTaskList, param, res => {
                if (res.code == 200) {
                    this.taskRightList = res.list;
                    let len = this.taskRightList.length;
                    this.taskRightList.forEach(item => {
                        if (item.task_is_apply === 3) {
                            item.enroll_num = item.task_number_num
                        }
                    })
                    if (val == 1) {
                        this.taskRightList.sort(function (a, b) {
                            return b.enroll_num - a.enroll_num
                        })
                    }
                    this.taskRightList.forEach((item, index) => {
                        item.rank = index + 1;
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            }, (err) => {
            })
        },
        changeTab() {
            let val = this.taskTab === 'teacher' ? 1 : 2;
            if (val == 1) {
                this.teacherTaskList = [];
                this.getTeacherTask()
            } else {
                this.companyTaskList = [];
                this.getCompanyTask()
            }
        },
        //查看任务详情
        viewTaskDetail(taskItem) {
            if (taskItem.userData) {
                this.$router.push({
                    path: '/student/task/detail',
                    query: {
                        id: taskItem.task_id,
                        user_id: taskItem.userData[0].user_account_id
                    }
                })
            } else {
                this.$router.push({
                    path: '/student/task/detail',
                    query: {
                        id: taskItem.task_id
                    }
                })
            }
            this.$emit('changeMenu', 'studentTaskIndex');
        },
        getVideoData() {
            let param = {
                id: this.accountId
            }
            this.$httpStudent.axiosGetBy(this.$api.account_videoRanking, param, res => {
                if (res.code == 200) {
                    this.videoRankList = res.data;
                    let len = this.videoRankList.length;
                    for (let i = 0; i < len; i++) {
                        this.videoRankList[i].rank = i + 1;
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            });
            // let param = {
            //     paging: '1',
            //     page: 1,
            //     pageSize: 6,
            //     account_id: this.accountId
            // }
            // this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_video, param, (res) => {
            //     this.videoRankList = res.data.list;
            //     this.workTotal = res.data.total
            // })
        },
        viewAllVideo(type) {
            if (type == 1) {
                // 作品排行榜
                // this.$router.push("/student/video/managementNew")
                this.$router.push({
                    path: "/student/video/managementNew",
                    query: {
                        accountId: this.accountId
                    }
                })
            } else if (type == 2) {
                // 热门视频
                this.$router.push("/student/hot/video")
            } else {
                // 热点榜单
                this.$router.push("/student/hot/hotspot")
            }
        },
        getHotVideoData() {
            let param = {
                page: 1,
                limit: 6
            }
            this.$httpStudent.axiosGetBy(this.$api.billboardHotVideo, param, res => {
                if (res.code == 200) {
                    this.videoHotList = res.data.list;
                    this.videoHotListTotal = res.data.total

                } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            });
        },
        getHotSortVideoData() {
            let param = {
                page: 1,
                limit: 6
            }
            this.$httpStudent.axiosGetBy(this.$api.billboardHotSearchRanking, param, res => {
                if (res.code == 200) {
                    this.videoHotRankList = res.data.list;
                    this.videoHotRankListTotal = res.data.total


                } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            });
        },
        getFanRankData() {
            let params={
                limit:10
            }
            this.$httpStudent.axiosGetBy(this.$api.account_fansRanking,params, res => {
                if (res.code == 200) {
                    this.fansRankList = res.data.list;

                } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            });

        },
        viewVideo(url) {
            window.open(url, '_blank')

        },
        viewFanChart() {
            this.$router.push("/student/works/fanChart")

        },
        getNumber(num) {
            if (num >= 100000000) {
                return (num / 10000 / 10000).toFixed(2) + "亿"
            } else if (num >= 10000) {
                return (num / 10000).toFixed(2) + "万"
            } else {
                return num
            }

        }
    }
}
</script>

<style scoped lang="scss">
p {
    margin: 0;
    padding: 0;
}

.work-bench {
    // width: 100%;
    // height: 800px;
    // background-color: pink;
    overflow-x: auto;

    .header {
        width: 100%;
    }

    .newCreate_box {
        width: 100%;
        height: 153px;
        margin-top: 20px;
        display: flex;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #252632;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        text-transform: none;



        .newCreate {
            width: 75%;
            // min-width: 800px;
            height: 100%;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            padding: 16px 16px 0px;
            box-sizing: border-box;

            p {
                font-size: 18px;
            }

            .content_box {
                width: 100%;
                display: flex;
                padding-top: 15px;
                box-sizing: border-box;


                .item_box {
                    width: 32.63%;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    border-radius: 4px;
                    position: relative;
                    padding: 15px;
                    box-sizing: border-box;
                    display: flex;
                    cursor: pointer;
                    align-items: center;

                    .icon {
                        width: 64px;
                        height: 64px;
                        background-color: pink;
                        position: absolute;
                        top: 0px;
                        right: 0px;


                    }

                    .disable_btn {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 100;
                        opacity: 0.5;

                    }

                    &:nth-of-type(1) {
                        background: #fff2ea;

                        .icon {
                            background: url('../../../assets/img/work/icon1.png') no-repeat;
                        }

                        .disable_btn {
                            background: #fff2ea;

                        }

                    }

                    &:nth-of-type(2) {
                        margin-left: 1%;
                        margin-right: 1%;
                        background: #ecf6ff;

                        .icon {
                            background: url('../../../assets/img/work/icon2.png') no-repeat;
                        }

                        .disable_btn {
                            background: #ecf6ff;

                        }
                    }

                    &:nth-of-type(3) {
                        background: #f3f1ff;

                        .icon {
                            background: url('../../../assets/img/work/icon3.png') no-repeat;
                        }

                        .disable_btn {
                            background: #f3f1ff;

                        }
                    }

                    // &:nth-of-type(1):hover {
                    //     background-color: #ffe4e6;

                    //     .text_box {
                    //         color: #ff2c55;
                    //     }

                    // }

                    // &:nth-of-type(2):hover {
                    //     background: #d3eaff;


                    //     .text_box {
                    //         color: #1d92ff;
                    //     }

                    // }

                    // &:nth-of-type(3):hover {
                    //     background: #e3dfff;

                    //     .text_box {
                    //         color: #735cff;

                    //     }
                    // }

                    .icon_image {
                        width: 32px;
                        height: 32px;
                        font-size: 0;

                        img {
                            width: 100%;
                            height: 100%;
                            display: inline-block;
                        }
                    }

                    .text_box {
                        width: 85%;
                        margin-left: 10px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 500;
                        font-size: 14px;
                        color: #252632;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .tip {
                            width: 98%;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            font-size: 12px;
                            color: #888888;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: inline-block;

                        }

                    }
                }
            }
        }

        .quick_navigation {
            // min-width: 340px;
            width: 23%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 2%;
            padding: 16px 16px 0px;
            box-sizing: border-box;

            p {
                font-size: 18px;
            }

            .content_box {
                width: 100%;
                display: flex;
                padding-top: 15px;
                box-sizing: border-box;

                .item_box {
                    width: 23%;

                    // background-color: skyblue;
                    margin-left: 2%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    justify-content: space-between;

                    img {
                        width: 70%;
                        max-width: 68px;
                    

                    }

                    span {
                        width: 100%;
                        text-align: center;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 500;
                        height: 20px;
                        font-size: 14px;
                        color: #333333;
                        margin-top: 2px;
                        overflow: hidden;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                    }
                }

            }

        }

    }

    .data_center {
        width: 100%;
        height: 384px;
        margin-top: 20px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
    }

    .work-task {
        width: 100%;
        display: flex;

        .task {
            height: 434px;
            background: #ffffff;
            border-radius: 10px;
            margin: 24px 0;
            padding: 10px 20px 0;
            display: flex;
            width: 70%;
            position: relative;

            .view-more {
                position: absolute;
                text-align: center;
                font-size: 18px;
                color: #5E53F0;
                margin: 22px 0 18px 0;
                cursor: pointer;
                right: 20px;
                top: 0;
                z-index: 1;
            }
        }

        .task-right {
            width: 30%;
            height: 444px;
            background: #ffffff;
            border-radius: 10px;
            margin: 24px 0 24px 20px;
            display: flex;
            flex-direction: column;

            .show-right {
                display: flex;
                justify-content: space-between;
                padding: 20px;

                .show-name {
                    font-size: 18px;
                    color: #16113F;
                }

                .show-switch {
                    color: #16113F;
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;

                    .switch-icon {
                        transform: rotate(90deg);
                        margin-right: 6px;
                    }

                    .switch-text {
                        font-size: 14px;
                        color: #16113F;
                    }
                }
            }

            .task-right-box {
                padding: 0 15px;
                position: relative;
                flex: 1;

                .task-title {
                    max-width: 260px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .icon-background {
                    img {
                        width: 27px;
                        height: 27px;
                    }
                }

                .rank-title {
                    position: absolute;
                    left: 20%;
                    top: 25%;
                }
            }
        }

        .task-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            min-height: 360px;
            justify-content: flex-start;
            overflow: hidden;

            .list-item {
                width: calc(33% - 10px);
                height: 100%;
                margin-right: 16px;

                &:nth-of-type(3) {
                    margin-right: 0;
                }

                .container {
                    width: 100%;
                    border: 1px solid #E3E4EC;
                    transition: all .25s ease-out;
                    border-radius: 4px;
                    cursor: pointer;

                    .item-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 200px;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            border-radius: 4px;
                            /*border-radius:8px 8px 0 0;*/
                        }

                        .sign {
                            padding: 5px;
                            position: absolute;
                            border-radius: 0;
                            top: 9px;
                            left: 6px;
                            display: inline-block;
                            background: #FFD107;
                            border-radius: 2px 2px 2px 2px;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            font-size: 12px;
                            color: #161823;

                        }
                    }

                    .item-content {

                        padding: 10px 0 0 16px;

                        .item-title {
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 500;
                            font-size: 16px;
                            color: #252632;
                            margin-bottom: 10px;
                        }

                        .task_money {
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            font-size: 12px;
                            color: #FF6900;

                            span:nth-of-type(2) {
                                font-size: 20px;
                                font-weight: 600;

                            }

                        }

                        .join_num {
                            font-family: PingFang SC, PingFang SC;
                            font-weight: 400;
                            font-size: 12px;
                            color: #666666;
                            padding: 10px 0;
                        }



                    }

                    .item-status {

                        width: 100%;
                        height: 56px;
                        border-top: 1px solid #F2F2F4;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 12px;
                        color: #666666;
                        display: flex;
                        padding: 0 16px;
                        box-sizing: border-box;
                        align-items: center;
                        justify-content: space-between;

                        .task_btn {
                            width: 80px;
                            height: 32px;
                            // background: #EBEAFF;
                            border-radius: 4px 4px 4px 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        // .view-task {
                        //     color: #2338E6;
                        // }

                        // .finish-task {
                        //     color: #20C997;
                        // }

                        // .time-out-task {
                        //     color: #666666;
                        // }
                    }
                }
            }
        }

        .no-task-list {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    .all_data_center {
        width: 100%;
        display: flex;

        .data_left {

            height: 649px;
            background: #ffffff;
            border-radius: 10px;
            margin-bottom: 24px;
            padding: 10px 0;
            // display: flex;
            // width: 69%;
            flex: 1;
            width: 0;
            box-sizing: border-box;

            .title {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 18px;
                color: #252632;
                padding-top: 10px;
                padding-left: 20px;
                display: inline-block;
            }

            .data_content {
                width: 100%;
                display: flex;
                margin-top: 20px;
                height: 500px;

                .rank {
                    width: 36%;
                    // overflow-x: auto;

                    &:nth-of-type(1) {
                        width: 36%;
                        border-right: 1px solid #F2F2F4;
                        padding: 0 20px;
                        box-sizing: border-box;
                    }

                    &:nth-of-type(2) {
                        width: 32%;
                        border-right: 1px solid #F2F2F4;
                        padding: 0 20px;

                        box-sizing: border-box;
                    }

                    &:nth-of-type(3) {
                        width: 32%;
                        padding: 0 20px;
                        box-sizing: border-box;

                    }

                    .item {
                        width: 100%;

                        .header {
                            width: 100%;
                            border-bottom: 1px solid #F2F2F4;

                            .item_title {
                                font-family: PingFang SC, PingFang SC;
                                font-weight: 500;
                                font-size: 14px;
                                color: #242632;
                                padding-bottom: 10px;
                                border-bottom: 2px solid #594FEE;
                                display: inline-block;

                            }



                        }

                        .has_list {
                            .all_data {

                                width: 100%;
                                height: 70px;
                                margin: 10px 0;
                                display: flex;
                                font-size: 0;
                                cursor: pointer;

                                .image {
                                    width: 52px;
                                    height: 70px;
                                    border-radius: 4px;
                                    position: relative;
                                    font-size: 0;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 4px;
                                        object-fit: cover;
                                    }

                                    .icon_circle {
                                        width: 16px;
                                        height: 16px;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        background-color: #594FEE;
                                        color: #fff;
                                        font-weight: 500;
                                        font-size: 10px;
                                        border-top-left-radius: 4px;
                                        border-bottom-right-radius: 8px;
                                        text-align: center;
                                        line-height: 16px;
                                        border: 1px solid #fff;

                                    }

                                }

                                .video_info_box {
                                    width: 0;
                                    flex: 1;
                                    font-family: PingFang SC, PingFang SC;
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: #252632;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    margin-left: 10px;
                                    padding: 6px 0;
                                    box-sizing: border-box;

                                    .video_title {
                                        width: 100%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: inline-block;

                                    }

                                    .video_info {
                                        width: 100%;
                                        font-family: PingFang SC, PingFang SC;
                                        font-weight: 500;
                                        font-size: 12px;
                                        color: #252632;
                                        display: flex;

                                        .infor_item {
                                            width: 33.33%;
                                            display: inline-block;

                                            &:nth-of-type(1) {
                                                // width: 35%;
                                            }

                                            &:nth-of-type(2) {
                                                // width: 33%;
                                                margin: 0 10px;
                                            }

                                            &:nth-of-type(3) {
                                                // width: 32%;
                                            }

                                            .number {
                                                font-weight: bold;
                                            }

                                        }
                                    }
                                }


                            }

                            .bottom {
                                width: 100%;
                                height: 50px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: 400;
                                font-size: 12px;
                                color: #888888;
                                cursor: pointer;
                            }
                        }

                        .no_list {
                            width: 100%;
                            display: flex;
                            margin-top: 20px;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            font-weight: 500;
                            font-size: 14px;
                            color: rgba(28, 31, 35, 0.55);
                        }


                    }
                }
            }
        }

        .data_right {

            width: 29.5%;
            height: 649px;
            background: #ffffff;
            border-radius: 10px;
            margin: 0px 0 24px 20px;
            display: flex;
            flex-direction: column;

            .fans_header {
                width: 100%;
                padding: 20px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                font-weight: 400;
                font-size: 18px;
                color: #252632;
                align-items: center;

                .view_more {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 12px;
                    color: #888888;
                    cursor: pointer;
                }
            }

            .fans_list {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 0px 20px;
                box-sizing: border-box;
                margin-bottom: 10px;

                .image_box {
                    width: 60px;
                    height: 60px;
                    font-size: 0;
                    border-radius: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        object-fit: cover;
                    }
                }

                .fans_info {
                    width: 0;
                    flex: 1;
                    margin-left: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .fans_name {
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 14px;
                        color: #252632;
                        margin-bottom: 5px;
                    }

                    .fans_number {
                        display: flex;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(37, 38, 50, 0.6);
                        justify-content: space-between;

                        .fans {
                            width: 100px;
                            display: flex;
                            flex-direction: column;

                            span {
                                display: inline-block;
                            }


                        }
                    }
                }
            }

            .no_list {
                width: 100%;
                // height: 100%;
                display: flex;
                margin-top: 20px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 14px;
                color: rgba(28, 31, 35, 0.55);
            }

        }

    }

}
</style>
