import { render, staticRenderFns } from "./PostsAnalysis.vue?vue&type=template&id=46d579a4&scoped=true"
import script from "./PostsAnalysis.vue?vue&type=script&lang=js"
export * from "./PostsAnalysis.vue?vue&type=script&lang=js"
import style0 from "./PostsAnalysis.vue?vue&type=style&index=0&id=46d579a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d579a4",
  null
  
)

export default component.exports