<template>
    <div class="box_header" v-if="accountList.length > 0">
        <div class="header">
            <img v-if="!accountInfo.avatar" src="@/assets/img/image/blank_headPic.png" alt="">
            <img v-else :src="accountInfo.avatar" alt="">
        </div>
        <div class="account_info" style="margin-left: 10px;">
            <div class="info ">
                <div class="name_select">
                    <span class="name">{{ accountInfo.nickname }}</span>
                    <div class="switch_account">
                        <el-popover popper-class="popver_account" style="padding-right: 0px !important;" class="popver"
                            ref="popover" placement="bottom" width="260" trigger="click" @show="showPopover">
                            <div class="account_list" ref="accountListContainer">
                                <div class="account_item" v-for="(item, index) in accountList"
                                    @click="selectAcount(item, index)" :ref="setAccountItemRef" :key="item.id">
                                    <div class="avatar">
                                        <img v-if="!item.avatar" src="@/assets/img/image/blank_headPic.png" alt="">
                                        <img v-else :src="item.avatar" alt="">
                                    </div>
                                    <div class="info">
                                        <span class="name">{{ item.nickname }}</span>
                                        <div v-if="item.is_on_line === 0" style="display: flex;align-items: center;">
                                            <span style="display: flex;align-items: center;"><span
                                                    style="display: flex;align-items: center;"><span
                                                        style="background-color: #FE2C55;width: 4px;height: 4px;display: inline-block;border-radius: 100%;margin-right: 5px;"></span></span>登录失败</span>
                                            <el-button class="re_bind_douyin"><span
                                                    @click="reBindDouyin()">重新登陆</span></el-button>
                                                    <i class="el-icon-check" v-if="accountInfo.id==item.id" style="margin-left: 10px; color: #39B54A;"></i>
                                        </div>
                                        <div v-else>
                                            <span style="display: flex;align-items: center;"><span
                                                    style="background-color: #39B54A;width: 4px;height: 4px;display: inline-block;border-radius: 100%;margin-right: 5px;"></span>登录成功<i class="el-icon-check" v-if="accountInfo.id==item.id" style="margin-left: 10px; color: #39B54A;"></i></span>
                                                    
                                        </div>
                                       
                                    </div>

                                </div>
                            </div>
                            <div slot="reference" style="cursor: pointer;font-size: 18px;">切换账号<i
                                    class="el-icon-arrow-down"></i>
                            </div>
                        </el-popover>

                    </div>
                </div>
                <span class="number">手机号: <span>{{ accountInfo.mobile }}</span></span>
            </div>
        </div>

        <div class="account_info" style="margin-left: 80px;">
            <div class="item">
                <span>作品</span>
                <span class="number">{{ getNumber(accountInfo.aweme_count) }}</span>
            </div>
            <div class="item">
                <span>粉丝</span>
                <span class="number">{{ getNumber(accountInfo.follower_count) }}</span>
            </div>
            <div class="item">
                <span>获赞</span>
                <span class="number">{{ getNumber(accountInfo.favoriting_count) }}</span>
            </div>

        </div>

    </div>
    <div class="no_data" v-else>
        <div class="left">
            <img src="../../assets/img/image/image.png" alt="">
            <span style="margin-left: 20px;">暂无账号</span>
        </div>
        <el-button @click="reBindDouyin(1)">
            添加账号
        </el-button>


    </div>
</template>
<script>
export default {
    data() {
        return {
            accountInfo: {
                nickname: "",
                mobile: "",
                avatar: "",
                aweme_count: "",//总作品
                id: null,
                follower_count: null,//总粉丝数
                favoriting_count: null,//获赞数

            },
            accountList: [],
            selectAccountIndex: 0,

        }

    },
    created(){
        this.accountItemRefs = [];
    },
    mounted() {
        this.getAccountList()
        window.addEventListener('scroll', this.handleScroll, true)

    },
    methods: {
        getAccountList() {
            let params = {
                page: 1,
                pageSize: 10,
                paging: 0
            }
            this.$httpStudent.axiosGetBy(this.$api.get_account, params, res => {
                if (res.code == 200) {
                    this.accountList = res.data.data
                    if (localStorage.getItem("account") == null || localStorage.getItem("account") == undefined) {
                        if (res.data.data.length > 0) {
                            this.accountInfo = res.data.data[0]
                            localStorage.setItem("account", JSON.stringify(this.accountInfo))
                            localStorage.setItem("accountIndex", 0)
                        }
                    } else {
                        this.accountInfo = JSON.parse(localStorage.getItem("account"))
                        this.selectAccountIndex = localStorage.getItem("accountIndex")
                    }
                    this.$emit("selectAccountData", this.accountInfo)
                }
            })
        },
        selectAcount(item, index) {
            this.selectAccountIndex = index
            this.accountInfo = item
            this.$emit("selectAccountData", item)
            localStorage.setItem("account", JSON.stringify(item))
            localStorage.setItem("accountIndex", index)
            this.$refs.popover.doClose()



        },
        handleScroll() {
            if (this.$refs.popover) {

                this.$refs.popover.updatePopper();
            }


        },
        reBindDouyin() {
            // console.log('item', item);
            this.$httpStudent.axiosGetBy(this.$api.tikhoknew_authorization, {}, res => {
                if (res.code === 200) {
                    // console.log('url', res.data.url);
                    window.open(res.data.url, '抖音授权扫码', 'channelmode=0,directories=0,width=1000,height=500,top=200,left=200');
                    // this.dialogAddAccount = false;
                    // this.AddAccountManageModule = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message,
                        duration: 1000
                    });
                }
            },
                err => {
                    console.log(err);
                })
        },
        getNumber(num) {
            if (num >= 100000000) {
                return (num / 10000 / 10000).toFixed(2) + "亿"
            } else if (num >= 10000) {
                return (num / 10000).toFixed(2) + "万"
            } else {
                return num
            }

        },
        setAccountItemRef(el) {
            if (el) {
                this.accountItemRefs.push(el);
            }
        },
        scrollToTenthItem(index) {
        if (this.accountItemRefs.length > index) {
            const tenthItem = this.accountItemRefs[index];
                this.$refs.accountListContainer.scrollTo({
                    top: tenthItem.offsetTop,
                    behavior: "auto",
                });
            }
        },
        showPopover(){
            let index=this.accountList.findIndex((item)=>item.id==this.accountInfo.id)
            this.$nextTick(() => {
                this.scrollToTenthItem(index);
            });
        }
    },
    beforeDestroy() {
        // 删除 localStorage 中的项
        localStorage.removeItem("account");
        localStorage.removeItem("accountIndex");
    }
}
</script>
<style lang="scss" scoped>
p {
    margin: 0;
    padding: 0;
}

.box_header {
    width: 100%;
    height: 112px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    box-sizing: border-box;
    padding: 24px;
    box-sizing: border-box;

    .header {

        width: 64px;
        height: 64px;
        border-radius: 100%;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }

    }

    .info {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 22px;
        color: #000000;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
            max-width: 217px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }

        .number {
            color: #7A7E88;
            font-size: 20px;

        }



    }

    .switch_account {
        width: 100px;
        height: 100%;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #888888;
        text-align: left;
        font-style: normal;
        text-transform: none;
        flex-shrink: 0;
        margin-left: 20px;
        margin-top: 4px;

        i {
            margin-left: 5px;
        }



    }

    .account_info {
        display: flex;
        height: 100%;

        .name_select {
            display: flex;
            align-items: center;
        }


        .item {
            width: 160px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #4D4F56;

            span {
                display: inline-block;
            }

            .number {
                font-family: Douyin Sans, Douyin Sans;
                font-weight: bold;
                font-size: 24px;
                color: #171A1E;
                text-align: left;
                font-style: normal;
                text-transform: none;

            }
        }
    }

}

.no_data {
    width: 100%;
    height: 112px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    box-sizing: border-box;
    padding: 24px;
    box-sizing: border-box;
    justify-content: space-between;

    .left {
        height: 100%;
        display: flex;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 20px;
        color: #7A7E88;

        .img {
            width: 64px;
            height: 64px;
            object-fit: cover;

        }
    }

    .el-button {
        width: 88px;
        height: 32px;
        background: #594FEE;
        border-radius: 4px 4px 4px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
}

.re_bind_douyin {
    width: 60px;
    height: 22px;
    background: #F2F2F4;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #594FEE;
    border: none;
    margin-left: 10px;

}

.account_list {
    width: 100%;
    max-height: 280px;
    overflow-y: auto;
    overflow-x: hidden;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
    -ms-overflow-style: thin;
    // scrollbar-color: #e1dfdf #f1f1f1;
    /* 滚动条滑块和轨道颜色 */

    .account_item {

        width: 260px;
        height: 69px;
        padding: 12px 0px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;


        .avatar {
            width: 45px;
            height: 45px;
            border-radius: 100%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
            }
        }

        .info {
            width: 178px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 12px;
            box-sizing: border-box;

            .name {
                width: 178px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #000000;

            }

            .number {
                color: #7A7E88;
                font-weight: 400;
                font-size: 16px;
                margin-top: 4px;

            }
        }

        .icon_box {
            width: 20px;
            height: 20px;
            margin-left: 20px;

        }
    }

}

.el-popover {
    padding-right: 0px !important;
}

/* 设置滚动条整体 */
::-webkit-scrollbar {
    width: 4px;
    /* 滚动条宽度 */
    height: 6px;
    /* 水平滚动条高度 */
}

/* 设置滚动条轨道 */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* 轨道背景色 */
}

/* 设置滚动条滑块 */
::-webkit-scrollbar-thumb {
    background: #e1dfdf;
    /* 滑块背景色 */
    border-radius: 4px;
    /* 滑块圆角 */
}

/* 设置滚动条滑块悬停时的样式 */
::-webkit-scrollbar-thumb:hover {
    background: #c9c9c9;
    /* 滑块悬停背景色 */
}
</style>
<style>
.popver_account {
    padding-right: 2px !important;
}
</style>
