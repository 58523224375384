<template>
  <div class="article-preview">
    <div class="article-preview_header">
      <div class="header-title">{{ articleTitle }}</div>

      <el-button @click="handleBack">返回</el-button>
    </div>

    <div class="article-preview_content">
      <div class="article-html" v-html="articleContent"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticlePreview",
  data() {
    return {
      articleTitle: "",
      articleContent: "",
    };
  },
  created() {
    this.getArticleInfo();
  },
  methods: {
    // 获取文章内容详情
    getArticleInfo() {
      let articleInfo = JSON.parse(sessionStorage.getItem("articleInfo"));
      if (articleInfo) {
        this.articleTitle = articleInfo.title;
        this.articleContent = articleInfo.content;
      } else {
        sessionStorage.removeItem("articleInfo");
      }
    },

    // 返回
    handleBack() {
      this.$router.back();
    },
  },

  destroyed() {
    // sessionStorage.removeItem("articleInfo");
  },
};
</script>
<style scoped lang="scss">
.article-preview {
  min-height: calc(100vh - 100px);
  background: #ffffff;
  display: flex;
  flex-direction: column;

  &_header {
    border-bottom: 1px solid #f0f0f0;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title {
      font-size: 16px;
      color: #333333;
      font-weight: 600;
    }

    .el-button {
      background-color: #2a3fe8;
      width: 100px;
      height: 32px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &_content {
    padding: 16px 24px;
    color: #666666;
    font-size: 14px;
  }
}
</style>
