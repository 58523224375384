<template>
    <div class="data">
        <div class="data-header">
            <span class="data-title">数据概况</span>
            <div class="data-content">
                <div class="data-item" v-for="item in severalList">
                    <span class="text">{{item.total}}</span>
                    <span class="g-num">{{item.num}}</span>
                    <div class="added-this-week">
                        <span class="text">本周新增</span>
                        <i class="iconfont">&#xe643;</i>
                        <span class="num">{{item.addNum}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-trend">
            <span class="title">总粉丝</span>
            <div class="content">
                <div class="data-menu">
                    <div class="item" :class="{selected: fanTabIndex === 0}" @click="toggleFanTab(0)">近7天</div>
                    <div class="item" :class="{selected: fanTabIndex === 1}" @click="toggleFanTab(1)">30天</div>
                </div>
                <div id="fanTrend" style="width:100%; height:442px;position: relative"></div>
            </div>
        </div>
        <div class="data-trend">
            <span class="title">点赞趋势</span>
            <div class="content">
                <div class="data-menu">
                    <div class="item" :class="{selected: likeTrendIndex === 0}" @click="likeTrendFunTab(0)">近7天</div>
                    <div class="item" :class="{selected: likeTrendIndex === 1}" @click="likeTrendFunTab(1)">30天</div>
                </div>
                <div id="likeTrend" style="width:100%; height:442px;position: relative"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DataOverviewModule",
        data(){
            return{
                //数据概况
                mineInfo: {},
                severalList: [{
                    total: '粉丝总量',
                    num:0,
                    addNum:0
                }, {
                    total: '评论总量',
                    num:0,
                    addNum:0
                }, {
                    total: '点赞总量',
                    num:0,
                    addNum:0,
                }, {
                    total: '转发总量',
                    num:0,
                    addNum:0,
                }],
                //粉丝趋势近7天/30天切换
                fanTabIndex:0,
                //点赞趋势近7天/30天切换
                likeTrendIndex:0,
                //抖音账户一周内粉丝趋势数据
                weekFansTrend:{},
                //抖音账户一周内点赞趋势数据
                weekYeahTrend:{},
                //抖音账户一月内粉丝趋势数据
                monthFansTrend:{},
                //抖音账户一周内点赞趋势数据
                monthYeahTrend:{},
                //粉丝趋势没有数据提示
                FansTrendNoPrompt:null,
                //点赞趋势没有数据提示
                LikeTrendNoPrompt:null,

            }
        },
        mounted() {
            // this.fanTrend = this.$echarts.init(document.getElementById("fanTrend"));
            // this.likeTrend = this.$echarts.init(document.getElementById("likeTrend"));
            this.getData();
            // 粉丝趋势没有数据提示
            this.FansTrendNoPrompt = document.createElement("div")
            // 点赞趋势没有数据提示
            this.LikeTrendNoPrompt = document.createElement("div")

            window.onresize = () => {
                if (this.fanTabIndex == 0) {
                    this.fanTrendFun(this.weekFansTrend); //粉丝趋势
                } else {
                    this.fanTrendFun(this.monthFansTrend); //粉丝趋势
                }
                if (this.likeTrendIndex == 0) {
                    this.likeTrendFun(this.weekYeahTrend); //点赞趋势
                } else {
                    this.likeTrendFun(this.monthYeahTrend); //点赞趋势
                }
            };
            this.fanTrendFun(this.weekFansTrend);
            this.likeTrendFun(this.weekYeahTrend);
        },
        methods: {
            // 获取数据概况
            getData() {
                let param = {
                    platform_type: 1,
                    type:1,
                    id: this.$route.query.id
                };
                this.$httpteacher.axiosGetBy(this.$api.get_account, param, res => {
                    if (res.code == 200) {
                        //点赞总数//  粉丝总量
                        if (res.data.favoriting_count != '' && res.data.favoriting_count != undefined
                            && res.data.favoriting_count != 'undefined'){
                            // this.severalList[0].num = res.data.favoriting_count;
                            this.severalList[0].num = res.data.follower_count;
                        }
                        //评论总数
                        if (res.data.comment_count != '' && res.data.comment_count != undefined
                            && res.data.comment_count != 'undefined'){
                            this.severalList[1].num = res.data.comment_count;
                        }
                        //粉丝总数//  点赞总量
                        if (res.data.follower_count != '' && res.data.follower_count != undefined
                            && res.data.follower_count != 'undefined'){
                            // this.severalList[2].num = res.data.follower_count;
                            this.severalList[2].num = res.data.favoriting_count;
                        }
                        //一周粉丝变化量
                        if (res.data.new_follower_count != '' && res.data.new_follower_count != undefined
                            && res.data.new_follower_count != 'undefined'){
                            this.severalList[2].addNum = res.data.new_follower_count;
                        }

                        //转发总数
                        if (res.data.forward_count != '' && res.data.forward_count != undefined
                            && res.data.forward_count != 'undefined'){
                            this.severalList[3].num = res.data.forward_count;
                        }
                        //一周转发变化量
                        if (res.data.new_share_count != '' && res.data.new_share_count != undefined
                            && res.data.new_share_count != 'undefined'){
                            this.severalList[3].addNum = res.data.new_share_count;
                        }
                        // 抖音账户一个月内粉丝趋势数据
                        if (res.data.follower_data && res.data.follower_data.length>0){
                            if (typeof(res.data.follower_data) == 'string'){
                                this.monthFansTrend = JSON.parse(
                                    res.data.follower_data
                                );
                            }else{
                                this.monthFansTrend = res.data.follower_data
                            }
                            this.fanTrendFun(this.monthFansTrend);
                        }
                        //抖音账户一周内粉丝趋势数据
                        this.weekFansTrend = this.monthFansTrend.slice(-7)
                        this.fanTrendFun(this.weekFansTrend);
                        // 抖音账户一个月内点赞趋势数据
                        if (res.data.like_data && res.data.like_data.length>0){
                            if (typeof(res.data.like_data) == 'string'){
                                this.monthYeahTrend = JSON.parse(
                                    res.data.like_data
                                );
                            }else{
                                this.monthYeahTrend = res.data.like_data
                            }
                            this.likeTrendFun(this.monthYeahTrend);
                        }
                        //抖音账户一周内点赞趋势数据
                        this.weekYeahTrend = this.monthYeahTrend.slice(-7)
                        this.likeTrendFun(this.weekYeahTrend);
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            //粉丝趋势近7天/30天切换
            toggleFanTab(index) {
                this.fanTabIndex = index;
                if (index === 0) {
                    this.fanTrendFun(this.weekFansTrend); //粉丝趋势
                } else {
                    this.fanTrendFun(this.monthFansTrend); //粉丝趋势
                }
            },
            //点赞趋势近7天/30天切换
            likeTrendFunTab(index) {
                this.likeTrendIndex = index;
                if (index === 0) {
                    this.likeTrendFun(this.weekYeahTrend); //点赞趋势
                } else {
                    this.likeTrendFun(this.monthYeahTrend); //点赞趋势
                }
            },
            // 点赞趋势
            likeTrendFun(yeahTrendArr) {
                this.LikeTrendNoPrompt.innerHTML='';
                // 时间和数据
                let time = [],
                    data = [];
                if (yeahTrendArr.length>0){
                    for (let i in yeahTrendArr) {
                        // 保存时间和数据
                        time.push(yeahTrendArr[i].date.substring(5, yeahTrendArr[i].date.length));
                        data.push(yeahTrendArr[i].new_like);
                    }
                }
                let max = Math.max(...data)
                let min = Math.min(...data)
                let likeTrend = this.$echarts.init(document.getElementById("likeTrend"));
                likeTrend.setOption({
                    color: ["#495DF1"],
                    tooltip: {
                        trigger: "axis",
                        padding: 0,
                        axisPointer: {
                            type: 'line',
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                color: '#e0e2eb'
                            }
                        },
                        formatter: function (params) {
                            let name = params[0].name;
                            let date = params[0].data;
                            let str = '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + '点赞总数：' + '</span> <sapn class="tooltip-title-right blue"> ' + date + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                            return str;
                        }
                    },
                    xAxis: [
                        {
                            // 隐藏刻度线
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#999',
                                },
                            },
                            axisLabel: {
                                textStyle: {
                                    color: '#999',
                                },
                                // rotate: 40,
                            },
                            // type: 'category',
                            data: time
                        }
                    ],
                    yAxis: [
                        {
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#999'
                                }
                            },
                            type: "value",
                            name: "",
                            min: min,
                            max: max,
                            // interval: 50,
                            axisLabel: {
                                formatter: "{value}"
                            },
                        }
                    ],
                    series: [
                        {
                            name: "点赞总数",
                            data: data,
                            type: "line",
                            lineStyle: {
                                color: "#04A8FF",
                                width: 3
                            },
                            // areaStyle: {
                            //     color: "#d8f6ec"
                            // },
                            smooth: true,
                            symbol: 'circle',
                            showSymbol: false, //关闭线上默认的圆点
                            symbolSize: 5, //设置圆点的大小
                            itemStyle: {
                                emphasis: { // 鼠标经过时：
                                    color: '#04A8FF',
                                    borderColor: '#ffffff',
                                    borderWidth: 4,
                                    shadowColor: 'rgba(133,127,221,1)',
                                },
                                normal: {
                                    shadowBlur: 30,
                                }
                            },
                        }
                    ]
                },true);
                if(data.length === 0){
                    this.LikeTrendNoPrompt.innerHTML = "暂无数据";
                    this.LikeTrendNoPrompt.style.color = "#666";
                    this.LikeTrendNoPrompt.style.position = "absolute";
                    this.LikeTrendNoPrompt.style.top = "100px";
                    this.LikeTrendNoPrompt.style.left = "50%";
                    document.querySelector("#likeTrend").appendChild(this.LikeTrendNoPrompt)
                }
            },
            // 粉丝趋势
            fanTrendFun(fansTrendArray) {
                if (document.getElementById("fanTrend")) {
                    this.FansTrendNoPrompt.innerHTML = '';
                    // 时间和数据
                    let time = [],
                        data = [];
                    if (fansTrendArray.length > 0) {
                        for (let i in fansTrendArray) {
                            // 保存时间和数据
                            time.push(fansTrendArray[i].date.substring(5, fansTrendArray[i].date.length));
                            data.push(fansTrendArray[i].total_fans);
                        }
                    }
                    let max = Math.max(...data)
                    let min = Math.min(...data)
                    let fanTrend = this.$echarts.init(document.getElementById("fanTrend"));
                    fanTrend.setOption({
                        tooltip: {
                            trigger: "axis",
                            padding: 0,
                            axisPointer: {
                                type: 'line',
                                lineStyle: {
                                    type: 'solid',
                                    width: 1,
                                    color: '#e0e2eb'
                                }
                            },
                            formatter: function (params) {
                                let name = params[0].name;
                                let date = params[0].data;
                                let str = '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + '点赞总数：' + '</span> <sapn class="tooltip-title-right purple"> ' + date + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                                return str;
                            }
                        },
                        color:'#584EEE',
                        title: {
                            x: "center",
                            y: "bottom",
                            textStyle: {
                                //设置主标题风格
                                Color: "#333", //设置主标题字体颜色
                                fontSize: 14,
                                fontStyle: "normal"
                            }
                        },
                        xAxis: {
                            type: "",
                            data: time,
                            //设置网格线颜色
                            // boundaryGap: false,
                            // 隐藏刻度线
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                textStyle: {
                                    color: '#999',
                                },
                                // rotate: 40,
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#999"
                                }
                            }
                        },
                        yAxis: {
                            type: "value",
                            min: min,
                            max: max,
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: ["#f1f1f1"]
                                }
                            },
                            //隐藏刻度线
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: "#999"
                                }
                            },
                            axisLabel: {
                                fontSize: "14",
                                marginTop: "35px",
                                formatter:function (value,index){
                                    if(value >= 10000){
                                        return `${value / 10000}W`
                                    } else {
                                        return value
                                    }
                                },
                                show: true
                            }
                        },
                        series: [
                            {
                                name: "粉丝总数",
                                data: data,
                                type: "line",
                                lineStyle: {
                                    color: "#584EEE",
                                    width: 3
                                },
                                // areaStyle: {
                                //     color: "#d8f6ec"
                                // },
                                smooth: true,
                                symbol: 'circle',
                                showSymbol: false, //关闭线上默认的圆点
                                symbolSize: 5, //设置圆点的大小
                                itemStyle: {
                                    emphasis: { // 鼠标经过时：
                                        color: '#584EEE',
                                        borderColor: '#ffffff',
                                        borderWidth: 4,
                                        shadowColor: 'rgba(133,127,221,1)',
                                    },
                                    normal: {
                                        shadowBlur: 30,
                                    }
                                },
                            }
                        ]
                    }, true);
                    if (data.length === 0) {
                        this.FansTrendNoPrompt.innerHTML = "暂无数据";
                        this.FansTrendNoPrompt.style.color = "#666";
                        this.FansTrendNoPrompt.style.position = "absolute";
                        this.FansTrendNoPrompt.style.top = "100px";
                        this.FansTrendNoPrompt.style.left = "50%";
                        document.querySelector("#fanTrend").appendChild(this.FansTrendNoPrompt)
                    }
                }
            },
        }

    }
</script>

<style scoped lang="scss">
    .data{
        padding: 0 25px;
        height: 100%;
        /*overflow: auto;*/
        .data-header{
            .data-title{
                font-size: 14px;
                font-weight: 400;
            }
            .data-content{
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                line-height: 35px;
                .data-item{
                    display: flex;
                    flex-direction: column;
                    .g-num{
                        font-size: 24px;
                        font-weight: bold;
                        color: #5F55F1;
                    }
                    .added-this-week{
                        & i{
                            font-size: 14px;
                            color: #FFB624;
                            margin: 0 14px;
                        }
                        & .num{
                            font-size: 14px;
                            font-weight: bold;
                            color: #FFB624;
                        }

                    }
                }
                .text{
                    color:#666666;
                    font-size: 14px;
                }

            }
        }

        .data-trend{
            margin-top: 20px;

            ::v-deep.tooltip {
                height: 59px;
                border-radius: 2px;
                box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
                background: #fff;

                .tooltip-box {
                    padding: 13px 20px 10px;
                }

                .tooltip-title {
                    font-size: 12px;
                    color: #999999;
                }

                .tooltip-title-right {
                    float: right;
                    padding-right: 10px;
                    font-size: 16px;

                    &.purple {
                        color: #5F55F1;
                    }

                    &.blue {
                        color: #04A8FF;
                    }
                }

                .tooltip-title-left {
                    color: #333;
                }
            }
        }

        .data-menu{
            display: flex;
            justify-content: flex-end;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            .item {
                line-height: 1;
                display: inline-block;
                padding: 10px 12px;
                cursor: pointer;
                background: #F1F0FE;
                border-radius: 5px 0 0 5px;
                &:hover{
                    background: #564CED;
                    color: #FFFFFF;
                }
                &:last-of-type {
                    border-radius: 0 5px 5px 0;
                }
            }

            .selected{
                background: #564CED;
                color: #FFFFFF;
            }
        }
    }

</style>