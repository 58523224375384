<script>
import {mediaDataList} from "@/utils/apis"
import {win} from "../../../../public/tinymce";
export default {
    name: "MediaData",
    data() {
        return {
            list: [],
            typeTags: [
                "热播榜",
                "热播榜",
                "头条热榜",
                "热点榜",
                "实时热榜",
                "点击量排行榜",
                "热搜榜",
                "今日排行榜",
                "排行榜总榜",
                "热榜",
                "实时热点",
                "搜狐热榜",
                "360趋势",
                "每周必看",
                "热搜榜",
                "24小时热榜",
                "热榜",
                "今日热帖",
                "24h热文榜",
                "最受欢迎的书评",
            ],
        }
    },
    mounted() {
        this.mediaList()
    },
    methods: {
        openUrl(item) {
            window.open(item.url)
        },
        mediaList: async function() {
            try {
                const {code, data, msg} = await mediaDataList()
                if (code === 200) {
                    this.list = data;
                }
            } catch (e) {
                console.log("error",e)

            }
        }
    }
}
</script>

<template>
    <div class="main">
        <div class="news-item" v-for="(item,index) in list" :key="index">
            <div class="news-header">
                <div class="header-ico">
                    <div class="img">
                        <img :src="require(`../../../assets/img/logo/${index}.png`)" alt="">
                    </div>
                    <div class="title">{{ item.name }}</div>
                </div>
                <div class="header-tag">{{typeTags[index]}}</div>
            </div>
            <div class="news-list">
                <a style="color:#122E67;" :href="value.url" v-for="(value, key) in item.list" target="_blank" :key="key">
                    <div class="item" >
                        <div :class="`index index_${key}`">{{key + 1}}</div>
                        <div class="title">{{value.title}}</div>
                        <div class="number">{{value.value.indexOf('w') !== -1 ? value.value.replace('w', '万') : value.value}}</div>
                    </div>
                </a>
            </div>
        </div>

    </div>
</template>

<style scoped lang="scss">
.main {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(4, 344px);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    .news-item {
        background: #FFF;
        border-radius: 8px;
        padding: 0 20px;
        .news-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 47px;
            width: 100%;
            border-bottom: 1px solid #F2F2F4;
            .header-ico {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .img {
                    margin-right: 6px;
                    width: 24px;
                    height: 24px;
                    border-radius: 2px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;

                    }
                }
                .title {
                    color: #242632;
                }
            }
            .header-tag {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 2px solid #685EF5;
                color: #666666;
            }
        }
        .news-list {
            .item {
                cursor: pointer;
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .index {
                    margin-right: 10px;
                    color: #929399;
                    &.index_0 {
                        color: #FF2C55;
                    }
                    &.index_1 {
                        color: #FF851D;
                    }
                    &.index_2 {
                        color: #FF748F;
                    }
                }
                .title {
                    flex: 1;
                    width: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .number {
                    color: #999999;
                    //width: 70px;
                }
            }
        }
    }
}

</style>
