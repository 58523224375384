<template>
    <div class="account-content">
        <!--        <div class="del-wrapper">-->
        <!--            <div class="del-btn" @click="deletePro">-->
        <!--                <i class="el-icon-delete"></i>删除-->
        <!--            </div>-->
        <!-- <span class="del-number">已选{{chooseNumber}}个</span> -->
        <!--        </div>-->
        <el-table :border="true" height="100%"  :data="shortTable" style="width: 100%;height: 1%; flex: 1"  size="medium"
            :header-cell-style="{ fontWeight: 'normal', color: '#333', background: '#ECEBFF', height: '50px' }"
            :cell-style="{ fontSize: '12px', color: '#333', }" @selection-change="handleSelectionChange" @sort-change="handleSortChange">
            <el-table-column type="selection" width="55" align="center" ></el-table-column>
            <el-table-column label="序号" align="left" width="60" >
                <template slot-scope="scope">
                    {{ scope.$index+(studentPages.eachPageNum*(studentPages.currentPageNum-1)) + 1 }}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="platform_type" label="平台" align="left"  >
                <template slot-scope="scope">
                    <div class="account-number">
                        <img
                            :src="scope.row.platform_type === 1 ? require('../../assets/img/image/douyin.png') : require('../../assets/img/image/baijiahao.png')">
                        <span class="scope-title">{{ scope.row.platform_type === 1 ? '抖音' : '百家号' }}</span>
                    </div>
                </template>
            </el-table-column> -->
            <el-table-column prop="name" label="账号"  resizable>
                <template slot-scope="scope">
                    <div class="account-number" v-if="scope.row.platform_type === 1" @click="gotoData(scope.row)">
                        <img :src="scope.row.avatar" width="30" height="30">
                        <span class="scope-title">{{ scope.row.nickname }}</span>
                    </div>
                    <div class="account-number" v-else @click="gotoData(scope.row)">
                        <img :src="scope.row.avatar" width="30" height="30">
                        <span class="scope-title">{{ scope.row.nickname }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="unique_id" label="抖音号" align="center" width="120px"  >
                <template slot-scope="scope">
                    <div class="box_btn" >
                        <el-tooltip placement="top">
                                <div slot="content">{{ scope.row.unique_id  }}</div>
                                <span class="text" v-if="scope.row.unique_id">{{ scope.row.unique_id }}</span>
                        </el-tooltip>
                        <i class="el-icon-edit editBtn"  @click="editRow(scope.row,1)"></i>
                    </div>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="总粉丝数" align="center"  >
                <template slot-scope="scope">
                    <!-- {{ scope.row }} -->
                    <div> {{ getNumber(scope.row.follower_count) }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="state" label="男女比例" align="center"  >
                <template slot-scope="scope">
                    <div v-if="scope.row.fans_data && scope.row.fans_data.length != 0"
                        style="display: flex;flex-direction: column;">
                        <!-- <span>{{ scope.row.fans_data[0]?.item == 1 ? "男" : scope.row.fans_data[0]?.item == 2 ? '女' : '' }}
                            <span>{{ scope.row.fans_data[0]?.value }}</span>
                        </span>
                        <span>{{ scope.row.fans_data[1]?.item == 1 ? "男" : scope.row.fans_data[1]?.item == 2 ? '女' : ''
                            }} <span>{{ scope.row.fans_data[1]?.value }}</span></span> -->
                        <span>男 {{ getValue(scope.row, 1) }}</span>
                        <span>女 {{ getValue(scope.row, 2) }}</span>
                    </div>
                    <div v-else>
                        -
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="state" label="总作品数" align="center"  >
                <template slot-scope="scope">
                    {{ getNumber(scope.row.aweme_count) }}
                </template>
            </el-table-column>
            <el-table-column prop="label" label="标签"  align="center" sortable="custom"  >
                <template #header>
                    <div style="display: flex;flex-direction: column;align-items: center;">
                        <div style="display: flex;align-items: center;margin-top: 12px;">
                            标签
                            <div style="color: rgb(192, 196, 204);display: flex;align-items: center;justify-content: center;flex-direction: column;">
                                <span class="caret-wrapper-c"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                            </div>
                        </div>
                        <div style="display: flex;flex-direction: column;justify-self: flex-start" >
                            <el-popover
                                placement="bottom"
                                width="300"
                                trigger="click" @show="showSelect()" @hide="selectList()">
                                <div style="width: 100%;max-height: 320px;overflow-y: auto;">
                                    <el-checkbox-group v-model="labelCheckList">
                                        <el-checkbox style="display: flex;align-items: center;" v-for="item in selectLabelList"  :key="item" :label="item"><span style="width: 260px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: inline-block;">{{ item }}</span></el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <span  slot="reference" @click.stop="showSelect()" style="display: flex;justify-content: start;"><i style="color: rgb(64, 158, 255);background-color: #fff;" class="el-icon-caret-bottom"></i></span>
                            </el-popover>
                        </div>
                    </div>
                </template>
                <template slot-scope="scope">
                    <div class="box_btn" >
                        <el-tooltip placement="top">
                                <div slot="content" style="width: 200px;">{{ scope.row.label  }}</div>
                                <span class="text" v-if="scope.row.label">{{ scope.row.label }}</span>
                        </el-tooltip>
                        <i class="el-icon-edit editBtn" @click="editRow(scope.row,2)"></i>
                    </div>
              </template>
            </el-table-column>
            <el-table-column prop="operator" label="运营人" align="center" sortable="custom"  >
                <template #header>
                    <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                        <div style="display: flex;align-items: center;margin-top: 12px;">
                            运营人
                            <div style="color: rgb(192, 196, 204);display: flex;align-items: center;justify-content: center;flex-direction: column;">
                                <span class="caret-wrapper-c"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                            </div>
                        </div>
                        <div style="display: flex;flex-direction: column;justify-self: flex-start" >
                            <el-popover
                                placement="bottom"
                                width="300"
                                trigger="click" @show="showSelect()" @hide="selectList()">
                                <div style="width: 100%;max-height: 320px;overflow-y: auto;">
                                    <el-checkbox-group v-model="operationChexkList">
                                        <el-checkbox style="display: flex;align-items: center;" v-for="item in selectOperationList"  :key="item" :label="item"><span style="width: 260px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: inline-block;">{{ item }}</span></el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <span  slot="reference" @click.stop="showSelect()" style="display: flex;justify-content: start;"><i style="color: rgb(64, 158, 255);background-color: #fff;" class="el-icon-caret-bottom"></i></span>
                            </el-popover>
                        </div>
                    </div>
                </template>
                <template slot-scope="scope">
                    <div class="box_btn" >
                        <el-tooltip placement="top">
                                <div slot="content" style="width: 200px;">{{ scope.row.operator  }}</div>
                                <span class="text" v-if="scope.row.operator">{{ scope.row.operator }}</span>
                        </el-tooltip>
                        <i class="el-icon-edit editBtn" @click="editRow(scope.row,3)"></i>
                    </div>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center"  sortable="custom">
                <template #header>
                    <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                        <div style="display: flex;align-items: center;margin-top: 12px;">
                            备注
                            <div style="color: rgb(192, 196, 204);display: flex;align-items: center;justify-content: center;flex-direction: column;">
                                <span class="caret-wrapper-c"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                            </div>
                        </div>
                        <div style="display: flex;flex-direction: column;justify-self: flex-start" >
                            <el-popover
                                placement="bottom"
                                width="300"
                                trigger="click" @show="showSelect()" @hide="selectList()">
                                <div style="width: 100%;max-height: 320px;overflow-y: auto;">
                                    <el-checkbox-group v-model="remarkCheckList">
                                        <el-checkbox style="display: flex;align-items: center;" v-for="item in selectRemarkList"  :key="item" :label="item"><span style="width: 260px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: inline-block;">{{ item }}</span></el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <span  slot="reference" @click.stop="showSelect()" style="display: flex;justify-content: start;"><i style="color: rgb(64, 158, 255);background-color: #fff;" class="el-icon-caret-bottom"></i></span>
                            </el-popover>
                        </div>
                    </div>
                </template>
              <template slot-scope="scope">
                <div class="box_btn" >
                        <el-tooltip placement="top">
                                <div slot="content" style="max-width: 180px;">{{ scope.row.remark  }}</div>
                                <span class="text" v-if="scope.row.remark">{{ scope.row.remark }}</span>
                        </el-tooltip>
                        <i class="el-icon-edit editBtn" @click="editRow(scope.row,4)"></i>
                    </div>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注2" align="center"  sortable="custom">
                <template #header>
                    <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                        <div style="display: flex;align-items: center;margin-top: 12px;">
                            备注2
                            <div style="color: rgb(192, 196, 204);display: flex;align-items: center;justify-content: center;flex-direction: column;">
                                <span class="caret-wrapper-c"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                            </div>
                        </div>
                        <div style="display: flex;flex-direction: column;justify-self: flex-start" >
                            <el-popover
                                placement="bottom"
                                width="300"
                                trigger="click" @show="showSelect()" @hide="selectList()">
                                <div style="width: 100%;max-height: 320px;overflow-y: auto;">
                                    <el-checkbox-group v-model="account_remarkCheckList">
                                        <el-checkbox style="display: flex;align-items: center;" v-for="item in account_selectRemarkList"  :key="item" :label="item"><span style="width: 260px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: inline-block;">{{ item }}</span></el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <span  slot="reference" @click.stop="showSelect()" style="display: flex;justify-content: start;"><i style="color: rgb(64, 158, 255);background-color: #fff;" class="el-icon-caret-bottom"></i></span>
                            </el-popover>
                        </div>
                    </div>
                </template>
              <template slot-scope="scope">
                <div class="box_btn" >
                        <el-tooltip placement="top">
                                <div slot="content" style="max-width: 180px;">{{ scope.row.account_remark  }}</div>
                                <span class="text" v-if="scope.row.account_remark">{{ scope.row.account_remark }}</span>
                        </el-tooltip>
                        <i class="el-icon-edit editBtn" @click="editRow(scope.row,5)"></i>
                    </div>
              </template>
            </el-table-column>
            <el-table-column prop="is_on_line" label="账号状态" width="180" sortable="custom"  >
                <template #header>
                <div>
                    <div style="display: flex;align-items: center;">
                        账号状态
                        <div style="color: rgb(192, 196, 204);display: flex;align-items: center;justify-content: center;flex-direction: column;">
                            <span class="caret-wrapper-c"><i class="sort-caret ascending"></i><i class="sort-caret descending"></i></span>
                        </div>
                    </div>
                </div>
                </template>
                <template slot-scope="scope">
                    <div v-if="scope.row.is_on_line === 0" style="display: flex;align-items: center;">
                        <span style="display: flex;align-items: center;"><span
                                style="font-size: 26px;color: #FE2C55 ;">·</span>登录失败</span>
                        <el-button class="re_bind_douyin"><span @click="reBindDouyin(scope.row)">重新登陆</span></el-button>
                    </div>
                    <div v-else>
                        <span style="display: flex;align-items: center;"><span
                                style="font-size: 26px;color: #39B54A ;">·</span>登录成功</span>
                    </div>
                    <!-- <div v-if="scope.row.platform_type===1">
                        <span :class="scope.row.audit_status==0?'':scope.row.audit_status==1?'under-passed':scope.row.audit_status==2?'under-err':'under-err'">
                            {{scope.row.audit_status==0?'审核中':scope.row.audit_status==1?'审核通过':scope.row.audit_status==2?'审核不通过':'驳回审核'}}
                        </span>
                    </div>
                    <div class="under-passed" v-else>
                        <span>审核通过</span>
                    </div> -->
                </template>
            </el-table-column>
            <el-table-column label="操作" width="120"  >
                <template slot-scope="scope">
                    <div class="operation">
                        <el-button @click="getTop(scope.row.id)" type="text" class="topping">
                            <span v-show="scope.row.top === 0">置顶</span>
                        </el-button>
                        <el-button @click="unPinkTop(scope.row.id)" type="text" class="topping">
                            <span v-show="scope.row.top === 1">取消置顶</span>
                        </el-button>

                        <el-button @click="deleteRow(scope.row)" type="text" class="del">
                            <span>删除</span>
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="padding-bottom: 10px;" :current-page="studentPages.currentPageNum"
            :page-size="studentPages.eachPageNum" layout="prev, pager, next, jumper,sizes"  :page-sizes="[10, 20, 50, 100]" :total="studentPages.total"
            v-show="shortTable.length > 0" @current-change="studentCurrentChange" @size-change="studentSizeChange">
        </el-pagination>
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="550px" @close="cancalEidtRow()">
            <el-form ref="ruleForm" :model="accountInfo" label-position="right" label-width="80px" :rules="accountInfoRules">
                <el-form-item label="抖音号" v-if="accountInfo.type==1" prop="unique_id">
                    <el-input v-model="accountInfo.unique_id" autocomplete="off" placeholder="请输入抖音号"></el-input>
                </el-form-item>
                <el-form-item label="标签" v-if="accountInfo.type==2"  prop="label">
                    <el-input v-model="accountInfo.label" autocomplete="off" placeholder="请输入标签"></el-input>
                </el-form-item>
                <el-form-item label="运营人" v-if="accountInfo.type==3"  prop="operator">
                    <el-input v-model="accountInfo.operator" autocomplete="off" placeholder="请输入运营人"></el-input>
                </el-form-item>
                <el-form-item label="备注" v-if="accountInfo.type==4"  prop="remark">
                    <el-input v-model="accountInfo.remark" autocomplete="off" placeholder="请输入备注"></el-input>
                </el-form-item>
                <el-form-item label="备注" v-if="accountInfo.type==5"  prop="account_remark">
                    <el-input v-model="accountInfo.account_remark" autocomplete="off" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancalEidtRow()">取 消</el-button>
                <el-button type="primary" @click="confirmRow()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "ShortVideoModule",
    props: ['isFresh'],
    data() {
        var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入抖音号'));
        } else {
        const regex = /^[a-zA-Z0-9_.]{1,16}$/;
          if (regex.test(value)) {
            this.$refs.ruleForm.validateField('checkPass');
          }else{
            callback(new Error('最多16个字,只允许包含字母,数字,下划线和点'));
          }
          callback();
        }
      };
        return {
            shortTable: [],
            currentSort: 0,
            platform_type: '',//平台类型
            // sortList:['抖音'],
            multipleSelection: [],
            chooseNumber: 0,
            //分页
            studentPages: {
                currentPageNum: 1,
                eachPageNum: 100,
                total: 0
            },
            accountInfo:{
                id:void 0,
                unique_id:"",
                label:"",
                operator:"",
                remark:"",
                account_remark:"",
                type:void 0,
            },
            dialogFormVisible:false,
            accountInfoRules:{
                unique_id: [
            { required: true, message: '请输入抖音号', trigger: 'blur' },
            { validator: validatePass,message: '最多16个字,只允许包含字母,数字,下划线和点', trigger: 'blur' }
          ],
            label:[
            { required: true, message: '请输入标签', trigger: 'blur' },
            { min: 1, max: 50, message: '长度不能超过50个字符', trigger: 'blur' }
          ],
                operator:[
            { required: true, message: '请输入运营人', trigger: 'blur' },
            { min: 1, max: 50, message: '长度不能超过50个字符', trigger: 'blur' }
          ],
                remark:[
            { required: true, message: '请输入备注', trigger: 'blur' },
            { min: 1, max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
            
          ],
          account_remark:[
            { required: true, message: '请输入备注', trigger: 'blur' },
            { min: 1, max: 50, message: '长度不能超过50个字符', trigger: 'blur' },
            
          ],
            },
            dialogTitle:"",
            sort:{
                sort:"",
                sort_field:""
            },
            labelCheckList:[],
            operationChexkList:[],
            remarkCheckList:[],
            account_remarkCheckList:[],
            selectLabelList:[],
            selectOperationList:[],
            selectRemarkList:[],
            account_selectRemarkList:[],
        }
    },
    mounted() {
        if(localStorage.getItem("labelCheckList")){
            this.labelCheckList=JSON.parse(localStorage.getItem("labelCheckList"))
        }else{
            this.labelCheckList=[]
        }
        if(localStorage.getItem("operationChexkList")){
            this.operationChexkList=JSON.parse(localStorage.getItem("operationChexkList"))
        }else{
            this.operationChexkList=[]
        }
        if(localStorage.getItem("remarkCheckList")){
            this.remarkCheckList=JSON.parse(localStorage.getItem("remarkCheckList"))
        }else{
            this.remarkCheckList=[]
        }
        if(localStorage.getItem("account_remarkCheckList")){
            this.account_remarkCheckList=JSON.parse(localStorage.getItem("account_remarkCheckList"))
        }else{
            this.account_remarkCheckList=[]
        }

        
        this.getAccountList()
      
    },
    //扫码成功后刷新列表
    watch: {
        isFresh(codeList) {
            if (codeList === true) {
                this.getAccountList()
            }
        }
    },
    methods: {
        getValue(row, value) {
            return (row.fans_data[0]?.item === value ? row.fans_data[0]?.value
                : row.fans_data[1]?.value );
        },
        reBindDouyin(item) {
            console.log('item', item);
            this.$httpStudent.axiosGetBy(this.$api.tikhoknew_authorization, {}, res => {
                if (res.code === 200) {
                    console.log('url', res.data.url);
                    window.open(res.data.url, '抖音授权扫码', 'channelmode=0,directories=0,width=1000,height=500,top=200,left=200');
                    this.dialogAddAccount = false;
                    this.AddAccountManageModule = false;
                } else {
                    this.$message({
                        type: "error",
                        message: res.message,
                        duration: 1000
                    });
                }
            },
                err => {
                    console.log(err);
                })
        },
        // chooseSort(sortIndex) {
        //     this.currentSort = sortIndex;
        //     this.$emit('shortVideoTab',this.currentSort)
        // },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.chooseNumber = val.length
            this.$emit("handleSelectionChange", this.multipleSelection)
        },
        deletePro() {
            console.log(1111)
        },
        // 获取列表
        getAccountList() {
            let param = {
                page: this.studentPages.currentPageNum,
                pageSize: this.studentPages.eachPageNum,
                paging: 1,
                sort:this.sort.sort,
                sort_field:this.sort.sort_field,
                label:this.labelCheckList.join(","),
                operator:this.operationChexkList.join(","),
                remark:this.remarkCheckList.join(","),
                account_remark:this.account_remarkCheckList.join(","),
            };

            this.$httpStudent.axiosGetBy(this.$api.get_account, param, res => {
                if (res.code === 200) {
                    this.shortTable = res.data.data;
                    console.log(res.data.data, this.shortTable, "res.data.data");

                    this.studentPages.total = res.data.total;
                } else if (res.code === 204) {
                    this.$message.error(res.message);
                    this.$router.push('/');
                }
            }, (err) => {
                console.log(err);
            })
        },
        // 置顶
        getTop(id) {
            let param = {
                id: id,
                need_top: 1
            };
            this.$httpStudent.axiosPost(this.$api.account_top, param, (res) => {
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: '置顶成功',
                        duration: 1000,
                    });
                    this.getAccountList()
                } else {
                    this.$message.warning(res.message)
                }
            }, (err) => {
                console.log(err)
            })
        },
        // 取消置顶
        unPinkTop(id) {
            let param = {
                id: id,
                need_top: 0
            };
            this.$httpStudent.axiosPost(this.$api.account_top, param, (res) => {
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: '取消置顶成功',
                        duration: 1000,
                    });
                    this.getAccountList()
                } else {
                    this.$message.warning(res.message)
                }
            }, (err) => {
                console.log(err)
            })
        },
        gotoData(row) {
            if (Number(row.auth) === 0) {
                this.$message.warning('授权失败，该账号被其他人绑定不能查看')
                return
            }
            if (row.platform_type === 1) {
                if (row.audit_status === 1) {
                    this.$router.push({
                        path: '/student/data/details',
                        query: {
                            id: row.id,
                            platform: 1,
                            type: 1
                        }
                    })
                } else {
                    this.$message({
                        type: "warning",
                        message: '账号还未审核成功',
                        duration: 1000,
                    });
                }
            } else {
                let param = {
                    type: 2,
                    platform_type: 7,
                    id: row.id,
                };
                this.$httpStudent.axiosGetBy(this.$api.get_account, param, res => {
                    if (res.code === 200) {
                        window.open(res.data.url)
                    } else if (res.code === 204) {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })

            }
        },
        // 删除
        deleteRow(row) {
            this.$confirm('是否删除该数据，删除完将无法恢复', '提示消息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                let param = {
                    id: row.id,
                    type: row.type,
                    platform_type: row.platform_type,
                }
                this.$httpStudent.axiosPost(this.$api.account_delete, param, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.message);
                        this.getAccountList()
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            }).catch(() => {
                this.$message.info('已取消删除');
            })
        },
        studentCurrentChange(val) {
            this.studentPages.currentPageNum = val;
            this.getAccountList()
        },
        studentSizeChange(val){
            this.studentPages.eachPageNum=val
            this.getAccountList()
        },
        editRow(row,type){
            this.accountInfo.id=row.id
            console.log(row,type);

            if(type==1){
                this.accountInfo.unique_id=row.unique_id?row.unique_id:""
                this.dialogTitle=!row.unique_id?'添加抖音号':"编辑抖音号"
            }else if(type==2){
                this.accountInfo.label=row.label?row.label:""
                this.dialogTitle= !row.label?'添加标签':'编辑标签'
            }else if(type==3){
                this.accountInfo.operator=row.operator?row.operator:""
                this.dialogTitle=row.operator?'添加运营人':'编辑运营人'
            }else if(type==4){
                this.accountInfo.remark=row.remark?row.remark:""
                this.dialogTitle=!row.remark?"添加备注":"编辑备注"
            }else{
                this.accountInfo.account_remark=row.account_remark?row.account_remark:""
                this.dialogTitle=!row.account_remark?"添加备注":"编辑备注"
                
            }
            this.accountInfo.type=type
            this.dialogFormVisible=true



        },
        cancalEidtRow(){
            this.dialogFormVisible=false
            this.$refs.ruleForm.resetFields();


        },
        confirmRow(){
            this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                let param = {
                    id: this.accountInfo.id
                };
                if(this.accountInfo.type==1){
                    param.unique_id=this.accountInfo.unique_id
                }else if(this.accountInfo.type==2){
                    param.label=this.accountInfo.label
                }else if(this.accountInfo.type==3){
                    param.operator=this.accountInfo.operator
                }else if(this.accountInfo.type==4){
                    param.remark=this.accountInfo.remark
                }else{
                    param.account_remark=this.accountInfo.account_remark
                }
                this.$httpStudent.axiosPost(this.$api.updateAccount, param, res => {
                    if (res.code === 200) {
                        this.getAccountList()
                        this.$nextTick(()=>{
                            this.$refs.ruleForm.resetFields();
                        })
                        this.dialogFormVisible=false

                    }else{
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })

          } else {
            console.log('error submit!!');
            return false;
          }
        });


        },
        handleSortChange({ column, prop, order }){
            console.log(column,prop,order);

            if(order=='ascending'){
                this.sort.sort='asc'
            }else{
                this.sort.sort='desc'
            }
            this.sort.sort_field=prop
            this.getAccountList()

        },
        getNumber(num) {
            if (num >= 100000000) {
                return (num / 10000 / 10000).toFixed(2) + "亿"
            } else if (num >= 10000) {
                return (num / 10000).toFixed(2) + "万"
            } else {
                return num
            }

        },
        getContidionList(){
            this.$httpStudent.axiosGet(this.$api.accountGetContidionList, (res) => {
                if (res.code === 200) {
                 this.selectLabelList=res.data.label
                 this.selectOperationList=res.data.operator
                 this.selectRemarkList=res.data.remark
                 this.account_selectRemarkList=res.data.account_remark
                 if(!localStorage.getItem("labelCheckList")&&this.labelCheckList.length==0){
                    this.labelCheckList=this.selectLabelList
                }
                if(!localStorage.getItem("operationChexkList")&&this.operationChexkList.length==0){
                    this.operationChexkList=this.selectOperationList
                }
                if(!localStorage.getItem("remarkCheckList")&&this.remarkCheckList.length==0){
                    this.remarkCheckList=this.selectRemarkList
                }
                if(!localStorage.getItem("account_remarkCheckList")&&this.account_remarkCheckList.length==0){
                    this.account_remarkCheckList=this.account_selectRemarkList
                }
               
                
              
                 
                } else {
                    this.$message.warning(res.message)
                }
            }, (err) => {
                console.log(err)
            })
        },
        showSelect(){
            this.getContidionList()
            
        },
        selectList(){
            localStorage.setItem("labelCheckList",JSON.stringify(this.labelCheckList))
            localStorage.setItem("operationChexkList",JSON.stringify(this.operationChexkList))
            localStorage.setItem("remarkCheckList",JSON.stringify(this.remarkCheckList))
            localStorage.setItem("account_remarkCheckList",JSON.stringify(this.account_remarkCheckList))
            
            this.getAccountList()
        },
        resetList(){
            this.labelCheckList=[]
            this.operationChexkList=[]
            this.remarkCheckList=[]
            this.account_remarkCheckList=[]
            localStorage.setItem("labelCheckList",JSON.stringify(this.labelCheckList))
            localStorage.setItem("operationChexkList",JSON.stringify(this.operationChexkList))
            localStorage.setItem("remarkCheckList",JSON.stringify(this.remarkCheckList))
            localStorage.setItem("account_remarkCheckList",JSON.stringify(this.account_remarkCheckList))
            this.getAccountList()

        }
    }
}
</script>

<style scoped lang="scss">
.account-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .del-wrapper {
        margin-bottom: 20px;
        display: flex;

        .del-btn {
            cursor: pointer;
            transition: all .3s;

            i {
                margin-right: 6px;
            }

            &:hover {
                color: #5864e4;
            }
        }

        .del-number {
            margin-left: 20px;
        }
    }

    .account-number {
        display: flex;
        align-items: center;

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }

        .scope-title {
            max-width: calc(100% - 50px);
            margin-left: 10px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            cursor: pointer;
            font-size: 14px;
        }
    }

    .down {
        & i {
            font-size: 14px;
            color: #42D7AB;
            -ms-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            padding-right: 10px;
            display: inline-block;
            transform: rotate(180deg);
        }
    }

    .up {
        & i {
            font-size: 14px;
            color: #FF0000;
            padding-left: 10px;
        }
    }

    .operation {
        .topping {
            span {
                color: #4659f2;

                &:hover {
                    color: #463bdc;
                }
            }
        }

        .del {
            span {
                color: #f56c6c;

                &:hover {
                    color: #ff0000;
                }
            }
        }
    }

    .under-passed {
        color: #1BB285;
    }

    .under-err {
        color: #f56c6c;
    }

    .script-content-list {
        .script-content-tab {
            ::v-deep .el-tabs__item {
                height: 45px;
                line-height: 45px;
                font-size: 16px;
                font-weight: 400;
                border: none;
                color: #16113F;
            }

            ::v-deep .el-tabs__active-bar {
                background: #9ACC5B;
            }

            ::v-deep .el-tabs__active-bar:before {
                position: absolute;
                top: -13px;
                left: 40%;
                content: '';
                width: 0;
                height: 0;
                border-style: dashed dashed solid dashed;
                border-width: 7px;
                border-color: transparent transparent #9ACC5B transparent;
            }

            /*::v-deep.el-tabs__item{*/
            /*    padding: 0;*/
            /*    margin-right:20px;*/
            /*    box-sizing: border-box;*/
            /*}*/
        }

        .dy-main {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 286px);

            ::v-deep .el-table {
                display: flex;
                flex-direction: column;
            }

            ::v-deep .el-table__footer-wrapper,
            ::v-deep .el-table__header-wrapper {
                overflow: initial;
            }

            ::v-deep .el-table__body-wrapper {
                overflow: auto;
            }
        }
    }
}
.box_btn{
    width: 100%;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // background: skyblue;
    .text{
        display: inline-block;
        max-width: 90%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .editBtn{
        cursor: pointer;
        display: none;
        margin-left: 10px;
    }
  &:hover{
    .editBtn{
        cursor: pointer;
        display: inline-block;
    }
}
}


.re_bind_douyin {
    width: 60px;
    height: 22px;
    background: #F2F2F4;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(37, 38, 50, 0.6);
    border: none;
    margin-left: 10px;
}
::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
    // border-bottom: 1px solid transparent !important;
}
::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
    // border-right: 1px solid #c0c4cc !important;
}
::v-deep .el-table .caret-wrapper {
    display: none !important;
}
::v-deep .el-table--medium .el-table__cell{
    padding: 8px 0px !important;
}
::v-deep .el-table .caret-wrapper-c{
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 34px;
    width: 24px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
}
/* 设置滚动条整体 */
::-webkit-scrollbar {
  width: 4px; /* 滚动条宽度 */
  height: 4px; /* 水平滚动条高度 */
}

/* 设置滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 轨道背景色 */
}

/* 设置滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #888; /* 滑块背景色 */
  border-radius: 10px; /* 滑块圆角 */
}

/* 设置滚动条滑块悬停时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滑块悬停背景色 */
}
</style>
