<template>
    <div class="interactive-content-module">
        <div class="interactive-content-select">
            <el-select v-model="platformValue" placeholder="请选择" class="platform-select" @change="choosePlatform">
                <el-option
                        v-for="item in platformList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            <el-select v-model="accountValue" placeholder="请选择" class="platform-select" @change="toggleAccount">
                <el-option
                        v-for="item in accountList"
                        :key="item.id"
                        :label="item.nickname"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="interactive-content-list">
            <el-tabs v-model="interactiveTab" @tab-click="changeInteractiveTab" class="interactive-content-tab">
                <el-tab-pane label="关注管理" name="attention">
                    <AttentionManage ref="attentionManage" :accountValue="accountValue"></AttentionManage>
                </el-tab-pane>
                <el-tab-pane label="粉丝管理" name="fensi">
                    <FensiManage ref="fansManage"></FensiManage>
                </el-tab-pane>
                <!-- <el-tab-pane label="评论管理" name="comment">
                    <commentManage ref="commentManage"></commentManage>
                </el-tab-pane> -->
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import AttentionManage from './AttentionManage.vue'
    import FensiManage from './FensiManage.vue'
    import commentManage from './commentManage.vue'
    import {mapActions,mapMutations} from 'vuex'
    export default {
        name: "InteractiveContentModule",
        components: {
            AttentionManage,
            FensiManage,
            commentManage
        },
        data() {
            return {//平台选择
              platformList: [
                {
                  value: 1,
                  label: '抖音'
                },
                {
                  value: 2,
                  label: '快手'
                },
                {
                  value: 3,
                  label: '哔哩哔哩',
                },
                {
                  value: 4,
                  label: '美拍',
                },
                {
                  value: 5,
                  label: '西瓜',
                },
                {
                  value: 6,
                  label: '火山视频',
                }
              ],

                platformValue: 1,
                //账号选择
                accountList: [],
                accountValue: '',
                interactiveTab: 'attention',
            }
        },
        created() {
            this.getPlatformAccount();
        },
        methods: {
            ...mapMutations(['setDouyinId']),
            //切换tab
            changeInteractiveTab(tab) {
                if (tab.name === 'attention') {
                    this.$refs.attentionManage.cursor = 0;
                    this.$refs.attentionManage.attentionList = [];
                    this.$refs.attentionManage.getAttentionList();
                }
                if (tab.name === 'fensi') {
                    this.$refs.fansManage.hasMore = false
                    this.$refs.fansManage.cursor = 0
                    this.$refs.fansManage.fansList = []
                    this.$refs.fansManage.getFansList()
                }
                if (tab.name === 'comment') {
                    this.$refs.commentManage.listPages.currentPageNum = 1
                    this.$refs.commentManage.listPages.eachPageNum = 10
                    this.$refs.commentManage.levelOneCursor = 0
                    this.$refs.commentManage.commentList = []
                    this.$refs.commentManage.getUserInfo()
                    this.$refs.commentManage.getVideoList()
                }
            },
            // 切换账号
            toggleAccount(item) {
                this.$router.push({
                    path: '/student/interactive/index',
                    query: {
                        id: item,
                        platformId: this.platformValue
                    }
                })
                this.$emit('changeAccount', 1,this.platformValue,item);
                this.accountValue = item;
                // this.$router.push({
                //     path: '/student/interactive/index',
                //     query: {
                //         id: this.accountValue,
                //         platformId: this.platformValue
                //     }
                // })
                this.getPlatformAccount();
                if (this.interactiveTab === 'attention') {
                    this.$refs.attentionManage.cursor = 0;
                    this.$refs.attentionManage.attentionList = [];
                    this.$refs.attentionManage.getAttentionList();
                }
                if (this.interactiveTab === 'fensi') {
                    this.$refs.fansManage.hasMore = false
                    this.$refs.fansManage.cursor = 0
                    this.$refs.fansManage.fansList = []
                    this.$refs.fansManage.getFansList()
                }
                if (this.interactiveTab === 'comment') {
                    this.$refs.commentManage.listPages.currentPageNum = 1
                    this.$refs.commentManage.listPages.eachPageNum = 10
                    this.$refs.commentManage.levelOneCursor = 0
                    this.$refs.commentManage.commentList = []
                    this.$refs.commentManage.replyContent = ''
                    this.$refs.commentManage.getUserInfo()
                    this.$refs.commentManage.getVideoList()
                }
            },
            //选择平台
            choosePlatform(id) {
                this.platformValue = id;
                this.getPlatformAccount();
            },
            //获取平台账号
            getPlatformAccount() {
                this.$httpStudent.axiosGetBy(this.$api.get_account, {platform_type: this.platformValue, type: 1},  (res) => {
                    if (res.code === 200) {
                        if (res.data.data.length > 0) {
                            let arr = res.data.data;
                            this.accountList = res.data.data;
                            if (this.accountValue) {
                                this.$router.push({
                                    path: '/student/interactive/index',
                                    query: {
                                        id: this.accountValue,
                                        platformId: this.platformValue
                                    }
                                })
                                this.setDouyinId(this.accountValue);
                            } else {
                                this.accountValue = arr[0].id;
                                this.setDouyinId(this.accountValue);
                                this.$router.push({
                                    path: '/student/interactive/index',
                                    query: {
                                        id: this.accountValue,
                                        platformId: this.platformValue
                                    }
                                })
                            }
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .interactive-content-module {
        width: 100%;
        padding: 14px 40px;
        box-sizing: border-box;
        .interactive-content-select {
            margin-bottom: 15px;
            .platform-select {
                width: 136px;
                padding-right: 40px;
                ::v-deep .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                }
                ::v-deep .el-input__icon {
                    line-height: 30px;
                }
            }
        }
        .interactive-content-list {
            .interactive-content-tab {
                ::v-deep .el-tabs__item {
                    height: 45px;
                    line-height: 45px;
                    font-size: 16px;
                    font-weight: 400;
                    border: none;
                    color: #16113F;
                }

                ::v-deep .el-tabs__active-bar {
                    background: #9ACC5B;
                }

                ::v-deep .el-tabs__active-bar:before {
                    position: absolute;
                    top: -13px;
                    left: 40%;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: dashed dashed solid dashed;
                    border-width: 7px;
                    border-color: transparent transparent #9ACC5B transparent;
                }
            }
        }
    }
</style>