<template>
    <div class="account-wrpper">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
            <el-breadcrumb-item class="first">创作中心</el-breadcrumb-item>
            <el-breadcrumb-item class="second">账号管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="script-wrapper">
            <div class="account-btn-box">
                <div class="account-btn-right"><span @click="deleteAccount()" style="cursor: pointer;"><i
                            class="el-icon-delete"></i>删除</span>
                    (已选{{ this.deleteArr?.length }}个)</div>
                <div style="display: flex;">
                    <div class="btn-blue account-btn" style="margin-right: 10px;" @click="reset()">重置</div>
                    <div class="btn-blue account-btn" @click="addAccount()">添加账号</div>
                </div>
            </div>
            <div class="script-item">
                <ShortVideoModule ref="shortVideoModule" :isFresh="isFresh"
                    @handleSelectionChange="handleSelectionChange" />
            </div>
        </div>
        <div class="addAccount-item">
            <el-dialog title="选择添加账号平台" width="40%" :visible.sync="AddAccountManageModule" :close-on-click-modal="false">
                <AddAccountManageModule @platformSelect="platformSelectFun" />
            </el-dialog>
        </div>
        <!--添加账号-->
        <el-dialog title="添加账号" :visible.sync="dialogAddAccount" left width="30%" :close-on-click-modal="false"
            @close="resetForm()">
            <el-form v-if="selectPlatform.platform_type === '抖音号'" :model="addAccountForm" ref="addAccountForm"
                label-position="left" :rules="rules">
                <el-form-item label="输入主页链接" :label-width="formLabelWidth" prop="index_url" class="link-label">
                    <el-input v-model="addAccountForm.index_url" autocomplete="off" placeholder="请输入您的抖音主页链接方便审核"
                        style="width: 80%;"></el-input>
                    <el-tooltip content="说明:登录抖音，进入“我”选择“···”选择“分享个人主页” 选择“复制链接”，就可以取得主页链接" placement="top" effect="light">
                        <i class="iconfont tooltip-icon" style="margin-left: 10px">&#xe69c;</i>
                    </el-tooltip>
                </el-form-item>
            </el-form>
            <el-form v-else :model="addAccountForm" ref="addAccountForm" label-position="left" :rules="rules">
                <el-form-item label="用户名称" :label-width="formLabelWidth" prop="platform_name">
                    <el-input v-model="addAccountForm.platform_name" autocomplete="off" placeholder="请输入用户名称"
                        style="width: 80%;"></el-input>
                    <el-tooltip content="说明:用户名跟你关注的用户名要一致" placement="top" effect="light">
                        <i class="iconfont tooltip-icon" style="margin-left: 10px">&#xe69c;</i>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="百家号ID" :label-width="formLabelWidth" prop="app_id">
                    <el-input v-model="addAccountForm.app_id" autocomplete="off" placeholder="请输入百家号ID"
                        style="width: 80%;"></el-input>
                    <el-tooltip content="说明:复制百家号链接https://baijiahao.baidu.com到网页登录，选择开发-基本配置-复制app_id" placement="top"
                        effect="light">
                        <i class="iconfont tooltip-icon" style="margin-left: 10px">&#xe69c;</i>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="百家号token" :label-width="formLabelWidth" prop="app_token">
                    <el-input v-model="addAccountForm.app_token" autocomplete="off" placeholder="请输入百家号token"
                        style="width: 80%;"></el-input>
                    <el-tooltip content="说明:复制百家号链接https://baijiahao.baidu.com到网页登录，选择开发-基本配置-复制app_token" placement="top"
                        effect="light">
                        <i class="iconfont tooltip-icon" style="margin-left: 10px">&#xe69c;</i>
                    </el-tooltip>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddAccount = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addAccountForm')">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogCode" custom-class="centerDialog" width="30%" :close-on-click-modal="false"
            @close="isDialogCode()">
            <div class="code-title">注：先用百度系app扫码关注，关注后关闭弹窗填写账号信息</div>
            <div class="baijai-code">
                <img :src="codeUrl" alt="">
            </div>
        </el-dialog>
        <!--添加账号-->
    </div>
</template>

<script>
import ShortVideoModule from '../../../components/creativecentermodule/ShortVideoModule.vue'
import AddAccountManageModule from '../../../components/creativecentermodule/AddAccountManageModule.vue'

export default {
    name: "AccountManage",
    components: {
        ShortVideoModule,
        AddAccountManageModule
    },
    data() {
        return {
            AddAccountManageModule: false,
            selectPlatform: '',//选择平台
            dialogAddAccount: false,
            formLabelWidth: '110px',
            codeUrl: '', //百家号二维码
            dialogCode: false, //百家号二维码
            addAccountForm: {
                index_url: '',
                account: '',
                password: '',
                account_link: '',
                platform_name: '',
                app_id: '',
                app_token: '',
                platform: '',
            },
            // sortIndex:0,
            rules: {
                platform: [
                    { required: true, message: '请选择平台', trigger: 'blur' },
                ],
                index_url: [
                    { required: true, message: '请输入链接', trigger: 'blur' },
                ],
                platform_name: [
                    { required: true, message: '请输入百家号名称', trigger: 'change' },
                ],
                app_id: [
                    { required: true, message: '请输入百家号ID', trigger: 'change' },
                ],
                app_token: [
                    { required: true, message: '请输入百家号token', trigger: 'change' },
                ],
            },
            isFresh: false,
            deleteArr: []
        }
    },
    mounted() {

    },
    methods: {
        // 获取账号
        addToForm() {
            let param = {}
            if (this.selectPlatform.platform_type === '抖音号') {
                console.log('fff', this.addAccountForm);
                param = {
                    index_url: this.addAccountForm.index_url
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_authorization, param, res => {
                    if (res.code === 200) {
                        console.log('url', res.data.url);
                        window.open(res.data.url, '抖音授权扫码', 'channelmode=0,directories=0,width=1000,height=500,top=200,left=200');
                        this.dialogAddAccount = false;
                        this.AddAccountManageModule = false;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                },
                    err => {
                        console.log(err);
                    })
            } else {
                let param = {
                    platform: 1,
                    platform_name: this.addAccountForm.platform_name,
                    app_id: this.addAccountForm.app_id,
                    app_token: this.addAccountForm.app_token,
                }
                this.$httpStudent.axiosPost(this.$api.api_addplatformaccount, param, res => {
                    if (res.code === 200) {
                        this.$message({
                            type: "success",
                            message: '获取成功',
                            duration: 1000
                        });
                        this.dialogAddAccount = false;
                        this.$refs.shortVideoModule.getAccountList()
                        //如果绑定过该账号，接口返回值会为true
                        if (res.data.id !== true) {
                            this.saveIntelligent(res.data.id);
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                },
                    err => {
                        console.log(err);
                    })
            }
        },
        saveIntelligent(id) {
            let param = {
                user_account_id: parseInt(id),
            }
            switch (this.selectPlatform.platform_type) {
                case '抖音号':
                    param.type = 2;
                    param.identifying = 2;
                    break;
                case '百家号':
                    param.type = 1;
                    param.identifying = 1;
                    break;
                default:
                    param.type = 1;
                    param.identifying = 1;
                    break;
            }
            this.$httpStudent.axiosPost(this.$api.intelligent_saveIntelligent, param, res => {
                if (res.code === 200) {
                } else {
                    this.$message({
                        type: "error",
                        message: res.message,
                        duration: 1000
                    });
                }
            },
                err => {
                    console.log(err);
                })
        },
        handleClick(tab, event) {
            this.tabType = event.target.innerText;
        },
        // 添加账号
        addAccount() {
            this.AddAccountManageModule = true;
        },
        isDialogCode() {
            // this.dialogCode = false;
            this.dialogAddAccount = true;
        },
       
        //平台选择
        platformSelectFun(platform) {
            this.selectPlatform = platform;
            this.AddAccountManageModule = true;
            this.dialogCode = false;
            this.dialogAddAccount = false;
            if (this.selectPlatform.platform_type === '抖音号') {
                //短视频添加账号
                // this.addToForm();
                //隐藏弹框
                // this.dialogAddAccount = true;
                this.AddAccountManageModule = false
                this.addToForm()
            } else {
                //文创添加账号
                this.$httpStudent.axiosGet(this.$api.api_qrCodeBjh, res => {
                    if (res.code === 200) {
                        this.codeUrl = res.data.url
                        this.dialogCode = true
                        this.AddAccountManageModule = false
                        // window.open(res.data.url,'百家号授权码','channelmode=0,directories=0,width=1000,height=500,top=200,left=200');

                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                },
                    err => {
                        console.log(err);
                    })
            }
        },
        //接收短视频组件平台的选择值
        shortVideoSort(sortIndex) {
            this.sortIndex = sortIndex;
        },
        //重置表单
        resetForm() {
            this.$refs.addAccountForm.resetFields();
            for (let key in this.addAccountForm) {
                this.addAccountForm[key] = "";
            }
        },
        // 标签切换
        // toggleAccountTab(index) {
        //     this.accountTabCurrent = index
        // },
        handleSelectionChange(arr) {
            this.deleteArr = arr
            console.log("选中删除", arr);
        },
        deleteAccount() {
            if (this.deleteArr.length == 0) {
                this.$message.warning("请选中要删除的账户")
            } else {
                this.$confirm('此操作将永久删除该账户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let arr = []
                    this.deleteArr.forEach(item => {
                        arr.push(item.id)
                    })
                    let param = {
                        id: arr.join(',')
                    }
                    this.$httpStudent.axiosPost(this.$api.account_delete, param, (res) => {
                        if (res.code == 200) {
                            this.$refs.shortVideoModule.getAccountList()
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
        reset(){
        this.$refs.shortVideoModule.resetList()  
        }

    },
  
}
</script>

<style scoped lang="scss">
::v-deep .el-tab-pane {
    height: 100%;
}

.account-wrpper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);

    ::v-deep .el-tabs {
        flex: 1;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    ::v-deep .el-tabs__header {
        border-bottom: none;
        margin: 0;
    }

    ::v-deep .el-tabs__content {
        height: 1%;
        flex: 1;
        background: #fff;
        padding-top: 20px;
    }

    .account-item {
        width: 100%;
        height: calc(100% - 40px);
        overflow: auto;
        display: flex;
        flex-wrap: wrap;

        & li {
            display: flex;
            align-items: center;
            justify-content: start;
            flex-wrap: wrap;
            cursor: pointer;
        }

        .account-img {
            display: inline-block;
            height: 60px;
            margin: 0 20px 20px 0;

            img {
                border-radius: 50%;
                width: 48px;
                height: 48px;
            }
        }
    }

    .tooltip-icon {
        margin-left: 10px;

        &:hover {
            color: #1E34E4;
            cursor: pointer;
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: center;
        align-items: center;

        ::v-deep .el-button--primary {
            color: #FFF;
            background-color: #0824ce;
            border-color: #0824ce;
        }

        ::v-deep .el-button--primary:focus,
        .el-button--primary:hover {
            background: #495df1;
            border-color: #495df1;
            color: #FFF;
        }
    }
}

::v-deep .el-tabs__header .is-active {
    background: #fff;
    font-size: 16px;
    font-weight: 400;
    color: #0824ce;
    border-radius: 6px 6px 0 0;
}

::v-deep .el-tabs__item {
    height: 52px;
    line-height: 52px;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none;
}

.script-wrapper {
    display: flex;
    flex-direction: column;
    height: 1%;
    flex: 1;
    background: #fff;
    border-radius: 10px;
    padding: 30px 40px 0;

    .account-creation-tabs {
        text-align: left;

        .account-tabs {
            display: inline-block;
            margin-right: 20px;
            position: relative;
            cursor: pointer;
            transition: all .3s;
            background-color: #F1F0FE;
            color: #574DED;
            border-radius: 4px;
            padding: 4px 16px;

            &:last-child {
                margin-right: 0;
            }

            &.current {
                background-color: #574DED;
                color: #fff;
            }

            &:hover {
                background-color: #1E33E4;
                color: #fff;
            }
        }
    }

    .script-item {
        /*display: none;*/
        height: 1%;
        flex: 1;
        justify-content: flex-start;
        margin-top: 20px;

        .script-content {
            padding: 16px;
            border-bottom: 1px solid #EAEAEA;

            .script-search {
                display: flex;

                .el-input {
                    width: 1%;
                    flex: 1;
                    margin-left: 10px;
                }

                ::v-deep .el-input__inner {
                    border: 1px solid #594FEE;
                    border-radius: 15px;
                }
            }

            .script-model {
                text-align: center;
                margin-bottom: 16px;
            }

            .hot-script {
                text-align: left;
                margin: 16px 0 0;
            }
        }

        .script-list {
            height: 1%;
            flex: 1;
            margin: 10px 0;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .item {
                padding: 16px;
                border-bottom: 1px solid #EAEAEA;

                .script {
                    margin-bottom: 10px;
                    color: #333;

                    &:hover {
                        color: #1E33E3;
                    }
                }

                .script-btn {
                    text-align: right;

                    .script-btn-blue {
                        border-radius: 4px;
                        background: #574DED;
                        color: #fff;

                        &:hover {
                            background: #1E33E3;
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                }

                .item-top {
                    display: flex;

                    .script-img {
                        width: 93px;
                        height: 58px;
                        display: inline-block;

                        margin-right: 10px;
                    }
                }
            }
        }

        &.current {
            display: flex;
        }

        .script-item {
            flex: 1;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
    }

    .account-btn-box {
        display: flex;
        justify-content: space-between;

        .account-btn-right {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;

            i {
                margin-right: 6px;
            }
        }

        .btn-blue {
            width: 96px;
            background: #574DED;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
        }
    }
}

.addAccount-item {
    ::v-deep .el-dialog__body {
        padding: 0 20px;
    }
}

.baijai-code {
    width: 200px;
    height: 200px;
    margin: 0 auto;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.code-title {
    text-align: center;
    font-size: 16px;
    color: #ff0000;
    margin-bottom: 10px;
}
</style>
