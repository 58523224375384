<template>
  <div class="posts-analysis">
    <div class="item">
      <div class="item-header">
        <div class="item-header_title">
          <span>投稿预览</span>
          <el-popover popper-class="black-popover" trigger="hover" content="所选周期内发布作品的数据预览，数据每天更新" width="180">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </div>

        <div class="item-header_operation">
          <el-button icon="el-icon-download" @click="exportData">导出数据</el-button>
        </div>
      </div>

      <div class="item-content">
        <div
          :class="['preview-button', { 'is-active': currentPerview == item.id }]"
          v-for="item in previewMenus"
          :key="item.id"
          @click="handleCheckPerview(item)"
        >
          <div class="preview-button_title">
            <span>{{ item.name }}</span>
            <el-popover
              v-if="item.tipShow && currentPerview === item.id"
              trigger="hover"
              popper-class="black-popover"
              :content="item.tip"
              width="180"
            >
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
          </div>
          <div class="preview-button_data">{{ item.data }}{{ item.unit ? item.unit : "" }}</div>
        </div>
      </div>
    </div>

    <div class="item">
      <div class="item-header">
        <div class="item-header_title">
          <span>投稿表现</span>
          <el-popover popper-class="black-popover" trigger="hover" content="所选周期内发布作品的表现，数据每天更新" width="180">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </div>

        <div class="item-header_operation">
          <el-button icon="el-icon-download" @click="exportChartData">导出数据</el-button>
        </div>
      </div>

      <div class="performance-content" v-if="performanceData.data.length > 0">
        <div class="item-brief-info">
          <div class="item-brief-info_type">
            <i class="el-icon-s-marketing"></i>
            <span>{{ getTypeLabel }}</span>
          </div>

          <div class="item-brief-info_average imp-text">
            均值: <span class="data">{{ performanceData.avg_num }}{{ chartTypeOptions.find((item) => item.value == chartType).unit }}</span
            >,
          </div>

          <div class="item-brief-info_max imp-text">
            最高:
            <span class="data"
              >{{ performanceData.max_data ? performanceData.max_data.num : 0
              }}{{ chartTypeOptions.find((item) => item.value == chartType).unit }}&nbsp;</span
            >
            (<span class="tip">{{ performanceData.max_data ? performanceData.max_data.title : "" }}</span
            >) ,
          </div>

          <div class="item-brief-info_min imp-text">
            最低:
            <span class="data"
              >{{ performanceData.min_data ? performanceData.min_data.num : 0
              }}{{ chartTypeOptions.find((item) => item.value == chartType).unit }}&nbsp;</span
            >
            (<span class="tip">{{ performanceData.min_data ? performanceData.min_data.title : "" }}</span
            >)
          </div>
        </div>

        <div class="performance-chart">
          <el-select class="autoWidth" v-model="chartType" placeholder="请选择" @change="getContentAnalysisTrend()">
            <template slot="prefix">
              {{ chartTypeOptions.find((item) => item.value == chartType).label }}
              <el-popover
                trigger="hover"
                popper-class="black-popover"
                :content="chartTypeOptions.find((item) => item.value == chartType).tip"
              >
                <i class="el-icon-question" slot="reference" style="color: #bbbbbd"></i>
              </el-popover>
            </template>
            <el-option v-for="type in chartTypeOptions" :key="type.value" :label="type.label" :value="type.value"> </el-option>
          </el-select>
          <div class="chart" ref="chart"></div>
        </div>
      </div>
      <div class="performance-content empty" v-else>
        <img src="@/assets/img/datacenter/no-data.png" alt="" />
        <span>暂无数据</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PostsAnalysis",
  data() {
    return {
      currentPerview: "video_num",
      previewMenus: [
        {
          id: "video_num",
          name: "周期内投稿量",
          data: 0,
          tip: "所选周期内的投稿量数据，数据每天更新",
          tipShow: true,
        },
        {
          id: "avg_play_duration",
          name: "条均播放时长",
          data: 0,
          unit: "秒",
          tip: "所选周期内发布作品的播放时长均值，数据每天更新",
          tipShow: true,
        },
        {
          id: "avg_play_num",
          name: "播放量中位数",
          data: 0,
          tip: "所选周期内发布作品的播放量中位数，数据每天更新",
          tipShow: true,
        },
        {
          id: "avg_digg_num",
          name: "条均点赞数",
          data: 0,
          tip: "所选周期内发布作品的点赞数均值，数据每天更新",
          tipShow: true,
        },
        {
          id: "avg_comment_num",
          name: "条均评论数",
          data: 0,
          tip: "所选周期内发布作品的评论数均值，数据每天更新",
          tipShow: true,
        },
        {
          id: "avg_share_num",
          name: "条均分享量",
          data: 0,
          tip: "所选周期内发布作品的分享量均值，数据每天更新",
          tipShow: true,
        },
      ],
      chartType: "1",
      chartTypeOptions: [
        {
          label: "播放量",
          value: "1",
          tip: "所选周期内发布作品的播放量，数据每天更新",
        },
        {
          label: "平均播放时长",
          value: "2",
          unit: "秒",
          tip: "所选周期内发布作品的平均播放时长，数据每天更新",
        },
      ],
      performanceData: {
        avg_num: 0,
        data: [],
        max_data: null,
        min_data: null,
      },
      allAccountsInit: true,
    };
  },
  props: {
    filterAccounts: { type: Array, required: true },
    filterTime: { type: Array, required: true },
    allAccounts: { type: Array, required: true },
  },
  computed: {
    getTypeLabel() {
      const typeObj = this.chartTypeOptions.find((item) => item.value == this.chartType);
      return typeObj.label + "表现";
    },
  },
  watch: {
    filterAccounts: {
      handler(newval) {
        this.getContentAnalysis();
        this.getContentAnalysisTrend(true);
      },
      deep: true,
      immediate: false,
    },
    filterTime: {
      handler(newval) {
        this.getContentAnalysis();
        this.getContentAnalysisTrend(true);
      },
      deep: true,
      immediate: false,
    },
    allAccounts: {
      handler(newval) {
        if (newval && newval.length > 0 && this.allAccountsInit) {
          this.getContentAnalysis();
          this.getContentAnalysisTrend(true);
          this.allAccountsInit = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 切换
    handleCheckPerview(menus) {
      this.currentPerview = menus.id;
    },

    // 投稿分析
    getContentAnalysis() {
      const params = {
        id: this.filterAccounts.toString(),
        date: this.filterTime.toString(),
      };
      // if (this.filterAccounts.length == 0) params.id = this.allAccounts.toString();
      if (params.id == "" || params.date == "") {
        this.previewMenus.forEach((item) => {
          this.$set(item, "data", 0);
        });
        return;
      }

      this.$httpStudent.axiosGetBy(this.$api.dataCenterContentAnalysis, params, (res) => {
        if (res.code == 200) {
          for (let key in res.data) {
            const previewObj = this.previewMenus.find((item) => item.id == key);
            this.$set(previewObj, "data", res.data[key]);
          }
        }
      });
    },

    // 投稿表现
    getContentAnalysisTrend(reset = false) {
      if (reset) this.chartType = "1";

      const params = {
        id: this.filterAccounts.toString(),
        data_type: this.chartType,
        date: this.filterTime.toString(),
      };
      // if (this.filterAccounts.length == 0) params.id = this.allAccounts.toString();
      if (params.id == "" || params.date == "") {
        this.performanceData = {
          avg_num: 0,
          data: [],
          max_data: null,
          min_data: null,
        };
        return;
      }

      this.$httpStudent.axiosGetBy(this.$api.dataCenterContentAnalysisTrend, params, (res) => {
        if (res.code == 200) {
          this.performanceData = res.data;

          this.$nextTick(() => this.setChartOptions());
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000,
          });
        }
      });
    },

    // 判断字符是否为中文字符
    isChinese(char) {
      return /[\u4e00-\u9fa5]/.test(char);
    },

    // 计算字符串的物理长度。中文字符为2，英文字符为1，超过16截断
    getDisplayLength(str) {
      let length = 0;
      let index = 0;
      for (index = 0; index < str.length; index++) {
        const char = str.charAt(index);
        length += this.isChinese(char) ? 2 : 1; // 中文字符长度2，英文字符长度1

        if (length > 16) break;
      }
      return index;
    },

    formatterTooltip(params) {
      let checkStr = `${this.performanceData.avg_num < params[0].value ? "高于" : "低于"}均值`;
      let checkNum = Math.abs(this.performanceData.avg_num - params[0].value).toFixed(2);
      let itemData = JSON.parse(params[0].name);

      return `
        <div class="tooltip-date">${itemData.time}</div>
        <div class="tooltip">
          <img src="${itemData.cover_url}" />
          
          <div class="tooltip-info">
            <div class="tooltip-info_desc tooltip-info_item">
              <div class="item-text">
                <i style="background-color: ${params[0].color};" class="item-icon"></i>
                <div>${itemData.title}</div>  
              </div>
              <div class="item-value">${params[0].value}</div>
            </div>  
            <div class="tooltip-info_check tooltip-info_item">
              <div class="item-text">${checkStr}</div>
              <div class="item-value">${checkNum}</div>
            </div>
          </div>
        </div>
      `;
    },

    setChartOptions() {
      if (!this.$refs.chart) return;

      const data = this.performanceData.data;
      const avgNum = this.performanceData.avg_num;
      const dataNum = data.map((item) => item.num);
      let zoomEnd = 100;
      if (dataNum.length > 0) {
        zoomEnd = dataNum.length > 50 ? (50 * 100) / dataNum.length : 100;
      }
      console.log("zoomEnd: ", zoomEnd);

      const chart = this.$echarts.init(this.$refs.chart);
      const options = {
        grid: {
          left: "0%",
          right: "1%",
          top: "10%",
          bottom: "35",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis", // 悬停时触发
          axisPointer: { type: "shadow" }, // 指示器类型为阴影
          formatter: (params) => {
            // return `
            //   <div style="color: '#86909C';">${JSON.parse(params[0].name).title}</div>
            //   <div style="color: #000;">${this.getTypeLabel}: <span style="color: ${params[0].color}; font-size: 20px;">${
            //   params[0].value
            // }</span></div>
            // `;
            return this.formatterTooltip(params);
          },
        },
        legend: {
          top: 0,
          right: 0,
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontSize: 10,
            color: "#333",
          },
          selectedMode: false,
          data: [
            {
              name: this.getTypeLabel,
              show: true,
              orient: "horizontal",
              icon: "circle",
            },
            {
              name: "均值",
              show: true,
              orient: "horizontal",
              icon: "circle",
            },
          ],
        },
        xAxis: {
          type: "category",
          data: data.map((item) => JSON.stringify(item)),
          axisLabel: {
            color: "#86909C",
            formatter: (params) => {
              const json = JSON.parse(params);
              const length = this.getDisplayLength(json.title);
              let titleStr = json.title.slice(0, length);
              if (length < json.title.length) titleStr += "...";

              return `${json.time}\n${titleStr}`;
            },
          },
          axisLine: { lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "86909C", formatter: "{value}" },
          axisLine: { show: true, lineStyle: { color: "#C9CDD4" } },
          splitLine: { lineStyle: { type: "dashed" } },
        },
        series: [
          {
            name: this.getTypeLabel,
            type: "bar",
            data: data.map((item) => item.num),
            itemStyle: {
              color: "#594fee",
              borderRadius: [4, 4, 0, 0],
            },
            barWidth: 24,
          },
          {
            name: "均值",
            type: "line",
            data: data.map((item) => avgNum),
            itemStyle: {
              color: "#4CBCF5",
            },
            markLine: {
              symbol: ["none", "none"], // 设置起点和终点都没有箭头
              lineStyle: {
                width: 2, // 设置统一的线宽
                type: "solid", // 确保线是实线
              },
              data: [
                {
                  name: "均值",
                  yAxis: avgNum,
                  lineStyle: {
                    color: "#4CBCF5",
                    type: "solid",
                  },
                  label: {
                    show: false,
                  },
                },
              ],
            },
            symbol: "none", // 隐藏数据点
          },
        ],
        dataZoom: [
          {
            type: "slider", // 横向滚动条
            start: 0, // 默认起始显示百分比
            end: zoomEnd, // 默认结束显示百分比
            bottom: "16",
            height: 16,

            labelFormatter: (value, valueStr) => {
              // 自定义悬浮提示文字-同x轴格式
              // const params = JSON.parse(valueStr);
              // const length = this.getDisplayLength(params.title);
              // let titleStr = params.title.slice(0, length);
              // if (length < params.title.length) titleStr += "...";

              // return `${params.time}\n${titleStr}`;

              // 隐藏
              return "";
            },
          },
        ],
      };
      chart.setOption(options);

      window.addEventListener("resize", () => chart.resize());
    },

    exportData() {
      let params = {
        id: this.filterAccounts.toString(),
        date: this.filterTime.toString(),
        token: localStorage.getItem("studentToken"),
        responseType: "arraybuffer",
      };
      // if (this.filterAccounts.length == 0) params.id = this.allAccounts.toString();
      if (params.id == "" || params.date == "") return;

      this.$httpStudent.axiosSetTypeGet(this.$api.dateCenterContentAnalysisExport, params, "arraybuffer", (res) => {
        const blob = new Blob([res], { type: ".xlsx" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "投稿预览数据.xlsx"; // 设置文件名
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },

    exportChartData() {
      // dataCenterContentAnalysisTrendExport
      let params = {
        id: this.filterAccounts.toString(),
        date: this.filterTime.toString(),
        token: localStorage.getItem("studentToken"),
      };
      // if (this.filterAccounts.length == 0) params.id = this.allAccounts.toString();
      if (params.id == "" || params.date == "") return;

      this.$httpStudent.axiosSetTypeGet(this.$api.dataCenterContentAnalysisTrendExport, params, "arraybuffer", (res) => {
        const blob = new Blob([res], { type: ".xlsx" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "投稿表现数据.xlsx"; // 设置文件名
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.posts-analysis {
  padding: 24px 0 0 0;

  .item-header {
    &_operation {
      ::v-deep .el-button {
        padding: 6px 12px;
        background-color: #f2f2f4;
        border: none;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #777880;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }

  .item-content {
    background-color: #f2f2f4;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 24px;
    border: 1px solid #f2f2f4;
    border-bottom: none;
    border-right: none;

    .preview-button {
      width: 20%;
      height: 90px;
      box-sizing: border-box;
      background-color: #f9f9fa;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-inline: 16px;
      border-bottom: 1px solid #f2f2f4;
      border-right: 1px solid #f2f2f4;
      cursor: pointer;

      &_title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #252632;
        line-height: 16px;
        text-align: left;
        font-style: normal;

        .el-icon-question {
          margin-left: 3px;
          font-size: 13px;
          line-height: 14px;
          font-style: normal;
          color: #bbbbbd;
          cursor: pointer;
        }
      }

      &_data {
        font-family: Douyin Sans, Douyin Sans;
        font-weight: bold;
        font-size: 20px;
        color: #252632;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      &.is-active {
        background-color: #fff;
        border-top: 2px solid #594fee;
      }
    }
  }

  .performance-content {
    margin-top: 20px;
    height: 455px;

    .item-brief-info {
      background-color: #f9f9fa;
      border-radius: 4px;
      padding: 16px 18px;
      display: flex;
      align-items: center;
      gap: 10px;

      &_type {
        display: flex;
        align-items: center;
        gap: 3px;
        flex-shrink: 0;
        i {
          font-size: 20px;
          color: #594fee;
          border-radius: 3px;
        }

        span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #594fee;
          line-height: 19px;
          font-style: normal;
        }
      }

      .imp-text {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        max-width: 41.5%;
        color: rgba(37, 38, 50, 0.6);
        text-align: left;
        font-style: normal;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        white-space: nowrap;

        span.data {
          color: #252632;
          font-weight: 600;
        }

        span.tip {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .performance-chart {
      // height: calc(100% - 60px);
      margin-top: 12px;
      position: relative;

      ::v-deep .el-input__prefix {
        position: relative;
        left: 0;
        box-sizing: border-box;
        // border: 1px solid black;
        color: black;
        height: 25px;
        line-height: 25px;
        cursor: pointer;
      }

      ::v-deep .el-input__suffix {
        margin-left: 3px;
        position: relative !important;
      }

      ::v-deep .el-select {
        position: absolute;
        top: 10px;
        left: 20px;
        z-index: 10;
      }

      ::v-deep .el-input__inner {
        width: max-content;
        width: 0;
        height: 25px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #252632;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        border: none;
        padding: 0;
      }

      ::v-deep .el-input__icon {
        line-height: 25px;
      }

      .chart {
        width: 100%;
        height: 368px;
        padding: 16px;
        // margin-top: 10px;
        border: 1px solid #f2f2f4;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
      }
    }

    &.empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      span {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: rgba(28, 31, 35, 0.55);
        line-height: 16px;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}

// tooltip - echarts
::v-deep(.tooltip-date) {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #777880;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

::v-deep(.tooltip) {
  display: flex;
  gap: 5px;
  margin-top: 5px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #252632;
  line-height: 16px;
  font-style: normal;
  text-transform: none;
  img {
    width: 75px;
    height: 110px;
    border-radius: 6px;
    object-fit: cover;
  }

  .tooltip-info {
    width: 200px;

    &_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      margin-bottom: 5px;

      .item-text {
        width: 70%;
        & > div {
          width: 100%;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 隐藏超出部分 */
          text-overflow: ellipsis; /* 显示省略号 */
        }

        display: flex;
        align-items: center;
        gap: 5px;

        .item-icon {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
    }

    .tooltip-info_desc {
      font-weight: 600;
    }

    .tooltip-info_check {
      margin-left: 11px;
      font-size: 12px;
      color: #777880;
    }
  }
}
</style>
