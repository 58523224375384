<template>
    <div class="details">
        <div class="details-top">
            <el-button class="blue-btn" @click="toBack">返回</el-button>
        </div>
        <div class="details-bottom" style="display: flex;justify-content: start">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="数据概况" name="first" lazy>
                    <DataOverviewModule ref="DataOverviewModule" v-if="activeName == 'first'"/>
                </el-tab-pane>
                <el-tab-pane label="粉丝数据分析" name="second" lazy>
                    <FanDataModule ref="FanDataModule" v-if="activeName == 'second'"/>
                </el-tab-pane>
                <el-tab-pane label="播主视频" name="third" lazy>
                    <BroadcasterVideoModule ref="BroadcasterVideoModule" v-if="activeName == 'third'"/>
                </el-tab-pane>
                <el-tab-pane label="直播数据" name="fourth" lazy>
                    <LiveModule ref="LiveModule" v-if="activeName == 'fourth'"/>
                </el-tab-pane>
            </el-tabs>
            <div class="update-content">
                <span @click="updateBtn" class="update-title"><i class="iconfont">&#xe64d;</i>更新数据</span>
            </div>
            <MineModule @selectAccountUpdate="selectAccountUpdate" ref="MineModule"/>
        </div>
    </div>
</template>

<script>
    import DataOverviewModule from '../../../components/TeacherModule/DataOverviewModule.vue'
    import MineModule from '../../../components/TeacherModule/MineModule.vue'
    import FanDataModule from '../../../components/TeacherModule/FanDataModule.vue'
    import BroadcasterVideoModule from '../../../components/TeacherModule/BroadcasterVideoModule.vue'
    import LiveModule from "../../../components/TeacherModule/LiveModule";

    export default {
        name: "DataDetails",
        components: {
            DataOverviewModule,
            MineModule,
            FanDataModule,
            BroadcasterVideoModule,
            LiveModule,
        },
        data() {
            return {
                activeName: 'first',
                accountID: '',//账户id
            }
        },
        mounted() {
            // let name = localStorage.getItem('currentTab')
            // 判断是否存在currentTab，即tab页之前是否被点击切换到别的页面
            // if(name){
            //     this.activeName = name
            // }
            this.accountID = this.$route.query.id//从路由获取id
        },
        methods: {
            handleClick(tab) {
                // 点击tab后触发事件，修改显示页面，将状态保存在localStorage里面
                localStorage.setItem('currentTab', tab.name)
            },
            toBack() {
                this.$router.push('/teacher/account/details')
            },
            updateBtn() {
                if (this.activeName !== 'third') {
                    if (this.activeName === 'first') {
                        this.$nextTick(() => {
                            this.$refs.DataOverviewModule.getData();
                        })
                    }
                    if (this.activeName === 'second') {
                        this.$nextTick(() => {
                            this.$refs.FanDataModule.getData();
                        })
                    }
                    if (this.activeName === 'fourth') {
                        this.$nextTick(() => {
                            this.$refs.LiveModule.getData();
                        })
                    }
                    this.accountID = this.$route.query.id//从路由获取id
                    this.$nextTick(() => {
                        this.$refs.MineModule.getAccountMine(1, 1, this.accountID);
                    })
                } else {
                    this.$message.warning('请前往创作中心->视频管理->选择账号->点击更新列表按钮')
                }
            },
            selectAccountUpdate(id) {
                if (this.activeName === 'first') {
                    this.$refs.DataOverviewModule.getData();
                }
                if (this.activeName === 'second') {
                    this.$refs.FanDataModule.getData();
                }
                if (this.activeName === 'third') {
                    this.$refs.BroadcasterVideoModule.getMyVideoList();
                }
                if (this.activeName === 'fourth') {
                    this.$refs.LiveModule.getData();
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .details {
        display: flex;
        flex-direction: column;
        height: 100%;

        ::v-deep .el-tabs {
            box-shadow: none;
            border: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            background: transparent;
            flex: 1;
            width: 1%;
            min-height: 810px;
        }

        ::v-deep .el-tabs__header {
            border-bottom: none;
            margin: 0;
        }

        ::v-deep .el-tabs__content {
            flex: 1;
            background: #fff;
            padding-top: 20px;
        }

        .el-tab-pane {
            height: 100%;
        }

        ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
            background: #fff;
        }

        ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
            border-left: none;
        }

        ::v-deep .el-tabs__header .is-active {
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            color: #0824ce;
            border-radius: 6px 6px 0 0;
        }

        ::v-deep .el-tabs__item {
            height: 52px;
            line-height: 52px;
        }

        ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
            border: none;
        }

        ::v-deep .el-tabs__item:hover {
            color: #0824ce;
            cursor: pointer;
        }

        .details-top {
            display: flex;
            justify-content: flex-end;

            .blue-btn {
                width: 80px;
                height: 32px;
                border-radius: 10px;
                line-height: 8px;
            }
        }

        .details-bottom {
            position: relative;
            margin-top: 25px;

            .update-content {
                height: 20px;
                position: absolute;
                top: 14px;
                right: 378px;

                .update-title {
                    margin: 0 10px;
                    font-size: 14px;
                    cursor: pointer;

                    i {
                        color: #0824ce;
                        margin-right: 10px;
                    }

                    &:hover {
                        color: #1D33E1;
                    }
                }
            }
        }
    }
</style>