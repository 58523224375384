<template>
    <div class="account-content">
        <div class="head-box">
            <div class="left-box">
                <img class="select-img" :src="select_img" alt="">
                <el-select ref="select" v-model="value" placeholder="请选择" @change="changeSelection"
                           :popper-append-to-body="false">
                    <el-option
                            v-for="item in studentList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        <img :src="item.head_portrait ===''?blank_headPic:item.head_portrait"
                             alt="" width="30"
                             height="30">
                        <span>{{item.name}}</span>
                    </el-option>
                </el-select>
            </div>
            <div class="right-box">
                <el-button class="purple-btn" @click="goBack">返回</el-button>
            </div>
        </div>
        <div class="account-info-box">
            <div class="head-time">
                数据统计时间：{{today}}
                <span @click="getDate" class="update-title"><i class="iconfont">&#xe64d;</i></span>
            </div>
            <div class="main-content">
                <div class="left-box">
                    <div class="top-box">
                        <div class="head-pic">
                            <img :src="studentInfo.head_portrait ===''?blank_headPic:studentInfo.head_portrait" alt="">
                        </div>
                        <div class="head-info">
                            <div class="account-name">{{studentInfo.name}}</div>
                            <div class="account-phone">班级：{{className}}</div>
                        </div>
                    </div>
                    <!-- <div class="bottom-box">
                        <div class="icon-box" @click="viewReport">
                            <i class="iconfont">&#xe791;</i>
                        </div>
                        <div class="bottom-text" @click="viewReport">能力报告</div>
                    </div> -->
                </div>
                <div class="middle-box">
                    <div class="info-box" v-for="(item,index) in infoList" :key="index">
                        <div class="top-box">
                            <div class="icon-box">
                                <i class="iconfont" v-html="item.icon" :style="{color:item.color}"></i>
                            </div>
                            <div class="top-title">
                                {{item.title}}
                            </div>
                            <div class="top-question">
                                <el-popover
                                        placement="top"
                                        trigger="hover"
                                        popper-class="popover-box"
                                        :content="item.description">
                                    <i class="el-icon-question" slot="reference" v-if="item.description"></i>
                                </el-popover>
                            </div>
                        </div>
                        <div class="bottom-box">{{item.content ===''?'0':item.content}}</div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="right-main-content">
                        <div class="right-box-item">
                            <div class="item-top">
                                完成企业任务
                            </div>
                            <div class="item-bottom">
                                {{studentInfo.qyrw_count}}
                            </div>
                        </div>
                        <div class="right-box-item">
                            <div class="item-top">
                                完成教师任务
                            </div>
                            <div class="item-bottom">
                                {{studentInfo.jsrw_count}}
                            </div>
                        </div>
                    </div>
                    <div class="right-bottom">
                        <el-button class="purple-btn" @click="gotoDetail">查看详情</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-item-fan">
            <div class="item-half-sub">总粉丝数</div>
            <div class="left-item-content">
                <div class="fan-tab">
                    <div class="item left" :class="{current: fanTabIndex === 0}" @click="toggleFanTab(0)">7天
                    </div>
                    <div class="item right" :class="{current: fanTabIndex === 1}" @click="toggleFanTab(1)">
                        30天
                    </div>
                </div>
                <div id="fansGrowth" :style="{width: '100%', height: '350px',position:'relative'}"></div>
            </div>
        </div>
        <div class="left-item-fan">
            <div class="item-half-sub">总点赞数</div>
            <div class="left-item-content">
                <div class="fan-tab">
                    <div class="item left" :class="{current: dzTabIndex === 0}" @click="toggleLikeTab(0)">7天</div>
                    <div class="item right" :class="{current: dzTabIndex === 1}" @click="toggleLikeTab(1)">30天</div>
                </div>
                <div id="dzGrowth" :style="{width: '100%', height: '350px',position:'relative'}"></div>
            </div>
        </div>
        <div class="left-item-fan">
            <div class="item-half-sub">用户数据</div>
            <div class="item-content">
                <div class="left_box">
                    <div class="gender-box">
                        <span class="title">性别分布</span>
                        <div class="gender-chart">
                            <div class="bottom-box">
                                <div class="box1">
                                    <span>{{woman}}%</span>
                                </div>
                                <div class="box1">
                                    <span>{{man}}%</span>
                                </div>
                            </div>
                            <div class="chart-content">
                                <div class="women" :style="{width: woman+'%'}"></div>
                                <div class="man" :style="{width: man+'%'}"></div>
                            </div>
                            <div class="icon-box">
                                <div class="women-box">
                                    <i class="iconfont">&#xe6a8;</i>
                                    <div class="icon-title">女性用户</div>
                                </div>
                                <div class="man-box">
                                    <i class="iconfont">&#xe6a6;</i>
                                    <div class="icon-title">男性用户</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right_box">
                    <div class="age-box">
                        <div class="title">
                            <span>年龄分布</span>
                        </div>
                        <div class="area-distribution">
                            <div id="ageDistribution" style="width: 100%;height: 275px;position: relative"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-item-fan">
            <div class="item-half-sub">直播数据</div>
            <div class="left-item-content">
                <div class="fan-tab">
                    <div class="item left" :class="{current: liveTabIndex === 0}" @click="toggleLiveTab(0)">7天</div>
                    <div class="item right" :class="{current: liveTabIndex === 1}" @click="toggleLiveTab(1)">30天</div>
                </div>
                <div id="liveData" :style="{width: '100%', height: '350px',position:'relative'}"></div>
            </div>
        </div>
        <div class="left-item-fan end">
            <div class="item-half-sub">账号数据列表</div>
            <div class="left-item-content">
                <el-table
                        :data="tabelList"
                        class="college-table"
                        height="780"
                        size="medium"
                        :header-cell-style="{fontWeight: '400', color: '#333', background: '#F8F8FD',height:'50px'}"
                        :cell-style="{fontSize: '16px',color: '#333',fontWeight: 'bold'}"
                        :row-style="{height:'100px'}">
                    <el-table-column prop="platform_type" label="平台" align="left" width="200"
                                     class-name="platform-head">
                        <template slot-scope="scope">
                            <div class="account-number platform">
                                <img :src="scope.row.platform_type===1?require('../../../assets/img/image/douyin.png'):require('../../../assets/img/image/baijiahao.png')">
                                <span class="scope-title">{{scope.row.platform_type===1?'抖音号':'百家号'}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="user_name"
                            label="账号信息"
                            align="left"
                            width="500"
                            class-name="student-name"
                    >
                        <template slot-scope="scope">
                            <div class="account-number">
                                <img :src="scope.row.avatar === null?require('../../../assets/img/image/blank_headPic.png'):scope.row.avatar"
                                     alt="" width="30"
                                     height="30">
                                <span class="scope-title">{{scope.row.nickname}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="follower_count" label="粉丝数" align="center"
                                     class-name="student-name">
                        <template slot-scope="scope">
                            <span class="student-name-title">{{scope.row.follower_count}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="aweme_count" label="作品总数" align="center"
                                     class-name="student-name">
                        <template slot-scope="scope">
                            <span class="student-name-title"
                                  v-if="scope.row.type === 1">{{scope.row.aweme_count}}</span>
                            <span class="student-name-title"
                                  v-else>暂无</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="favoriting_count" label="点赞总数" align="center"
                                     class-name="student-name">
                        <template slot-scope="scope">
                            <span class="student-name-title"
                                  v-if="scope.row.type === 1">{{scope.row.favoriting_count}}</span>
                            <span class="student-name-title"
                                  v-else>暂无</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="icon-box">
                                <div class="icon-content" @click="gotoData(scope.row)">
                                    <i class="iconfont">&#xe676;</i>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        class="circle-page"
                        :current-page="currentPageNum"
                        :page-size="eachPageNum"
                        layout="prev, pager, next, total"
                        :total="total"
                        @current-change="classCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapMutations} from 'vuex';
    import {mapGetters} from "vuex";
    import echarts from "echarts"

    export default {
        name: "accountDetail",
        data() {
            return {
                accountInfo: {},
                studentList: [],
                value: '',
                infoList: [
                    {
                        icon: '&#xe699;',
                        color: '#FF6D80',
                        title: '粉丝数',
                        content: ''
                    },
                    {
                        icon: '&#xe7fd;',
                        color: '#5F55F1',
                        title: '作品总数',
                        content: ''
                    },
                    {
                        icon: '&#xe685;',
                        color: '#04A8FF',
                        title: '点赞总数',
                        content: ''
                    },
                    {
                        icon: '&#xe684;',
                        color: '#9ACC5B',
                        title: '平均点赞',
                        content: '',
                        description: '近15天内作品的点赞数/作品数',
                    },
                    {
                        icon: '&#xe806;',
                        color: '#4588F9',
                        title: '平均评论',
                        content: ''
                    },
                    {
                        icon: '&#xe627;',
                        color: '#FFB624',
                        title: '平均转发',
                        content: ''
                    },
                ],
                today: '',
                fanTabIndex: 0,
                dzTabIndex: 0,
                liveTabIndex: 0,
                areaData: [],
                man: 0,
                woman: 0,
                account_id: '',
                class_id: '',
                select_img: '',
                blank_headPic: require('../../../assets/img/image/blank_headPic.png'),
                studentInfo: {},
                className: '',
                accountList: [],
                FansTrend: [],
                likesTrend: [],
                liveTrend: [],
                genderData: [],
                age: [],
                tabelList: [],
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
                //直播没有数据提示
                LiveTrendNoPrompt: null,
            }
        },
        mounted() {
            this.getParam();
            this.getDate();
        },
        computed: {
            ...mapGetters(['getAccountInfo'])
        },
        methods: {
            ...mapMutations(['setStudentInfo', 'setAccountInfo']),
            getParam() {
                this.accountInfo = this.getAccountInfo;
                this.account_id = this.accountInfo.account_id;
                this.class_id = this.accountInfo.class_id;
                this.className = this.accountInfo.class_name;
                this.value = JSON.parse(this.account_id);
            },
            getDate() {
                let myDate = new Date();
                this.today = this.formatDate(myDate.getTime());
                this.changeSelection(this.account_id);
                this.getStudentInfo(this.class_id);
            },
            formatDate(date) {
                var date = new Date(date);
                var YY = date.getFullYear() + '-';
                var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
                var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                return YY + MM + DD + " " + hh + mm + ss;
            },
            classCurrentChange(val) {
                this.currentPageNum = val;
                this.getTableList();
            },
            getData(id) {
                let param = {
                    user_id: id,
                }
                this.$httpteacher.axiosGetBy(this.$api.account_userAccount, param, res => {
                    if (res.code == 200) {
                        this.infoList[3].content = res.data.avg_likes;
                        this.infoList[4].content = res.data.avg_comment;
                        this.infoList[5].content = res.data.avg_forward;
                        this.FansTrend = res.data.history_fans;
                        this.likesTrend = res.data.history_likes;
                        this.liveTrend = res.data.live_data;
                        this.genderData = res.data.gender_distribution;
                        this.man = this.genderData[1].proportion;
                        this.woman = this.genderData[2].proportion;
                        // 年龄段
                        this.age = res.data.age_distribution
                        this.ageDistributionFun(this.age);
                        this.fanTrendFun(this.FansTrend, 0);
                        this.likeTrendFun(this.likesTrend, 0);
                        this.liveTrendFun(this.liveTrend, 0);
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            getStudentInfo(id) {
                let param = {
                    class_id: id,
                    type: 'all'
                }
                this.$httpteacher.axiosGetBy(this.$api.account_userListStatistics, param, res => {
                    if (res.code == 200) {
                        this.studentList = res.data;
                        this.getSingleStu();
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            getTableList() {
                let param = {
                    paging: 1,
                    user_id: this.account_id,
                    page: this.currentPageNum,
                    pageSize: this.eachPageNum
                }
                this.$httpteacher.axiosGetBy(this.$api.get_account, param, res => {
                    if (res.code == 200) {
                        this.tabelList = res.data.data;
                        this.total = res.data.total;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                })
            },
            getSingleStu() {
                for (let index in this.studentList) {
                    let obj = this.studentList[index];
                    if (obj.id == this.account_id) {
                        this.studentInfo = obj;
                        this.select_img = obj.head_portrait === '' ? this.blank_headPic : obj.head_portrait
                    }
                }
                this.infoList[0].content = this.studentInfo.fans_count;
                this.infoList[1].content = this.studentInfo.zp_count;
                this.infoList[2].content = this.studentInfo.dz_count;
            },
            changeSelection(e) {
                this.follower_data = [];
                let obj = {
                    account_id: e,
                    class_id: this.class_id,
                    class_name: this.className
                }
                this.setAccountInfo(obj);
                this.getData(e);
                this.account_id = e;
                this.getSingleStu();
                this.getTableList();
                for (let index in this.studentList) {
                    let obj = this.studentList[index];
                    if (obj.id == e) {
                        this.select_img = obj.head_portrait === '' ? this.blank_headPic : obj.head_portrait;
                    }
                }
            },
            goBack() {
                this.$router.push('/teacher/studentmang/index')
            },
            viewReport() {
                this.$message.warning('功能暂未开放')
            },
            gotoDetail() {
                this.$message.warning('功能暂未开放')
                // this.setStudentInfo(this.studentInfo)
                // this.$router.push('/teacher/account/task')
            },
            toggleFanTab(index) {
                this.fanTabIndex = index;
                this.fanTrendFun(this.FansTrend, index);
            },
            toggleLikeTab(index) {
                this.dzTabIndex = index;
                this.likeTrendFun(this.likesTrend, index);
            },
            toggleLiveTab(index) {
                this.liveTabIndex = index;
                this.liveTrendFun(this.liveTrend, index);
            },
            // 粉丝趋势
            fanTrendFun(fansTrendArry, type) {
                if (fansTrendArry.length == 0) {
                    let div1 = document.createElement("div");
                    div1.innerHTML = "暂无数据";
                    div1.className = 'no-data';
                    div1.style.position = "absolute";
                    div1.style.top = "100px";
                    div1.style.left = "50%";
                    document.querySelector("#fansGrowth").appendChild(div1);
                    return false
                }
                // 时间和数据
                let time = [],
                    data = [],
                    arr = [],
                    newArray = [];
                if (type === 0) {
                    arr = fansTrendArry.slice(-7)
                } else {
                    arr = fansTrendArry.slice(-30)
                }
                for (let i in arr) {
                    for (let j in arr[i]) {
                        let obj = {
                            timeNode: j,
                            value: arr[i][j]
                        };
                        newArray.push(obj)
                    }
                }
                for (let j in newArray) {
                    time.push(newArray[j].timeNode);
                    data.push(newArray[j].value);
                }
                let fanTrend = this.$echarts.init(document.getElementById("fansGrowth"));
                fanTrend.setOption({
                    title: {
                        x: "center",
                        y: "bottom",
                        textStyle: {
                            //设置主标题风格
                            Color: "#999", //设置主标题字体颜色
                            fontSize: 12,
                            fontStyle: "normal"
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        padding: 0,
                        axisPointer: {
                            type: 'line',
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                color: '#e0e2eb'
                            }
                        },
                        formatter: function (params) {
                            let name = params[0].name;
                            let date = params[0].data;
                            let str = '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + '粉丝总数：' + '</span> <sapn class="tooltip-title-right red"> ' + date + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                            return str;
                        }
                        // formatter: '{b0}<br />粉丝总数 : {c0}'
                    },
                    xAxis: {
                        type: "category",
                        data: time,
                        //设置网格线颜色
                        boundaryGap: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#999"
                            }
                        }
                    },
                    yAxis: {
                        type: "value",
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#f1f1f1"]
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#999"
                            }
                        },
                        axisLabel: {
                            fontSize: "12",
                            marginTop: "35px",
                            formatter: "{value}",
                            show: true
                        }
                    },
                    series: [
                        {
                            data: data,
                            type: "line",
                            lineStyle: {
                                color: "#F5697B",
                                width: 3
                            },
                            smooth: true,
                            symbol: 'circle',
                            showSymbol: false, //关闭线上默认的圆点
                            symbolSize: 5, //设置圆点的大小
                            itemStyle: {
                                emphasis: { // 鼠标经过时：
                                    color: '#F5697B',
                                    borderColor: '#ffffff',
                                    borderWidth: 4,
                                    shadowColor: 'rgba(133,127,221,1)',
                                },
                                normal: {
                                    shadowBlur: 30,
                                }
                            },
                        }
                    ]
                }, true);
            },
            likeTrendFun(likesTrendArry, type) {
                if (likesTrendArry.length == 0) {
                    let div1 = document.createElement("div");
                    div1.innerHTML = "暂无数据";
                    div1.className = 'no-data';
                    div1.style.position = "absolute";
                    div1.style.top = "100px";
                    div1.style.left = "50%";
                    document.querySelector("#dzGrowth").appendChild(div1);
                    return false
                }
                // 时间和数据
                let time = [],
                    data = [],
                    arr = [],
                    newArray = [];
                if (type === 0) {
                    arr = likesTrendArry.slice(-7)
                } else {
                    arr = likesTrendArry.slice(-30)
                }
                for (let i in arr) {
                    for (let j in arr[i]) {
                        let obj = {
                            timeNode: j,
                            value: arr[i][j]
                        };
                        newArray.push(obj)
                    }
                }
                for (let j in newArray) {
                    time.push(newArray[j].timeNode);
                    data.push(newArray[j].value);
                }
                let likesTrend = this.$echarts.init(document.getElementById("dzGrowth"));
                likesTrend.setOption({
                    title: {
                        x: "center",
                        y: "bottom",
                        textStyle: {
                            //设置主标题风格
                            Color: "#999", //设置主标题字体颜色
                            fontSize: 12,
                            fontStyle: "normal"
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        padding: 0,
                        axisPointer: {
                            type: 'line',
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                color: '#e0e2eb'
                            }
                        },
                        formatter: function (params) {
                            let name = params[0].name;
                            let date = params[0].data;
                            let str = '<div class="tooltip">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + '点赞总数：' + '</span> <sapn class="tooltip-title-right blue"> ' + date + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                            return str;
                        }
                        // formatter: '{b0}<br />粉丝总数 : {c0}'
                    },
                    xAxis: {
                        type: "category",
                        data: time,
                        //设置网格线颜色
                        boundaryGap: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#999"
                            }
                        }
                    },
                    yAxis: {
                        type: "value",
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ["#f1f1f1"]
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#999"
                            }
                        },
                        axisLabel: {
                            fontSize: "12",
                            marginTop: "35px",
                            formatter: "{value}",
                            show: true
                        }
                    },
                    series: [
                        {
                            data: data,
                            type: "line",
                            lineStyle: {
                                color: "#5F55F1",
                                width: 3
                            },
                            smooth: true,
                            symbol: 'circle',
                            showSymbol: false, //关闭线上默认的圆点
                            symbolSize: 5, //设置圆点的大小
                            itemStyle: {
                                emphasis: { // 鼠标经过时：
                                    color: '#5F55F1',
                                    borderColor: '#ffffff',
                                    borderWidth: 4,
                                    shadowColor: 'rgba(133,127,221,1)',
                                },
                                normal: {
                                    shadowBlur: 30,
                                }
                            },
                        }
                    ]
                }, true);
            },
            liveTrendFun(liveTrendArry, type) {
                if (liveTrendArry.length == 0) {
                    let div1 = document.createElement("div");
                    div1.innerHTML = "暂无数据";
                    div1.className = 'no-data';
                    div1.style.position = "absolute";
                    div1.style.top = "100px";
                    div1.style.left = "50%";
                    document.querySelector("#liveData").appendChild(div1);
                    return false
                }
                let arr1 = Object.entries(liveTrendArry.live_number_pviewers);
                let arr2 = Object.entries(liveTrendArry.live_avg_viewing_time);
                let newArr1 = [],
                    newArr2 = [],
                    time = [],
                    data1 = [],
                    data2 = [];
                if (type === 0) {
                    newArr1 = arr1.slice(-7);
                    newArr2 = arr2.slice(-7);
                } else {
                    newArr1 = arr1.slice(-30);
                    newArr2 = arr2.slice(-30);
                }
                for (let i in newArr1) {
                    time.push(newArr1[i][0]);
                    data1.push(newArr1[i][1]);
                }
                for (let j in newArr2) {
                    data2.push(newArr2[j][1]);
                }
                let liveTrend = this.$echarts.init(document.getElementById("liveData"));
                liveTrend.setOption({
                    title: {
                        x: 'center',
                        y: 'bottom',
                        textStyle: { //设置主标题风格
                            Color: '#333333',//设置主标题字体颜色
                            fontSize: 14,
                            fontStyle: 'normal',
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        padding: 0,
                        axisPointer: {
                            type: 'line',
                            lineStyle: {
                                type: 'solid',
                                width: 1,
                                color: '#e0e2eb'
                            }
                        },
                        formatter: function (params) {
                            let name = params[0].name;
                            let data1 = params[0].data;
                            let data2 = params[1].data;
                            let str = '<div class="tooltip double">\
                            <div class="tooltip-box">\
                            <div class="tooltip-title">' + name + '</div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + '观看人数：' + '</span> <sapn class="tooltip-title-right purple"> ' + data1 + ' </sapn>\
                            </div>\
                            <div class="tooltip-content">\
                            <span class="tooltip-title-left">' + '人均观看时长：' + '</span> <sapn class="tooltip-title-right purple"> ' + data2 + ' </sapn>\
                            </div>\
                            </div>\
                            </div>';
                            return str;
                        }
                    },
                    legend: {
                        show: true,
                        selectedMode: 'multiple',    //设置显示单一图例的图形，点击可切换
                        bottom: 0,
                        left: 700,
                        textStyle: {
                            color: '#666',
                            fontSize: 12
                        },
                        itemGap: 20,
                        data: ['观看人数', '人均观看时长'],
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: time,
                            boundaryGap: true,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#999999'
                                }
                            },
                        }
                    ],

                    yAxis: [
                        {
                            type: 'value',
                            //隐藏刻度线
                            axisTick: {
                                show: false
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: ['#f1f1f1'],
                                }
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#999999'
                                }
                            },
                            axisLabel: {
                                fontSize: '14',
                                marginTop: '35px',
                                formatter: '{value}',
                                show: true,
                            },
                        },
                        {
                            type: 'value',
                            //隐藏刻度线
                            axisTick: {
                                show: false
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: ['#f1f1f1'],
                                }
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#999999'
                                }
                            },
                            axisLabel: {
                                fontSize: '14',
                                marginTop: '35px',
                                formatter: '{value}',
                                show: true,
                            },
                        }
                    ],

                    series: [
                        {
                            name: '观看人数',
                            type: 'bar',
                            data: data1,
                            barWidth: '23px',
                            itemStyle: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#009CED'},
                                        {offset: 0.5, color: '#188df0'},
                                        {offset: 1, color: '#564CED'}
                                    ]
                                ),
                                barBorderRadius: [5, 5, 0, 0]
                            },
                        },
                        {
                            name: '人均观看时长',
                            data: data2,
                            yAxisIndex: '1',
                            type: "line",
                            lineStyle: {
                                color: "#5F55F1",
                                width: 3
                            },
                            smooth: true,
                            symbol: 'circle',
                            showSymbol: true, //关闭线上默认的圆点
                            symbolSize: 8, //设置圆点的大小
                            itemStyle: {
                                emphasis: { // 鼠标经过时：
                                    color: '#5F55F1',
                                    borderColor: '#ffffff',
                                    borderWidth: 4,
                                    shadowColor: 'rgba(133,127,221,1)',
                                },
                                normal: {
                                    color: '#5F55F1',
                                }
                            },
                        },
                    ]
                })
            },
            ageDistributionFun(array) {
                let newArr = [];
                for (let i in array) {
                    for (let j in array[i]) {
                        let obj = {
                            item: j,
                            value: array[i][j]
                        };
                        newArr.push(obj)
                    }
                }
                //年龄段数据处理
                let noDataDiv = document.querySelector('.no-data');
                if (noDataDiv !== null) {
                    document.querySelector("#ageDistribution").removeChild(noDataDiv);
                }
                if (document.getElementById('ageDistribution')) {
                    let ageArray = [],
                        dataArray = [],
                        totalNum = 0;
                    if (array.length > 0) {
                        for (let i in newArr) {
                            totalNum += Number(newArr[i].value)
                        }
                        for (let j in newArr) {
                            ageArray.push(newArr[j].item);
                            let obj = {
                                value: Number(this.getPercent(newArr[j].value, totalNum, false)),
                                name: newArr[j].item
                            }
                            dataArray.push(obj)
                        }
                    }
                    ageArray.sort();
                    let _this = this;
                    this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
                    this.ageDistribution.setOption({
                        tooltip: { //提示框组件
                            trigger: 'item', //触发类型(饼状图片就是用这个)
                            formatter: function (params) {
                                return params.seriesName + '<br/>' + params.name + ':' + params.value + '%'
                            }
                        },
                        color: ['#F5AF23', '#9ECB59', '#5F55F1', '#F5697B', '#C3C3F4'],
                        legend: {
                            padding: [0, 60, 0, 0],
                            orient: 'horizontal',  //布局  纵向布局 图例标记居文字的左边 vertical则反之
                            width: 40,      //图行例组件的宽度,默认自适应
                            x: 'right',   //图例显示在右边
                            y: 'bottom',   //图例在垂直方向上面显示居中
                            itemWidth: 10,  //图例标记的图形宽度
                            itemHeight: 10, //图例标记的图形高度
                            data: ageArray,
                            textStyle: {
                                color: '#000',
                                fontSize: 14,
                                lineHeight: 22
                            },
                            formatter: function (params) {
                                return params + '岁'
                            }
                        },
                        series: [{
                            name: '年龄分布',
                            type: 'pie',
                            data: dataArray,
                            center: ['40%', '58%'],
                            radius: ['60%', '70%'],
                            itemStyle: {
                                normal: {
                                    label: {  //饼图图形上的文本标签
                                        show: true,  //平常不显示
                                        color: '#000',
                                        fontSize: 18,
                                        formatter: function (params) {
                                            return params.value + '%';
                                        }
                                    },
                                    labelLine: {     //标签的视觉引导线样式
                                        show: true  //平常不显示
                                    },
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        position: 'center',
                                        textStyle: {
                                            fontSize: '10',
                                            fontWeight: 'bold'
                                        }
                                    }
                                }
                            }
                        }]
                    });

                    if (dataArray.length == 0) {
                        let div1 = document.createElement("div");
                        div1.innerHTML = "暂无数据";
                        div1.className = 'no-data';
                        div1.style.position = "absolute";
                        div1.style.top = "100px";
                        div1.style.left = "50%";
                        document.querySelector("#ageDistribution").appendChild(div1)
                    }

                }

            },
            //计算百分比
            getPercent(curNum, totalNum, isHasPercentStr) {
                curNum = parseFloat(curNum);
                totalNum = parseFloat(totalNum);
                if (isNaN(curNum) || isNaN(totalNum)) {
                    return '-';
                }
                return isHasPercentStr ?
                    totalNum <= 0 ? '0%' : (Math.round(curNum / totalNum * 10000) / 100.00 + '%') :
                    totalNum <= 0 ? 0 : (Math.round(curNum / totalNum * 10000) / 100.00);
            },
            gotoData(item) {
                if (item.type === 1) {
                    this.$router.push({
                        path: '/teacher/data/details',
                        query: {
                            id: item.id,
                            user_id: this.account_id
                        }
                    })
                } else {
                    this.$message({
                        type: "error",
                        message: '请选择抖音账号',
                        duration: 1000
                    });
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .account-content {
        min-width: 1400px;

        .popover-box {
            font-size: 12px;
            color: #444444;
            line-height: 20px;
        }

        .purple-btn {
            width: 90px;
            height: 30px;
            background: #564CED;
            border-radius: 6px;
            color: #ffffff;
            line-height: 0;
        }

        .head-box {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            position: relative;

            .left-box {
                background: #ffffff;
                border-radius: 4px;

                .select-img {
                    width: 27px;
                    height: 27px;
                    border-radius: 50%;
                    position: absolute;
                    left: 10px;
                    top: 6px;
                    z-index: 1;
                }

                ::v-deep .el-input__inner {
                    padding-left: 40px;
                }

                ::v-deep .el-input__suffix .el-input__suffix-inner .el-icon-arrow-up {
                    &:before {
                        content: '\e78f';
                        color: #333333;
                    }
                }

                ::v-deep .el-select-dropdown .el-select-dropdown__item {
                    display: flex;
                    align-items: center;

                    span {
                        margin-left: 10px;
                    }

                    img {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                    }
                }
            }
        }

        .account-info-box {
            background: #ffffff;
            margin-top: 15px;
            border-radius: 6px;

            .head-time {
                text-align: right;
                padding: 18px;
                color: #666666;
                font-size: 12px;

                .update-title {
                    cursor: pointer;
                    margin-left: 9px;

                    i {
                        font-size: 14px;
                    }
                }
            }

            .main-content {
                display: flex;

                .bottom-box {
                    display: flex;
                    justify-content: center;
                    cursor: pointer;

                    .icon-box {
                        width: 36px;
                        height: 37px;
                        border-radius: 50%;
                        background: #5E54F1;
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        i {
                            font-size: 24px;
                        }
                    }

                    .bottom-text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 10px;
                    }
                }
            }

            .left-box {
                margin: 40px 0 30px 45px;
                padding-right: 100px;
                border-right: 5px solid #EEEEEE;

                .top-box {
                    display: flex;

                    .head-pic img {
                        width: 110px;
                        height: 110px;
                        border-radius: 50%;
                    }

                    .head-info {
                        width: 150px;
                        margin-left: 25px;

                        .account-name {
                            margin-top: 15px;
                            font-size: 20px;
                            color: #000000;
                            max-width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .account-phone {
                            margin-top: 45px;
                            font-size: 14px;
                            color: #888888;
                            max-width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }

                .bottom-box {
                    margin-top: 60px;
                    text-align: center;
                }

                .head-pic img {
                    width: 110px;
                    height: 110px;
                }
            }

            .middle-box {
                min-width: 700px;
                margin: 55px 0 30px 100px;
                border-right: 5px solid #EEEEEE;
                display: flex;
                flex-wrap: wrap;

                .info-box {
                    width: calc(33% - 80px);
                    text-align: center;
                    padding-left: 60px;

                    .top-box {
                        display: flex;
                        padding-left: 25px;

                        i {
                            font-size: 24px;
                        }

                        .top-title {
                            font-size: 14px;
                            height: 100%;
                            color: #888888;
                            margin-left: 8px;
                        }

                        .top-question {
                            margin-left: 8px;
                            margin-top: 2px;

                            .el-icon-question {
                                font-size: 16px;
                                color: #645AF1;
                                cursor: pointer;
                            }
                        }
                    }

                    .bottom-box {
                        text-align: center;
                        margin-top: 15px;
                        font-weight: bold;
                        color: #333333;
                        font-size: 24px;
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &:nth-of-type(3n+1) {
                        padding-right: 30px;
                        padding-left: 0;
                    }

                    &:nth-of-type(3n+2) {
                        position: relative;
                        padding-right: 40px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 1px;
                            height: 36px;
                            background: #EEEEEE;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 1px;
                            height: 36px;
                            background: #EEEEEE;
                        }
                    }

                }
            }

            .right-box {
                margin: 55px 0 30px 30px;
                padding-right: 50px;

                .right-main-content {
                    display: flex;
                }

                .right-bottom {
                    text-align: center;
                    display: flex;
                    justify-content: center;

                    .purple-btn {
                        display: flex;
                        justify-content: center;
                        line-height: 4px;
                    }
                }

                .right-box-item {
                    position: relative;
                    padding: 50px 35px;

                    .item-top {
                        width: 84px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #888888;
                    }

                    .item-bottom {
                        margin-top: 30px;
                        font-weight: bold;
                        color: #333333;
                        font-size: 24px;
                        text-align: center;
                        max-width: 84px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &:first-child {
                        &:after {
                            content: '';
                            position: absolute;
                            top: 60px;
                            right: 0;
                            width: 1px;
                            height: 36px;
                            background: #EEEEEE;
                        }
                    }

                }
            }
        }

        .left-item-fan {
            margin-bottom: 20px;
            margin-top: 20px;

            .item-half-sub {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #000000;
            }

            .left-item-content {
                background: #fff;
                border-radius: 6px;
                margin-top: 15px;
                padding: 15px;

                .fan-tab {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: center;
                    padding-right: 30px;
                    color: #999;
                    line-height: 1;
                    color: #666666;

                    .item {
                        cursor: pointer;
                        width: 50px;
                        height: 30px;
                        background: #F1F0FE;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.left {
                            border-radius: 5px 0px 0px 5px;
                        }

                        &.right {
                            border-radius: 0px 5px 5px 0px;
                        }
                    }

                    .current {
                        color: #FFFFFF;
                        background: #564CED;
                    }
                }

                ::v-deep.tooltip {
                    height: 59px;
                    border-radius: 2px;
                    box-shadow: 0px 2px 9px 1px rgba(0, 0, 0, 0.11);
                    background: #fff;

                    &.double {
                        height: 91px;
                    }

                    .tooltip-box {
                        padding: 13px 20px 10px;
                    }

                    .tooltip-title {
                        font-size: 12px;
                        color: #999999;
                    }

                    .tooltip-title-right {
                        float: right;
                        padding-right: 10px;
                        font-size: 16px;

                        &.red {
                            color: #F5697B;
                        }

                        &.blue {
                            color: #5F55F1;
                        }

                        &.purple {
                            color: #5F55F1;
                        }
                    }

                    .tooltip-title-left {
                        color: #333;
                    }
                }
            }

            .item-content {
                display: flex;
                justify-content: space-between;

                .title {
                    font-size: 16px;
                    color: #333333;
                }

                .left_box {
                    border-radius: 6px;
                    display: flex;
                    background-color: #ffffff;
                    flex-direction: column;
                    width: calc(50% - 20px);
                    height: 100%;
                    border-right: 1px solid #eaeaea;
                    margin: 10px 20px 0 0;

                    .gender-box {
                        display: flex;
                        flex-direction: column;
                        padding: 40px 30px;

                        .gender-chart {
                            margin-top: 100px;
                            padding: 0 30px;

                            .icon-box {
                                display: flex;
                                justify-content: space-between;
                                margin-top: 74px;

                                .icon-title {
                                    margin-top: 22px;
                                    font-size: 14px;
                                    color: #444444;
                                }

                                .women-box {
                                    text-align: center;

                                    i {
                                        color: #FF6A6A;
                                    }
                                }

                                .man-box {
                                    text-align: center;

                                    i {
                                        color: #564CED;
                                    }
                                }

                                i {
                                    font-size: 35px;
                                }
                            }

                            .chart-content {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                height: 13px;

                                .man {
                                    border-radius: 6px;
                                    background-color: #564CED;
                                }

                                .women {
                                    flex: 1;
                                    border-radius: 6px;
                                    background-color: #FF6A6A;
                                }
                            }

                            .bottom-box {
                                font-size: 18px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #000000;
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 30px;

                                .box1 {
                                    display: flex;
                                    flex-direction: column;
                                }
                            }
                        }
                    }
                }

                .right_box {
                    display: flex;
                    border-radius: 6px;
                    background-color: #ffffff;
                    flex-direction: column;
                    width: 50%;
                    height: 100%;
                    margin: 10px 0 0;

                    .age-box {
                        padding: 40px 30px;
                    }

                    .area-distribution {
                        flex: 1;
                        margin-top: 47px;
                        display: flex;
                        flex-direction: column;
                        height: calc(100% - 66px);
                    }
                }
            }

            &.end {
                margin-bottom: 0;
            }

            .college-table {
                width: 100%;
                margin-top: 22px;

                ::v-deep .el-table__header .platform-head {
                    padding-left: 25px;
                }

                .account-number {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    height: 45px;

                    &.platform {
                        padding-left: 20px;
                    }

                    img {
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                    }

                    .scope-title {
                        width: 260px;
                        margin-left: 10px;
                        text-align: left;
                        color: #333;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: inline-block;
                        cursor: pointer;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                .icon-box {
                    display: flex;
                    justify-content: center;

                    .icon-content {
                        width: 40px;
                        height: 40px;
                        background: #FF6A6A;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        i {
                            font-size: 20px;
                            color: #ffffff;
                        }
                    }
                }
            }

            .circle-page {
                padding-top: 35px;
            }

        }
    }
</style>