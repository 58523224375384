<template>
  <div class="ArticleAudit">
    <div class="ArticleAuditHeader">文章审核</div>
    <div class="selectClass">
      <div class="select-left">
        <span>筛选</span>
        <el-select style="margin: 0 16px" size="mini" v-model="auditValue" placeholder="请选择" @change="selectAudit">
          <el-option v-for="item in auditList" :key="item.status" :label="item.name" :value="item.status"> </el-option>
        </el-select>
      </div>
      <el-select class="select-right" size="mini" v-model="selectValue" placeholder="请选择" @change="isSelectClass">
        <el-option v-if="classList.length > 0" :key="0" label="全部" :value="0"></el-option>
        <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
    </div>
    <el-table
      :data="articleList"
      style="width: 100%; flex: 1"
      height="1%"
      size="medium"
      :header-cell-style="{ fontWeight: 'normal', height: '50px', color: '#222222', background: '#F8F8FD', fontSize: '14px' }"
      :cell-style="{ fontSize: '14px', color: '#333333', height: '75px' }"
    >
      <el-table-column type="index" align="center" label="序号" width="150"></el-table-column>
      <el-table-column prop="name" align="center" label="学生名称"></el-table-column>
      <el-table-column prop="class_name" align="center" label="班级"></el-table-column>
      <el-table-column prop="title" align="center" label="文章名称"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="previewArticle(scope.row)"> 预览 </el-button>

          <template>
            <el-button type="text" style="color: #67c23a" @click="auditArticle(scope.row.id, 2, '通过')" v-if="scope.row.status === 1">
              通过
            </el-button>
            <el-button type="text" style="color: #f56c6c" @click="auditArticle(scope.row.id, 3, '驳回')" v-if="scope.row.status === 1">
              驳回
            </el-button>
          </template>
          <span style="color: #67c23a; margin-left: 10px" v-if="scope.row.status === 2">审核通过</span>
          <span style="color: #f56c6c; margin-left: 10px" v-if="scope.row.status === 3">审核失败</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pages-center"
      :current-page="adminPages.currentPageNum"
      :page-size="adminPages.eachPageNum"
      layout="prev, pager, next, jumper"
      :total="adminPages.total"
      @current-change="adminCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: "ArticleAudit",
  data() {
    return {
      auditValue: -1,
      auditList: [
        {
          status: -1,
          name: "全部",
        },
        {
          status: 1,
          name: "待审核",
        },
        {
          status: 2,
          name: "已通过",
        },
        {
          status: 3,
          name: "未通过",
        },
      ],
      selectValue: 0,
      classList: [],
      articleList: [],
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getSelectClass();
    this.getArticleList();
  },
  methods: {
    //选择班级列表
    getSelectClass() {
      let param = {};
      this.$httpteacher.axiosGetBy(this.$api.classList, param, (res) => {
        if (res.code == 200) {
          this.classList = res.list;
        }
      });
    },

    // 获取文章审核列表
    getArticleList() {
      let params = {
        status: this.auditValue,
        class_id: this.selectValue,
        page: this.adminPages.currentPageNum,
        limit: this.adminPages.eachPageNum,
      };

      this.$httpteacher.axiosGetBy(this.$api.account_articleList, params, (res) => {
        if (res.code === 200) {
          this.articleList = res.data.list || [];
          this.adminPages.total = res.data.total;
        } else {
          this.$message.warning(res.mssage);
        }
      });
    },

    //选择班级
    isSelectClass(val) {
      this.getArticleList();
    },

    selectAudit() {
      this.getArticleList();
    },

    // 审核文章
    auditArticle(id, status, label) {
      this.$confirm(`是否${label}该文章?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: id,
          status: status,
        };

        this.$httpteacher.axiosPost(this.$api.account_auditArticle, params, (res) => {
          if (res.code === 200) {
            this.$message.success(res.message);
            this.getArticleList();
          } else {
            this.$message.warning(res.message);
          }
        });
      });
    },

    // 预览文章
    previewArticle(row) {
      sessionStorage.setItem("articleInfo", JSON.stringify(row));
      this.$router.push({
        path: "/teacher/account/article/articlePreview",
      });
    },

    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getArticleList();
    },
  },
};
</script>
<style scoped lang="scss">
.ArticleAudit {
  height: calc(100vh - 100px);
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .ArticleAuditHeader {
    color: #333333;
    font-size: 16px;
    line-height: 55px;
    border-bottom: 1px solid #f3f3f6;
    padding-left: 60px;
  }

  .selectClass {
    display: flex;
    padding: 27px 60px;
  }
}

::v-deep .pages-center {
  padding: 30px 0;
}

.el-table::before {
  display: none;
}
</style>
