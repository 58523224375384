<template>
  <div class="AccountAudit">
    <div class="AccountAuditHeader">
      视频审核
    </div>
    <div class="selectClass">
      <div class="select-left">
        <span>筛选</span>
        <el-select style="margin: 0 16px" size="mini" v-model="adminPages.status" placeholder="请选择" @change="selectAudit">
          <el-option v-for="item in auditList" :key="item.status" :label="item.name" :value="item.status">
          </el-option>
        </el-select>
      </div>
      <el-select class="select-right" size="mini" v-model="adminPages.class_id" placeholder="请选择" @change="isSelectClass">
        <el-option v-if="classList.length>0" :key="0" label="全部" :value="0"></el-option>
        <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </div>
    <el-table :data="accountList" style="width: 100%;flex: 1;" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', height: '50px', color: '#222222', background: '#F8F8FD',fontSize: '14px'}"
      :cell-style="{fontSize: '14px',color: '#333333',height: '75px'}">
      <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
      <el-table-column prop="name" align="center" label="学生名称"></el-table-column>
      <el-table-column prop="class_name" align="center" label="班级"></el-table-column>
      <el-table-column prop="classification" align="center" label="所属平台">
        <template slot-scope="scope">
          <span>抖音</span>
        </template>
      </el-table-column>
      <el-table-column prop="account_link" label="视频封面">
        <template slot-scope="scope">
          <div style="width: 120px; height: 60px;"> <img style="width: 100%;height: 100%;object-fit: cover;" :src="scope.row.cover" alt=""></div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" style="margin-right: 10px;" @click="viewVideo(scope.row.video)">预览</el-link>
          <span style="color: #9ACC5B;margin-right: 10px;cursor: pointer;" v-if="scope.row.status === 1" @click="examine(scope.row.id,2)">通过</span>
          <span style="color: #FF0000;cursor: pointer;" v-if="scope.row.status === 1" @click="examine(scope.row.id,3)">驳回</span>
          <span style="color: #9ACC5B" v-if="scope.row.status === 2">已通过</span>
          <span style="color: #FF0000" v-if="scope.row.status === 3">未通过</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pages-center" :current-page="adminPages.currentPageNum" :page-size="adminPages.eachPageNum" layout="prev, pager, next, jumper" :total="adminPages.total" @current-change="adminCurrentChange"></el-pagination>
    <el-dialog title="视频预览" :visible.sync="dialogVisible" width="900px" :before-close="closeVideo">
      <div class="video-preview">
        <video width="100%" height="500px" :src="videoUrl" controls="true" controlslist="nodownload"></video>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  name: "AccountAudit",
  data() {
    return {
      auditValue: "",
      auditList: [
        {
          status: -1,
          name: "全部",
        },
        {
          status: 1,
          name: "待审核",
        },
        {
          status: 2,
          name: "已通过",
        },
        {
          status: 3,
          name: "未通过",
        },
      ],
      selectValue: "",
      classList: [],
      accountList: [],
      accountSelectList: [],
      studentName: "",
      studentAccount: "",
      dialogVisible: false,
      accountId: 0,
      accountStatus: 0,
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
        status:-1,
        class_id:0
      },
      videoUrl: "",
    };
  },
  created() {
    this.getSelectClass();
    this.getAccountList();
  },
  methods: {
    //选择班级列表
    getSelectClass() {
      let param = {};
      this.$httpteacher.axiosGetBy(this.$api.classList, param, (res) => {
        if (res.code == 200) {
          this.classList = res.list;
        }
      });
    },
    // 获取账号审核列表
    getAccountList() {
      let param = {
        page: this.adminPages.currentPageNum,
        limit: this.adminPages.eachPageNum,
        status:this.adminPages.status,
        class_id:this.adminPages.class_id,

      };
      this.$httpteacher.axiosGetBy(
        this.$api.accountManageVideoList,
        param,
        (res) => {
          if (res.code === 200) {
            this.accountList = res.data.list;
            this.accountSelectList = res.data.list;
            this.adminPages.total = res.data.total;
          }
        }
      );
    },

    //选择班级
    isSelectClass(val) {
      this.getAccountList();
    },
    selectAudit() {
      this.getAccountList();
    },
    examine(id, status) {
      let param = {
        id: id,
        status: status,
      };
      this.$httpteacher.axiosPost(
        this.$api.accountManageAuditVideo,
        param,
        (res) => {
          if (res.code === 200) {
            this.getAccountList();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    // 驳回审核
    auditNoPass() {
      let param = {
        id: this.accountId,
        status: 2,
      };
      this.$httpteacher.axiosGetBy(
        this.$api.changeAccountState,
        param,
        (res) => {
          if (res.code === 200) {
            this.$message({
              type: "error",
              message: "审核驳回",
              duration: 1000,
              onClose: () => {
                this.dialogVisible = false;
                this.getAccountList();
              },
            });
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        },
        (err) => {
          console.log("错误", err);
        }
      );
    },
    // 审核通过
    auditPass() {
      let param = {
        id: this.accountId,
        status: 1,
      };
      this.$httpteacher.axiosGetBy(
        this.$api.changeAccountState,
        param,
        (res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "审核通过",
              duration: 1000,
              onClose: () => {
                this.dialogVisible = false;
                this.getAccountList();
              },
            });
          } else {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        },
        (err) => {
          console.log("错误", err);
        }
      );
    },
    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getAccountList();
    },
    // 预览视频
    viewVideo(url) {
      this.dialogVisible = true;
      this.videoUrl = url;
    },
    closeVideo() {
      // 关闭视频播放
      this.dialogVisible=false
      this.videoUrl = "";
    },
  },
};
</script>
  <style scoped lang="scss">
.AccountAudit {
  height: calc(100vh - 100px);
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .AccountAuditHeader {
    color: #333333;
    font-size: 16px;
    line-height: 55px;
    border-bottom: 1px solid #f3f3f6;
    padding-left: 60px;
  }

  .selectClass {
    display: flex;
    padding: 27px 60px;
  }
}

::v-deep .pages-center {
  padding: 30px 0;
}

.el-table::before {
  display: none;
}
</style>